import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import backgroundimg from "../img/background/back1.jpg";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";

export default function Reset() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Reset api post!!!
  const [loader, setLoader] = useState(false);
  const [token, settoken] = useState("");
  const [password, setpassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const Navigate = useNavigate();
  const Submit = () => {
    if (token === "" || password === "" || password_confirmation === "") {
      Swal.fire({
        title: "Please Fill the Fields!",
        confirmButtonColor: "#1896c8",
        icon: "error",
      });
    } else {
      const formData = new FormData();
      formData.append("token", token);
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);
      setLoader(true);
      axios
        .post(`${Baseurl.baseurl}supplier/reset/password`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data);
          if (res.data.status) {
            setLoader(false);
            Navigate("/DmeLogin");
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else if (
            res?.data?.errors?.password[0] ===
            "The password must be at least 6 characters."
          ) {
            setLoader(false);
            enqueueSnackbar(res?.data?.errors?.password[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          } else if (
            res?.data?.errors?.password_confirmation[0] ===
            "The password confirmation must be at least 6 characters."
          ) {
            setLoader(false);
            enqueueSnackbar(res?.data?.errors?.password_confirmation[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        });
    }
  };

  const updateState = () => {
    token("");
    password("");
    password_confirmation("");
  };
  return (
    <div>
      {loader ? <Loader fullPage loading /> : null}
      <div
        id="app"
        className="page-head-bg"
        style={{ backgroundImage: `url(${backgroundimg})` }}
      >
        <section className="section mt-4">
          <div className="container mt-5">
            <div className="row mt-4">
              <div className="offset-md-2 col-md-8 offset-md-2">
                <div className="card card-primary mt-2">
                  <div className="card-header">
                    <h3 className="text-center">Dme Reset</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label className="text-white">Code</label>
                        <input
                          value={token}
                          onChange={(e) => settoken(e.target.value)}
                          type="number"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="text-white">Password</label>
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type="Password"
                          className="form-control"
                          tabIndex={1}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="text-white">Confirm Password</label>
                        <input
                          value={password_confirmation}
                          onChange={(e) =>
                            setpassword_confirmation(e.target.value)
                          }
                          type="Password"
                          className="form-control"
                          tabIndex={1}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        tabIndex={4}
                        disabled={!token || !password || !password_confirmation}
                        onClick={() => {
                          Submit();
                          updateState();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="mt-2 text-white text-center">
                      <Link to="/DmeLogin" className="text-white">
                        Dme Login
                      </Link>
                    </div>
                    <div className="mt-2 text-white text-center">
                      Design By{" "}
                      <Link
                        to="https://hnhtechsolutions.com"
                        className="text-white"
                      >
                        Hnh Tech Solutions
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
