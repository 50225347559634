import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import backgroundimg from "../img/background/back1.jpg";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";

import { Loader } from "react-overlay-loader";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";

export default function Doctorstaffregister() {
    const Navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false);
    const location = useLocation();

    console.log("location", location.search.split('')[4])

    // validation
    const [ftn, setftn] = useState(false)
    const [ln, setln] = useState(false)
    const [em, setem] = useState(false)
    const [Pass, setPass] = useState(false)
    // validation
    const [first_name, setfirst_name] = useState("")
    const [last_name, setlastname] = useState("")
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const Submit = () => {
        if (first_name === "") {
            setftn(true)
        } else if (last_name === "") {
            setln(true)
        } else if (email === "") {
            setem(true)
        } else if (password === "") {
            setPass(true)
        } else {
            setLoader(true)
            const Data = {
                'first_name': first_name,
                'last_name': last_name,
                'email': email,
                'password': password,
                'doctor_id': location.search.split('')[4],
            }
            axios.post(`${Baseurl.baseurl}doctorstaff/register`, Data)
                .then((res => {
                    setLoader(false)
                    console.log(res)
                    if (res?.data?.status == true) {
                        Navigate("/Doctor/staff/Login");
                        enqueueSnackbar(res.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } if (res?.data?.errors?.email[0]) {
                        enqueueSnackbar(res.data.errors.email[0], {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                    //  else {
                    //     enqueueSnackbar(res.data.message, {
                    //         anchorOrigin: {
                    //             vertical: 'top',
                    //             horizontal: 'right',
                    //         },
                    //         variant: 'error',
                    //         autoHideDuration: 2000,
                    //     });
                    // }
                }))
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }

    return (
        <div
            className="page-head-bg"
            style={{ backgroundImage: `url(${backgroundimg})` }}
        >
            {loader ? <Loader fullPage loading /> : null}
            <div className="container mt-5">
                <div className="row">
                    <div className="offset-md-2 col-md-8 offset-md-2">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="text-center">DOCTOR-STAFF REGISTERATION</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="frist_name" className="text-white">
                                            First Name
                                        </label>
                                        <input
                                            id="frist_name"
                                            value={first_name}
                                            onChange={(e) => {
                                                setfirst_name(e.target.value)
                                                setftn(false)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    Submit()
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                        />
                                        {ftn ? (
                                            <div class="alert alert-danger" role="alert">
                                                First Name is Required!
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="frist_name" className="text-white">
                                            Last Name
                                        </label>
                                        <input
                                            value={last_name}
                                            onChange={(e) => {
                                                setlastname(e.target.value)
                                                setln(false)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    Submit()
                                                }
                                            }}
                                            type="text"
                                            className="form-control"
                                            name="frist_name"
                                            autofocus
                                        />
                                        {ln ? (
                                            <div class="alert alert-danger" role="alert">
                                                Last Name is Required!
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="text-white">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value)
                                                setem(false)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    Submit()
                                                }
                                            }}
                                            className="form-control"
                                            name="email"
                                        />
                                        {em ? (
                                            <div class="alert alert-danger" role="alert">
                                                Email is Required!
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="last_name" className="text-white">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => {
                                                setpassword(e.target.value)
                                                setPass(false)
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    Submit()
                                                }
                                            }}
                                            className="form-control"
                                            name="last_name"
                                        />
                                        {Pass ? (
                                            <div class="alert alert-danger" role="alert">
                                                Password is Required!
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="offset-md-2 form-group col-md-8 offset-md-2">
                                        <button
                                            type="submit"
                                            onClick={Submit}
                                            className="btn btn-primary btn-lg btn-block"
                                        >
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="text-white text-center">
                                <Link to="/" className="text-white">
                                    DASHBOARD
                                </Link>
                            </div>
                            <div className="mt-2 mb-2 text-white text-center">
                                Already Registered?{" "}
                                <Link to="/Doctor/staff/Login" className="text-white">
                                    Login
                                </Link>
                            </div>
                            <div className="mb-2 text-white text-center">
                                Design By
                                <a
                                    href="https://hnhtechsolutions.com/"
                                    target="_blank"
                                    className="text-white"
                                >
                                    {" "}
                                    Hnh Tech Solutions
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
