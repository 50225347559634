import { React, useState, useEffect, useContext } from "react";
import DmeHeader from "../Component/Dmeheader";
import Footer from "../Component/Footer";
import { Loader } from "react-overlay-loader";
import { ThemeContext } from "../App";
import img2 from "../img/user.png";
import axios from "axios";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function DmeChangePassword() {
  const [loader, setLoader] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [token, settoken] = useState("");
  const [to, setto] = useState(false);
  const [codescreen, setcodescreen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const dmeId = localStorage.getItem("dmeId");
  const Navigate = useNavigate();

  const [verify, setverify] = useState(false);
  //   const [id, setid] = useState("");
  const Send = () => {
    if (token === "") {
      setto(true);
    } else {
      setLoader(true);
      const Data = {
        token: token,
      };
      axios
        .post(`${Baseurl.baseurl}supplier/changepassword/verify`, Data)
        .then((res) => {
          console.log(res);
          //   setid(res?.data?.token);
          if (res?.data?.status === true) {
            setLoader(false);
            setcodescreen(false);
            setverify(true);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else if (
            res?.data?.errors?.token[0] === "The token must be 4 digits."
          ) {
            setLoader(false);
            enqueueSnackbar(res?.data?.errors?.token[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  const [np, setnp] = useState(false);
  const [pass, setpass] = useState(false);
  const [cp, setcp] = useState(false);
  const [new_password, setnew_password] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [current_password, setcurrent_password] = useState("");
  const Otp = () => {
    if (current_password === "") {
      setpass(true);
    } else if (new_password === "") {
      setnp(true);
    } else if (confirm_password === "") {
      setcp(true);
    } else {
      setLoader(true);
      const Data = {
        new_password: new_password,
        confirm_password: confirm_password,
        current_password: current_password,
      };
      axios
        .post(
          `${Baseurl.baseurl}supplier/changepassword/process/${dmeId}`,
          Data
        )
        .then((res) => {
          console.log(res);
          setLoader(false);
          if (res?.data?.status === true) {
            setnew_password("")
            setconfirm_password("")
            setcurrent_password("")
            setLoader(false);
            Navigate('/DmeLogin')
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          }else if (res?.data?.status === false && res?.data?.errors?.new_password) {
            enqueueSnackbar(res?.data?.errors?.new_password[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }else if(res?.data?.status === false && res?.data?.errors?.confirm_password){
            enqueueSnackbar(res?.data?.errors?.confirm_password[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }else{
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <>
      <div>
        {loader ? <Loader fullPage loading /> : null}
        <DmeHeader />
        <div className="main-content">
          <div className="container"></div>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <img
                type="file"
                src={img2}
                className="rounded-circle"
                width={"100%"}
                alt="profile"
              />
            </div>
          </div>
          <div className="row text-center justify-content-center mt-5">
            {codescreen ? (
              <div className="col-md-10">
                <div className="row  justify-content-center">
                  <div className="col-md-6">
                    <input
                      type="number"
                      placeholder="Please Enter Code"
                      className="form-control mb-4"
                      value={token}
                      onChange={(e) => {
                        settoken(e.target.value);
                        setto(false);
                      }}
                    />
                    {to ? (
                      <div className="alert alert-danger" role="alert">
                        Token is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row justify-content-center text-center text-align-center">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary btn-block px-4"
                      onClick={Send}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {verify ? (
              <div className="col-md-10">
                <div className="row  justify-content-center">
                  <div className="col-md-12">
                    <input
                      type="password"
                      placeholder="Please Enter Current Password"
                      className="form-control mb-4"
                      value={current_password}
                      onChange={(e) => {
                        setcurrent_password(e.target.value);
                        setnp(false);
                      }}
                    />
                    {pass ? (
                      <div className="alert alert-danger" role="alert">
                        Current Password is required{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      placeholder="Please Enter New Password"
                      className="form-control mb-4"
                      value={new_password}
                      onChange={(e) => {
                        setnew_password(e.target.value);
                        setnp(false);
                      }}
                    />
                    {np ? (
                      <div className="alert alert-danger" role="alert">
                        New Password is required{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      placeholder="Please Enter Confirm Password"
                      className="form-control mb-4"
                      value={confirm_password}
                      onChange={(e) => {
                        setconfirm_password(e.target.value);
                        setcp(false);
                      }}
                    />
                    {cp ? (
                      <div className="alert alert-danger" role="alert">
                        Confirm Password is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row justify-content-center text-center text-align-center">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary btn-block px-4"
                      onClick={Otp}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
