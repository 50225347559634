import React, { forwardRef, useState, useEffect, useContext } from "react";
import DoctorStaffheader from "../Component/DoctorStaffHeader";
import MaterialTable from 'material-table';
import axios from 'axios';
import Footer from "../Component/Footer";
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { AiOutlineUserAdd } from "react-icons/ai";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from 'notistack';
import { ImCross } from "react-icons/im";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../App"

export default function DoctorStaffOrder() {
    const { theme, toggleTheme } = useContext(ThemeContext)

    const [Patient, setPatient] = useState([])
    useEffect(() => {
        console.log('Patient===========>>>', Patient)
    }, [Patient])

    // Fetch Doctor Patients 
    const DoctorPatient = () => {
        axios.get(`${Baseurl.baseurl}doctor/view/patients/${DoctorId}`)
            .then((Patient) => {
                console.log(Patient.data.view_patients, "Patient")
                if (Patient.data.view_patients.length <= 0) {
                    setPatient([])
                    setLoader(false)
                }
                else {
                    setLoader(false)
                    setPatient(Patient.data.view_patients)
                }
            })
            .catch(errors => {
                console.log("catch", errors)
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    const arr = (Patient.map(v => ([
        { value: v.patient.id, label: v.patient.first_name },
        { value: v.dme_id }
    ])))

    console.log(arr, "array")
    useEffect(() => {
        DoctorPatient();
    }, [])
    // Fetch Doctor Patients 

    // /modal
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const handleClose = () => {
        setmachine_name("");
        setnotes("");
        setpatient_id("");
        setvalueall("");
        setShow(false)
    }
    const handleClose2 = () => {
        setShow2(false);
        setmachine_name("");
        setnotes("");
        setpatient_id("");
        setvalueall("");
    }
    const handleClose4 = () => setShow4(false);
    const handleClose5 = () => setShow5(false);
    const handleShow = () => setShow(true);
    const handleShow4 = () => setShow4(true);
    const handleShow2 = () => setShow2(true);
    const handleShow5 = () => setShow5(true);
    // /modal

    const DoctorId = localStorage.getItem("DoctorId")
    const Doctorname = localStorage.getItem("Doctorname")
    const { enqueueSnackbar } = useSnackbar();

    const [loader, setLoader] = useState(true)

    // fetch  Data
    const [Data, setData] = useState([])
    const Fetch = () => {
        axios.get(`${Baseurl.baseurl}doctor/reffrel/view/${DoctorId}`)
            .then((Data) => {
                setLoader(false)
                console.log(Data.data.reffrel, "orders")
                setData(Data.data.reffrel)
            })
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            })
    }
    useEffect(() => {
        Fetch();
    }, [])
    // fetch  Data

    // store data
    const [machinename, setmachine_name] = useState("")
    const [mach, setmach] = useState(false)
    const [notes, setnotes] = useState("")
    const [not, setnot] = useState(false)
    const [patient_id, setpatient_id] = useState("")
    const [pi, setpi] = useState(false)
    const [dme_id, setdme_id] = useState([])
    const [valueall, setvalueall] = useState([])
    const Store = () => {
        if (patient_id === "") {
            setpi(true)
        } else if (machinename === "") {
            setmach(true)
        } else if (notes === "") {
            setnot(true)
        } else {
            setLoader(true)
            handleClose();
            var myHeaders = new Headers();
            var formdata = new FormData();
            formdata.append("doctor_name", Doctorname);
            formdata.append("patient_id", patient_id);
            formdata.append("doctor_id", DoctorId);
            formdata.append("machine_name", machinename);
            formdata.append("notes", notes);
            formdata.append("dme_id", dme_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${Baseurl.baseurl}doctor/reffrel/store`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status) {
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                        Fetch();
                        handleClose();
                        setmachine_name("");
                        setnotes("");
                        setpatient_id("");
                        setvalueall("");
                    } else {
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    }
                })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    // store data
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    // edit states
    const Editorder = (e) => {
        console.log("edit=============", e);
        handleShow2();
        setmachine_name(e.machine_name);
        setid(e.id);
        setnotes(e.notes);
    };
    // edit states


    // update
    const [id, setid] = useState([]);
    const update = () => {
        setLoader(true)
        handleClose2();
        var myHeaders = new Headers();
        var formdata = new FormData();
        formdata.append("doctor_name", Doctorname);
        formdata.append("patient_id", patient_id);
        formdata.append("doctor_id", DoctorId);
        formdata.append("machine_name", machinename);
        formdata.append("dme_id", dme_id);
        formdata.append("notes", notes);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(`${Baseurl.baseurl}doctor/reffrel/update/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status) {
                    enqueueSnackbar(result.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    Fetch();
                    handleClose2();
                    setmachine_name("");
                    setnotes("");
                    setpatient_id("");
                } else {
                    enqueueSnackbar(result.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                }
            })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });

    }
    // update

    const [idr, setidr] = useState([]);
    const [dmeid, setdmeid] = useState([]);
    const [doctorid, setdoctorid] = useState([]);
    const Notes = (e) => {
        console.log("Note", e);
        setidr(e.id);
        setdmeid(e.dme_id);
        setdoctorid(e.doctor_id);
        handleShow4();
    }
    const [reffrel_notes, setreffrel_notes] = useState([]);
    const NoteStore = () => {
        handleClose4();
        setLoader(true)
        const Data = {
            'doctor_notes': reffrel_notes,
            'dme_id': dmeid,
            'reffrel_id': idr,
            'doctor_id': doctorid,
            'doctor_name': Doctorname,
        }
        axios.post(`${Baseurl.baseurl}doctor/reffrel/notes`, Data)
            .then((result => {
                setidr("");
                setdmeid("");
                setdoctorid("");
                setreffrel_notes("");
                setLoader(false)
                console.log(result.data)
                if (result.status) {
                    enqueueSnackbar("Note Successfully Created", {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                } else {
                    setLoader(false)
                    handleClose4();
                    enqueueSnackbar(result.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                }
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    // add note

    // view notes
    const [view, setview] = useState([])
    const viewnotes = (id) => {
        setLoader(true)
        axios.get(`${Baseurl.baseurl}doctor/reffrel/notes/view/${DoctorId}/${id}`)
            .then((result => {
                setLoader(false)
                handleShow5();
                console.log(result.data?.reffrel[0].notes, "notes")
                setview(result.data?.reffrel[0].notes)
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    // view notes

    return (

        <>
            <DoctorStaffheader />
            {loader ? <Loader fullPage loading /> : null}
            <div className="main-content">
                <section>
                    <div className='card'>
                        <div className='card-header row'>
                            <div className='col-md-6'>
                                <h5 className='mb-0'>Order</h5>
                            </div>
                            <div className="col-md-6">
                                <button className='btn btn-primary float-right' onClick={handleShow}>
                                    <AiOutlineUserAdd className='delete-btn' />
                                </button>
                            </div>
                        </div>
                        <div className='card-body col-md-12'>
                            <MaterialTable
                                title=""
                                icons={tableIcons}
                                columns={[
                                    // { title: 'Patient id', field: 'id', render: rowData => rowData.patient_reffrel.id },
                                    { title: 'Patient Name', field: 'first_name', render: rowData => rowData.patient_reffrel.first_name },
                                    { title: 'Order Description', field: 'machine_name', render: rowData => rowData.machine_name },
                                    {
                                        title: 'Notes', field: 'notes', render: rowData => <>
                                            <a onClick={() => viewnotes(rowData.id)}>{rowData.notes}</a>
                                        </>
                                    },
                                    { title: 'Status', field: 'status' }
                                ]}

                                data={Data}

                                actions={[
                                    {
                                        icon: 'assignment_turned_in',
                                        tooltip: 'Add Notes',
                                        onClick: (event, rowData) => Notes(rowData)
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => Editorder(rowData)
                                    },
                                ]}
                                style={{
                                    backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                }}
                                options={{
                                    // pageSize: 20,
                                    // pageSizeOptions: [20, 50, 100],
                                    headerStyle: {
                                        backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                        color: '#FFF'
                                    },
                                    rowStyle: {
                                        backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
            {/* store */}
            <Modal
                show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Add Order</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Doctor name</label>
                                        <input type="text" value={Doctorname} className="form-control" defaultValue="" readOnly />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Patient Name</label>
                                        <select className='form-control' value={valueall} onChange={(e) => {
                                            var idp = JSON.parse(e.target.value)
                                            setvalueall(e.target.value)
                                            setpatient_id(idp.id)
                                            setdme_id(idp.dme_id)
                                            setpi(false)
                                        }}>
                                            <option value={"Select a value"}>{"Select a value"}</option>
                                            {
                                                Patient.map((item) =>
                                                    <option value={JSON.stringify(item)}>{item.patient.first_name}</option>
                                                )
                                            }
                                        </select>
                                        {pi ? <div className="alert alert-danger" role="alert">Patient name is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input type="text" value={machinename} onChange={(e) => {
                                            setmach(false)
                                            setmachine_name(e.target.value)
                                        }} className="form-control" defaultValue="" required />
                                        {mach ? <div className="alert alert-danger" role="alert">Description is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <textarea type="text" value={notes} onChange={(e) => {
                                            setnotes(e.target.value)
                                            setnot(false)
                                        }} className="form-control" />
                                        {not ? <div className="alert alert-danger" role="alert">Notes is required </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => Store()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* store */}

            {/* store Update */}
            <Modal
                show={show2} onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Update Order</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Doctor name</label>
                                        <input type="text" value={Doctorname} className="form-control" defaultValue="" readOnly />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Patient Name</label>
                                        <select className='form-control' value={valueall} onChange={(e) => {
                                            var idp = JSON.parse(e.target.value)
                                            setvalueall(e.target.value)
                                            setpatient_id(idp.id)
                                            setdme_id(idp.dme_id)
                                            setpi(false)
                                        }}>
                                            <option value={"Select a value"}>{"Select a value"}</option>
                                            {
                                                Patient.map((item) =>
                                                    <option value={JSON.stringify(item)}>{item.patient.first_name}</option>
                                                )
                                            }
                                        </select>
                                        {pi ? <div className="alert alert-danger" role="alert">Patient name is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input type="text" value={machinename} onChange={(e) => {
                                            setmachine_name(e.target.value)
                                            setmach(false)
                                        }} className="form-control" defaultValue="" required />
                                        {mach ? <div className="alert alert-danger" role="alert">Description is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <textarea type="text" value={notes} onChange={(e) => {
                                            setnotes(e.target.value)
                                            setnot(false)
                                        }} className="form-control" />
                                        {not ? <div className="alert alert-danger" role="alert">Notes is required </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => update()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* store  Update*/}

            {/*Note  store */}
            <Modal
                show={show4} onHide={handleClose4}>
                <Modal.Header >
                    <Modal.Title>Add Notes</Modal.Title>
                    <i onClick={handleClose4} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <textarea type="text" value={reffrel_notes} onChange={(e) => setreffrel_notes(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => NoteStore()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/*Note  store */}

            {/*Note  view */}
            <Modal
                size="xl"
                show={show5} onHide={handleClose5}>
                <Modal.Header >
                    <Modal.Title>Notes</Modal.Title>
                    <i onClick={handleClose5} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                            {
                                title: 'Docter', field: '', render: rowData => <>
                                    Name  : <span>{rowData?.doctor_name}</span> <br />
                                    Notes : <span>{rowData?.doctor_notes}</span> <br />
                                    Date  : <span>{rowData?.date}</span> <br />
                                    Time  : <span>{rowData?.time}</span>
                                </>
                            },
                            {
                                title: 'Dme Supplier', field: '', render: rowData => <>
                                    Name  : <span>{rowData?.dme_name}</span>  <br />
                                    Notes : <span>{rowData?.dme_notes}</span> <br />
                                    Date  : <span>{rowData?.date}</span> <br />
                                    Time  : <span>{rowData?.time}</span>
                                </>
                            },
                            {
                                title: 'Therapist', field: '', render: rowData => <>
                                    Name  : <span>{rowData?.therapist_name}</span> <br />
                                    Notes : <span>{rowData?.therapist_notes}</span><br />
                                    Date  : <span>{rowData?.date}</span><br />
                                    Time  : <span>{rowData?.time}</span><br />
                                </>
                            },
                        ]}

                        data={view}
                        style={{
                            backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                        }}
                        options={{
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100],
                            headerStyle: {
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                color: '#FFF'
                            },
                            rowStyle: {
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1
                        }}
                    />
                </Modal.Body>
            </Modal>
            {/*Note  view */}
            <Footer />
        </>
    )
}