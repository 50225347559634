import { React, useEffect, useState } from "react";
import img6 from "../img/user.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdOutlineDashboard, MdOutlineHealthAndSafety } from "react-icons/md";
import { FiPackage } from "react-icons/fi";
import { FaFirstOrderAlt, FaUserNurse } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import $ from "jquery";
import axios from "axios";
import Baseurl from "./Baseurl";
import { useSnackbar } from "notistack";
export default function DoctorHeader() {
  const Navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const Token = localStorage.getItem("DoctorLogin");
  const Package = localStorage.getItem("Package");
  const id = localStorage.getItem("DoctorId");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (localStorage.getItem("DoctorLogin") == null) {
      Navigate("/DoctorLogin");
    }
  }, []);

  const Doctorname = localStorage.getItem("Doctorname");

  const logout = () => {
    Navigate("/");
    localStorage.removeItem("DoctorLogin");
    localStorage.removeItem("Package");
    localStorage.removeItem("DoctorId");
    localStorage.removeItem("Doctorname");
    console.log("logout");
  };

  let removeClass = () => {
    var x = document.getElementsByTagName("BODY")[0].classList;
    if (x.contains("sidebar-gone")) {
      x.remove("sidebar-gone");
      x.add("sidebar-show");
    } else {
      x.add("sidebar-gone");
      x.remove("sidebar-show");
    }
  };
  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("SettingValue");
    setThemeColor(data);
    console.log("App.Js", data);
  }, [localStorage.getItem("SettingValue")]);
  useEffect(() => {
    Setting(themeColor);
  }, [themeColor]);
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };

  const Password = () => {
    axios.post(`${Baseurl.baseurl}doctor/sent/${id}`).then((res) => {
      console.log(res);
      if (res?.data?.status === true) {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    });
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul class="navbar-nav mr-3">
            <li>
              <a href="#" class="nav-link nav-link-lg collapse-btn">
                {" "}
                <i
                  onClick={() => removeClass()}
                  className="fa fa-bars d-lg-none d-xl-none"
                  style={{ color: "" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <h4 className="text-white">DOCTOR</h4>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to=""
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              DOCTOR
              <span className="d-sm-none d-lg-inline-block" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">{Doctorname}</div>
              <Link
                to="/DoctorProfile"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-user" />
                Profile
              </Link>
              <Link
                to="/doctor/change/password"
                className="dropdown-item has-icon text-white"
                onClick={Password}
              >
                {" "}
                <i className="fas fa-eye" />
                Change Password
              </Link>
              <Link
                onClick={logout}
                to="/DoctorLogin"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-sign-out-alt" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <side id="sidebar-wrapper">
          <div
            className="btn btn-primary float-right mt-3  d-lg-none d-xl-none"
            onClick={() => removeClass()}
          >
            <i className="fa fa-times text-white"></i>
          </div>
          <div className="sidebar-brand">
            <Link to="/DoctorDashboard">
              {" "}
              <span className="logo-name">{Doctorname}</span>
            </Link>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={img6} width="100%" />
            </div>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header text-center">Main</li>
            <li className={pathname == "/DoctorDashboard" ? "active" : ""}>
              <Link
                to="/DoctorDashboard"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineDashboard />
                </i>
                <span>DASHBOARD</span>
              </Link>
            </li>
            {/* <li className={pathname == "/doctor/notes" ? "active" : ""}>
              <Link
                to="/doctor/notes"
                className=" nav-link "
              >
                <i>
                  <CgNotes />
                </i>
                <span>NOTES</span>
              </Link>
            </li> */}
            <li className={pathname == "/DoctorPatient" ? "active" : ""}>
              <Link
                to="/DoctorPatient"
                className=" nav-link "
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>PATIENT</span>
              </Link>
            </li>
            <li className={pathname == "/doctor/patient" ? "active" : ""}>
              <Link
                to="/doctor/patient"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>DOCTOR PATIENT</span>
              </Link>
            </li>
            <li className={pathname == "/Doctor/Orders" ? "active" : ""}>
              <Link
                to="/Doctor/Orders"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <FaFirstOrderAlt />
                </i>
                <span>ORDERS</span>
              </Link>
            </li>
            {/* <li
              className={
                pathname == "/doctor/patient/documents" ? "active" : ""
              }
            >
              <Link to="/doctor/patient/documents" className=" nav-link ">
                <i>
                  <FaFirstOrderAlt />
                </i>
                <span>PATIENTS DOCUMENTS</span>
              </Link>
            </li> */}
            {Package?.includes("Premium") ? (
              <li className={pathname == "/DoctorStaff" ? "active" : ""}>
                <Link
                  to="/DoctorStaff"
                  className=" nav-link "
                  // onClick={() => removeClass()}
                >
                  <i>
                    <FaUserNurse />
                  </i>
                  <span>Staff</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {Package?.includes("free") ? (
              <li className={pathname == "/DoctorUpdate" ? "active" : ""}>
                <Link
                  to="/DoctorUpdate"
                  className=" nav-link "
                  // onClick={() => removeClass()}
                >
                  <i>
                    <FiPackage />
                  </i>
                  <span>Update To Premium</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </side>
      </div>
    </div>
  );
}
