import React, { useState, useEffect, forwardRef, useContext } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";

export default function Admin() {
  const { enqueueSnackbar } = useSnackbar();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [loader, setLoader] = useState(true);
  const [myid, Setmyid] = useState("");
  const [stfid, Setstfid] = useState("");
  const [error, seterror] = useState(false);
  // add update states
  const [first_name, Setfirst_name] = useState("");
  const [last_name, Setlast_name] = useState("");
  const [email, Setemail] = useState("");
  const [address, Setaddress] = useState("");
  const [city, Setcity] = useState("");
  const [state, Setstate] = useState("");
  const [country, Setcountry] = useState("");
  const [zip_code, Setzip_code] = useState("");
  const [approved, Setapproved] = useState("");
  const [role_id, Setrole_id] = useState("");
  const [Company_name, setCompany_name] = useState("");
  // add update states
  const [id, Setid] = useState("");
  const [password, Setpassword] = useState("");
  const [Data, SetData] = useState([]);
  const [status, setStatus] = useState("");
  const [StatusId, setStatusId] = useState("");
  const [active, setactive] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // states clear
  const handleShow = () => {
    setShow(true);
    Setfirst_name("");
    Setlast_name("");
    Setemail("");
    Setpassword("");
    Setaddress("");
    Setcity("");
    Setstate("");
    Setcountry("");
    Setzip_code("");
    setCompany_name("");
  };
  // states clear
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [item, setitem] = useState([]);
  const [data, setData] = useState([]);
  // get dme
  function GetData() {
    fetch(`${Baseurl.baseurl}superadmin/dme`).then((result) => {
      result
        .json()
        .then((resp) => {
          setData(resp.supplier);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    });
  }
  useEffect(() => {
    GetData();
    GetStaff();
  }, []);
  // get dme

  // get staff
  function GetStaff() {
    fetch(`${Baseurl.baseurl}superadmin/staff`)
      .then((result) => {
        result.json().then((resp) => {
          console.log("ahsan", result);
          setitem(resp.staff);
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  // get staff
  const Status3 = (e, id) => {
    handleShow3();
    setStatus(e);
    setStatusId(id);
  };
  const dmeassign = (i, item) => {
    handleShow4();
    Setfirst_name(item.first_name);
    Setmyid(item);
    Setrole_id(i.role_id);
    setactive(item.active);
  };
  // validation states
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Pass, setPass] = useState(false);
  const [add, Setadd] = useState(false);
  const [cit, Setcit] = useState(false);
  const [sta, Setsta] = useState(false);
  const [coun, Setcoun] = useState(false);
  const [zip, Setzip] = useState(false);
  const [CompanyName, setCompanyName] = useState(false);
  // validation states

  // add dme supplier
  const GetDataAdmin = () => {
    if (Company_name === "") {
      setCompanyName(true);
    } else if (first_name === "") {
      setFirstName(true);
    } else if (last_name === "") {
      setLastName(true);
    } else if (city === "") {
      Setcit(true);
    } else if (state === "") {
      Setsta(true);
    } else if (country === "") {
      Setcoun(true);
    } else if (zip_code === "") {
      Setzip(true);
    } else if (email === "") {
      setEmail(true);
    } else if (password === "") {
      setPass(true);
    } else if (address === "") {
      Setadd(true);
    } else {
      setLoader(true);
      handleClose();
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${SuperAdminToken}`);
      var formdata = new FormData();
      formdata.append("company_name", Company_name);
      formdata.append("firstname", first_name);
      formdata.append("lastname", last_name);
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("address", address);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("zip_code", zip_code);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${Baseurl.baseurl}superadmin/dme/store`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setFirstName(false);
          setLastName(false);
          setEmail(false);
          setPass(false);
          Setadd(false);
          Setcit(false);
          Setcoun(false);
          Setsta(false);
          Setzip(false);
          setCompanyName(false);
          console.log(result);
          if (result.status) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            GetData();
            handleClose();
          }
          if (result.status === false && result.errors.email[0]) {
            setLoader(false);
            enqueueSnackbar(result.errors.email[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
          if (result.status === false && result.message) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  const SuperAdminToken = localStorage.getItem("superAdmin");
  // add dme supplier
  // delete dme
  function deletedme(id) {
    fetch(`${Baseurl.baseurl}superadmin/dme/delete/${id}`, {
      method: "GET",
    })
      .then((result) => {
        result.json().then((resp) => {
          if (result.status) {
            enqueueSnackbar("DME-Supplier Successfully Deleted", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });

            GetData();
          } else {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
          console.warn(resp);
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
    GetData();
  }
  // delete dme
  // edit states
  const edit = (e) => {
    console.log("edit=============", e);
    handleShow2();
    Setfirst_name(e.first_name);
    Setlast_name(e.last_name);
    Setemail(e.email);
    Setaddress(e.address);
    Setcity(e.city);
    Setstate(e.state);
    Setcountry(e.country);
    Setzip_code(e.zip_code);
    setCompany_name(e.company_name);
    Setmyid(e.id);
  };
  // edit states
  // dme assign
  const DmeAssign = () => {
    if (stfid === "") {
      seterror(true);
    } else {
      seterror(false);
      handleClose4();
      setLoader(true);
      var formdata = new FormData();
      formdata.append("staff_id", stfid);
      formdata.append("active", active);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(`${Baseurl.baseurl}superadmin/dme/assign/${myid}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoader(false);
          console.log(result);
          handleClose4();
          if (result.status) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
      Setstfid("");
    }
  };
  // dme assign
  // update dme
  const UpdateApi = () => {
    if (Company_name === "") {
      setCompanyName(true);
    } else if (first_name === "") {
      setFirstName(true);
    } else if (last_name === "") {
      setLastName(true);
    } else if (city === "") {
      Setcit(true);
    } else if (state === "") {
      Setsta(true);
    } else if (country === "") {
      Setcoun(true);
    } else if (zip_code === "") {
      Setzip(true);
    } else if (email === "") {
      setEmail(true);
    } else if (address === "") {
      Setadd(true);
    } else {
      handleClose2();
      setLoader(true);
      console.log("myid", myid);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${SuperAdminToken}`);
      var formdata = new FormData();
      formdata.append("company_name", Company_name);
      formdata.append("firstname", first_name);
      formdata.append("lastname", last_name);
      formdata.append("email", email);
      formdata.append("address", address);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("zip_code", zip_code);
      formdata.append("id", myid);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${Baseurl.baseurl}superadmin/dme/update/${myid}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setFirstName(false);
          setLastName(false);
          setEmail(false);
          Setadd(false);
          Setcit(false);
          Setcoun(false);
          Setsta(false);
          Setzip(false);
          setCompanyName(false);
          setLoader(false);
          GetData();
          handleClose2();
          if (result.status) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  // update dme
  // status
  const Submit = (id) => {
    handleClose3();
    setLoader(true);
    const formData = {
      status: status,
    };
    axios
      .post(`${Baseurl.baseurl}superadmin/dme/approve/${StatusId}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setLoader(false);
        console.log("res", res.data);
        if (res.data.status) {
          setStatusId("");
          GetData();
          handleClose3();
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          }).catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          });
        }
      });
  };
  // status
  //  dme Patient DATA
  const [dpatient, setdpatient] = useState([]);
  function DmePatient(id) {
    setLoader(true);
    fetch(`${Baseurl.baseurl}staff/dme/assign/patients/${id}`)
      .then((result) => {
        result.json().then((resp) => {
          console.log(resp.view_patients[0].patients, "Dme Patient");
          setdpatient(resp.view_patients[0].patients);
          setLoader(false);
          handleShow5();
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }

  // {
  //   SettingValue === "dark" ? (
  //     alert("hello")
  //   ) : null
  // }

  // dme Patient DATA
  return (
    <div>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">DME SUPPLIER</h5>
              </div>
              <div className="col-md-6">
                <Button
                  className="btn btn-primary float-right"
                  onClick={handleShow}
                >
                  <AiOutlineUserAdd className="delete-btn" />
                </Button>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                columns={[
                  { title: "Company Name", field: "company_name" },
                  {
                    title: "First Name",
                    field: "first_name",
                    render: (rowData) => (
                      <>
                        <a onClick={() => DmePatient(rowData.id)}>
                          {rowData.first_name}
                        </a>
                      </>
                    ),
                  },
                  { title: "Last Name", field: "last_name" },
                  { title: "Address", field: "address" },
                  { title: "City", field: "city" },
                  { title: "State", field: "state" },
                  { title: "Country", field: "country" },
                  { title: "zip", field: "zip_code" },
                  { title: "Email", field: "email" },
                  { title: "Password", field: "password" },

                  {
                    title: "Package",
                    field: "packages",
                    render: (rowData) => rowData.packages?.name,
                  },

                  { title: "Status", field: "status" },
                ]}
                data={data}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => edit(rowData),
                  },
                  {
                    icon: "settings_accessibility",
                    tooltip: "Status",
                    onClick: (event, rowData) =>
                      Status3(rowData.status, rowData.id),
                  },
                  {
                    icon: "assignment_turned_in",
                    tooltip: "Dme Assign",
                    onClick: (event, rowData) =>
                      dmeassign(rowData.active, rowData.id),
                  },
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => deletedme(rowData.id),
                  }),
                ]}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
      {/* ADD Dme Supplier  */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>DME SUPPLIER</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      id="package"
                      value={Company_name}
                      onChange={(e) => {
                        setCompany_name(e.target.value);
                        setCompanyName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {CompanyName ? (
                      <div class="alert alert-danger" role="alert">
                        Company Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="package"
                      value={first_name}
                      onChange={(e) => {
                        Setfirst_name(e.target.value);
                        setFirstName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {FirstName ? (
                      <div class="alert alert-danger" role="alert">
                        First Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        Setlast_name(e.target.value);
                        setLastName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {LastName ? (
                      <div class="alert alert-danger" role="alert">
                        Last Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => {
                        Setcity(e.target.value);
                        Setcit(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {cit ? (
                      <div class="alert alert-danger" role="alert">
                        City is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => {
                        Setstate(e.target.value);
                        Setsta(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {sta ? (
                      <div class="alert alert-danger" role="alert">
                        State is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      onChange={(e) => {
                        Setcountry(e.target.value);
                        Setcoun(false);
                      }}
                      id="country"
                      name="country"
                      class="form-control"
                    >
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">
                        Congo, The Democratic Republic of The
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">
                        Macedonia, The Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and The Grenadines">
                        Saint Vincent and The Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">
                        South Georgia and The South Sandwich Islands
                      </option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.S.">
                        Virgin Islands, U.S.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                    {coun ? (
                      <div class="alert alert-danger" role="alert">
                        Country is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>zip code</label>
                    <input
                      type="number"
                      value={zip_code}
                      onChange={(e) => {
                        Setzip_code(e.target.value);
                        Setzip(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {zip ? (
                      <div class="alert alert-danger" role="alert">
                        zip code is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        Setemail(e.target.value);
                        setEmail(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {Email ? (
                      <div class="alert alert-danger" role="alert">
                        Email is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        Setpassword(e.target.value);
                        setPass(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue="aaallli"
                      required
                    />
                    {Pass ? (
                      <div class="alert alert-danger" role="alert">
                        Enter Maximum 8 Digit!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => {
                        Setaddress(e.target.value);
                        Setadd(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {add ? (
                      <div class="alert alert-danger" role="alert">
                        Address is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => GetDataAdmin()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ADD Dme Supplier  */}

      {/* Update Dme Supplier  */}
      <Modal size="lg" show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>Update Dme Supplier</Modal.Title>
          <i
            onClick={handleClose2}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
              <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      id="package"
                      value={Company_name}
                      onChange={(e) => {
                        setCompany_name(e.target.value);
                        setCompanyName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          GetDataAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {CompanyName ? (
                      <div class="alert alert-danger" role="alert">
                        Company Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="package"
                      value={first_name}
                      onChange={(e) => {
                        Setfirst_name(e.target.value);
                        setFirstName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {FirstName ? (
                      <div class="alert alert-danger" role="alert">
                        First Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        Setlast_name(e.target.value);
                        setLastName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {LastName ? (
                      <div class="alert alert-danger" role="alert">
                        Last Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => {
                        Setcity(e.target.value);
                        Setcit(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {cit ? (
                      <div class="alert alert-danger" role="alert">
                        City is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => {
                        Setstate(e.target.value);
                        Setsta(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {sta ? (
                      <div class="alert alert-danger" role="alert">
                        State is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      onChange={(e) => {
                        Setcountry(e.target.value);
                        Setcoun(false);
                      }}
                      id="country"
                      name="country"
                      class="form-control"
                    >
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">
                        Congo, The Democratic Republic of The
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">
                        Macedonia, The Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and The Grenadines">
                        Saint Vincent and The Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">
                        South Georgia and The South Sandwich Islands
                      </option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.S.">
                        Virgin Islands, U.S.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                    {coun ? (
                      <div class="alert alert-danger" role="alert">
                        Country is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>zip code</label>
                    <input
                      type="number"
                      value={zip_code}
                      onChange={(e) => {
                        Setzip_code(e.target.value);
                        Setzip(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {zip ? (
                      <div class="alert alert-danger" role="alert">
                        zip code is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(false);
                        Setemail(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {Email ? (
                      <div class="alert alert-danger" role="alert">
                        Email is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => {
                        Setaddress(e.target.value);
                        Setadd(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          UpdateApi();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {add ? (
                      <div class="alert alert-danger" role="alert">
                        Address is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => UpdateApi()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Update Dme Supplier  */}

      {/*Update Status  */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header className="">
          <Modal.Title>Update Status</Modal.Title>
          <i
            onClick={handleClose3}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* <h4 className="text-center">GetData Admin</h4> */}

              <div className="row">
                <div className="col-md-12">
                  <label className="text-white">Select Your Status</label>
                  <select
                    className="form-control mt-4 mb-4"
                    id="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={"pending"}>Pending</option>
                    <option value={"approved"}>Approved</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => Submit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Update Status  */}

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header className="">
          <Modal.Title>Update Status</Modal.Title>
          <i
            onClick={handleClose4}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* <h4 className="text-center">GetData Admin</h4> */}

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>STAFF</label>
                    <select
                      className="form-control"
                      onClick={(e) => {
                        Setstfid(e.target.value);
                        seterror(false);
                      }}
                    >
                      <option value={"Select a value"}>Select a value</option>
                      {item.map((e) => (
                        <option value={e.id}>{e.first_name}</option>
                      ))}
                    </select>
                    {error ? (
                      <div class="alert alert-danger" role="alert">
                        Staff is required
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="text-white">Select Your Status</label>
                  <select
                    className="form-control mt-4 mb-4"
                    id="assign"
                    value={active}
                    onChange={(e) => setactive(e.target.value)}
                  >
                    <option value={"disable"}>disable</option>
                    <option value={"enable"}>enable</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => DmeAssign()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*   dme Patients */}
      <Modal size="xl" show={show5} onHide={handleClose5}>
        <Modal.Header>
          <Modal.Title>Dme Patients</Modal.Title>
          <i
            onClick={handleClose5}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body col-md-12">
            <MaterialTable
              title=""
              icons={tableIcons}
              style={{
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
              }}
              columns={[
                {
                  title: "Patient First Name",
                  field: "first_name",
                  render: (rowData) => rowData.first_name,
                },
                {
                  title: "Patient Last Name",
                  field: "last_name",
                  render: (rowData) => rowData.last_name,
                },
                {
                  title: "Patient Email",
                  field: "email",
                  render: (rowData) => rowData.email,
                },
              ]}
              data={dpatient}
              options={{
                // pageSize: 20,
                // pageSizeOptions: [20, 50, 100],
                headerStyle: {
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                  color: "#FFF",
                },
                rowStyle: {
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                  color: "#FFF",
                },
                actionsColumnIndex: -1,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* dme Patients */}
    </div>
  );
}
