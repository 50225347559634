import { React, useState, useEffect, useContext } from "react";
import DoctoHeader from "../Component/DoctorHeader";
import img2 from "../img/user.png";
import Footer from "../Component/Footer";
import Baseurl from "../Component/Baseurl";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Loader } from "react-overlay-loader";
import { ThemeContext } from "../App";

export default function Doctorprofile() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  var profileData = localStorage.getItem("DoctorProfile");
  console.log("DoctorProfile", JSON.parse(profileData));
  const [Data, setData] = useState(JSON.parse(profileData));
    console.log(Data);
  const DoctorId = localStorage.getItem("DoctorId");
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [email, setemail] = useState(Data.email);
  const [name, setname] = useState(Data.first_name);
  const [lname, setlname] = useState(Data.last_name);
  const [address, setaddress] = useState(Data.address);
  const [city, setcity] = useState(Data.city);
  const [state, setstate] = useState(Data.state);
  const [country, setcountry] = useState(Data.country);
  const [zip_code, setzip_code] = useState(Data.zip_code);
  const [company_name, setcompany_name] = useState(Data.company_name);

  const [na, setna] = useState(false);
  const [lna, setlna] = useState(false);
  const [em, setem] = useState(false);
  const [add, setadd] = useState(false);
  const [ct, setct] = useState(false);
  const [sta, setsta] = useState(false);
  const [count, setcount] = useState(false);
  const [zip, setzip] = useState(false);
  const [co, setco] = useState(false);

  const Update = () => {
    if (company_name === "") {
      setco(true);
    } else if (name === "") {
      setna(true);
    } else if (lname === "") {
      setlna(true);
    } else if (address === "") {
      setadd(true);
    } else if (city === "") {
      setct(true);
    } else if (state === "") {
      setsta(true);
    } else if (country === "") {
      setcount(true);
    } else if (zip_code === "") {
      setzip(true);
    } else {
      setLoader(true);
      const Data = {
        firstname: name,
        email: email,
        lastname: lname,
        address: address,
        city: city,
        state: state,
        country: country,
        zip_code: zip_code,
        company_name: company_name,
      };
      axios
        .post(`${Baseurl.baseurl}doctor/profile/${DoctorId}`, Data)
        .then((result) => {
          setLoader(false);
          console.log(result.data);
          localStorage.setItem("Doctorname", name);
          if (result.data) {
            setname("");
            setemail("");
            setlname("");
            setaddress("");
            setcity("");
            setstate("");
            setcountry("");
            setzip_code("");
            setcompany_name("");
            enqueueSnackbar("Doctor Profile Successfully Updated", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <div>
      {loader ? <Loader fullPage loading /> : null}
      <DoctoHeader />
      <div className="main-content">
        <div className="container"></div>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <img
              type="file"
              src={img2}
              className="rounded-circle"
              width={"100%"}
              alt="profile"
            />
          </div>
        </div>
        <div className="row text-center justify-content-center mt-5">
          <div className="col-md-10">
            <div className="row  justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  value={company_name}
                  onChange={(e) => {
                    setcompany_name(e.target.value);
                    setco(false);
                  }}
                  placeholder="Company Name"
                  className="form-control mb-4"
                />
                {co ? (
                  <div className="alert alert-danger" role="alert">
                    Company Name is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                    setna(false);
                  }}
                  placeholder="First Name"
                  className="form-control mb-4"
                />
                {na ? (
                  <div className="alert alert-danger" role="alert">
                    First Name is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lname}
                  onChange={(e) => {
                    setlname(e.target.value);
                    setlna(false);
                  }}
                  className="form-control mb-4"
                />
                {lna ? (
                  <div className="alert alert-danger" role="alert">
                    Last Name is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                    setem(false);
                  }}
                  className="form-control mb-4"
                />
                {em ? (
                  <div className="alert alert-danger" role="alert">
                    Email is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => {
                    setaddress(e.target.value);
                    setadd(false);
                  }}
                  className="form-control mb-4"
                />
                {add ? (
                  <div className="alert alert-danger" role="alert">
                    Address is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setcity(e.target.value);
                    setct(false);
                  }}
                  className="form-control mb-4"
                />
                {ct ? (
                  <div className="alert alert-danger" role="alert">
                    City is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => {
                    setstate(e.target.value);
                    setsta(false);
                  }}
                  className="form-control mb-4"
                />
                {sta ? (
                  <div className="alert alert-danger" role="alert">
                    State is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => {
                    setcountry(e.target.value);
                    setcount(false);
                  }}
                  className="form-control mb-4"
                />
                {count ? (
                  <div className="alert alert-danger" role="alert">
                    Country is Required !
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Zip Code"
                  value={zip_code}
                  onChange={(e) => {
                    setzip_code(e.target.value);
                    setzip(false);
                  }}
                  className="form-control mb-4"
                />
                {zip ? (
                  <div className="alert alert-danger" role="alert">
                    ZipCode is Required !
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row justify-content-center text-center text-align-center">
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn btn-primary btn-block px-4"
                  onClick={Update}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
