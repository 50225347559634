import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import backgroundimg from "../img/background/back1.jpg";
import { useNavigate } from "react-router-dom";
import { Loader } from "react-overlay-loader";
import { Alert } from "react-bootstrap";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";

export default function DmeRegister() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // validation
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [count, setcount] = useState(false);
  const [cit, setcit] = useState(false);
  const [zip, setzip] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Sta, setSta] = useState(false);
  const [text, settext] = useState(false);
  const [Pass, setPass] = useState(false);
  // validation

  const [loader, setLoader] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [iddme, setiddme] = useState("");

  const [Country, setCountry] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [Address, setAddress] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [company, setcompany] = useState("");

  const Navigate = useNavigate();

  const Submit = () => {
    if (company_name === "") {
      setcompany(true);
    } else if (firstname === "") {
      setFirstName(true);
    } else if (lastname === "") {
      setLastName(true);
    } else if (email === "") {
      setEmail(true);
    } else if (password === "") {
      setPass(true);
    } else if (Country === "") {
      setcount(true);
    } else if (City === "") {
      setcit(true);
    } else if (State === "") {
      setSta(true);
    } else if (ZipCode === "") {
      setzip(true);
    } else if (Address === "") {
      settext(true);
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("country", Country);
      formData.append("city", City);
      formData.append("address", Address);
      formData.append("state", State);
      formData.append("zip_code", ZipCode);
      formData.append("company_name", company_name);
      axios
        .post(`${Baseurl.baseurl}supplier/register`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setiddme(res.data.dmesupplier_id);
          if (res.data.status) {
            setLoader(false);
            setRegister(false);
            setscreen(true);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            if (res.data.errors.email) {
              enqueueSnackbar(res.data.errors.email, {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "error",
                autoHideDuration: 2000,
              });
            }
            setLoader(false);
          }
        });
    }
  };
  // opt
  const [Register, setRegister] = useState(true);
  const [screen, setscreen] = useState(false);
  const [verified_code, setverified_code] = useState([]);
  const [verify, setverify] = useState(false);

  const Submitotp = () => {
    if (verified_code === "") {
      setverify(true);
    } else {
      setLoader(true);
      const Data = {
        verified_code: verified_code,
      };
      axios
        .post(`${Baseurl.baseurl}supplier/verify/${iddme}`, Data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            setLoader(false);
            setRegister(false);
            setscreen(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            Navigate("/DmePackage/", { state: { id: iddme } });
          } else {
            if (res.data.errors.email) {
              enqueueSnackbar(res.data.errors.email, {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "error",
                autoHideDuration: 2000,
              });
            }
            setLoader(false);
          }
        });
    }
  };

  return (
    <div
      className="page-head-bg-1"
      style={{ backgroundImage: `url(${backgroundimg})` }}
    >
      {loader ? <Loader fullPage loading /> : null}
      {Register ? (
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8 offset-md-2">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">DME SUPPLIER REGISTERATION</h3>
                </div>
                <div className="card-body">
                  <div className="row registerdme">
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        Company Name
                      </label>
                      <input
                        value={company_name}
                        onChange={(e) => {
                          setcompany_name(e.target.value);
                          setcompany(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Name"
                      />
                      {company ? (
                        <div class="alert alert-danger" role="alert">
                          Company Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        First Name
                      </label>
                      <input
                        value={firstname}
                        onChange={(e) => {
                          setfirstname(e.target.value);
                          setFirstName(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                        placeholder="Enter Your First Name"
                      />
                      {FirstName ? (
                        <div class="alert alert-danger" role="alert">
                          First Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="last_name" className="text-white">
                        Last Name
                      </label>
                      <input
                        value={lastname}
                        onChange={(e) => {
                          setlastname(e.target.value);
                          setLastName(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Last Name"
                      />
                      {LastName ? (
                        <div class="alert alert-danger" role="alert">
                          Last Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email" className="text-white">
                        Email
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                          setEmail(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Your Email"
                      />
                      {Email ? (
                        <div class="alert alert-danger" role="alert">
                          Email is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="password" className="text-white">
                        Password
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                          setPass(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter a Password"
                      />
                      {Pass ? (
                        <div class="alert alert-danger" role="alert">
                          Password is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="country" className="text-white">
                        Country
                      </label>
                      <select
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setcount(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        id="country"
                        name="country"
                        class="form-control"
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      {count ? (
                        <div class="alert alert-danger" role="alert">
                          Country is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="city" className="text-white">
                        City
                      </label>
                      <input
                        type="text"
                        value={City}
                        onChange={(e) => {
                          setCity(e.target.value);
                          setcit(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Your City"
                      />
                      {cit ? (
                        <div class="alert alert-danger" role="alert">
                          City is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="state" className="text-white">
                        State
                      </label>
                      <input
                        type="text"
                        value={State}
                        onChange={(e) => {
                          setState(e.target.value);
                          setSta(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Your State"
                      />
                      {Sta ? (
                        <div class="alert alert-danger" role="alert">
                          State is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="zipcode" className="text-white">
                        Zipcode
                      </label>
                      <input
                        type="text"
                        value={ZipCode}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          setzip(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Your ZipCode"
                      />
                      {zip ? (
                        <div class="alert alert-danger" role="alert">
                          Zip Code is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="address" className="text-white">
                        Address
                      </label>
                      <input
                        value={Address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          settext(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Your Address"
                      />
                      {text ? (
                        <div class="alert alert-danger" role="alert">
                          Address is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group offset-md-3 col-md-6 offset-md-3">
                      <button
                        type="submit"
                        onClick={() => {
                          Submit();
                        }}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="text-white text-center">
                    <Link to="/" className="text-white">
                      DASHBOARD
                    </Link>
                  </div>
                  <div className="mt-2 mb-2 text-white text-center">
                    Already Registered?{" "}
                    <Link to="/DmeLogin" className="text-white">
                      Login
                    </Link>
                  </div>
                  <div className="mb-2 text-white text-center">
                    Design By
                    <a
                      href="https://hnhtechsolutions.com/"
                      target="_blank"
                      className="text-white"
                    >
                      {" "}
                      Hnh Tech Solutions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {screen ? (
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">OPT</h3>
                </div>
                <div className="card-body">
                  <div className="row registerdme">
                    <div className="form-group col-md-12">
                      <input
                        value={verified_code}
                        onChange={(e) => {
                          setverified_code(e.target.value);
                          setverify(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submitotp();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="verified_code"
                        placeholder="Otp Verification"
                      />
                      {verify ? (
                        <div class="alert alert-danger" role="alert">
                          Otp is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group offset-md-3 col-md-6 offset-md-3">
                      <button
                        type="submit"
                        onClick={() => {
                          Submitotp();
                        }}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
