import React, { useState, useEffect, forwardRef, useContext } from "react";
import DoctorStaffHeader from "../Component/DoctorStaffHeader";
import Footer from "../Component/Footer";
import MaterialTable from "material-table";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GrDocumentText } from "react-icons/gr";

export default function Patient() {
  const Navigate = useNavigate();

  const Id = localStorage.getItem("doctorstaffid");
  const DoctorId = localStorage.getItem("DoctorId");

  const [loader, setLoader] = useState(true);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const handleClose = () => {
    setshow(false);
    setfirst_name("");
    setlast_name("");
    setuser_name("");
    setdob("");
    setgender("");
    setweight("");
    setaddress("");
    setcity("");
    setcountry("");
    setstate("");
    setphone_no("");
    setemail("");
    setpassword("");
  };
  const handleClose2 = () => {
    setshow2(false);
    setfirst_name("");
    setlast_name("");
    setuser_name("");
    setdob("");
    setgender("");
    setweight("");
    setaddress("");
    setcity("");
    setcountry("");
    setstate("");
    setphone_no("");
    setemail("");
  };
  const handleShow = () => setshow(true);
  const handleShow2 = () => setshow2(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [data, setData] = useState([]);
  //GET
  function get() {
    fetch(`${Baseurl.baseurl}doctorstaff/patient/view/${DoctorId}`)
      .then((result) => {
        result.json().then((resp) => {
          setLoader(false);
          console.log(resp.view_patients[0].patients);
          setData(resp.view_patients[0].patients);
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    get();
  }, []);

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [user_name, setuser_name] = useState("");
  const [dob, setdob] = useState("");
  const [gender, setgender] = useState("");
  const [weight, setweight] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [phone_no, setphone_no] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [primary_insurance, Setprimary_insurance] = useState("");
  const [secondary_insurance, Setsecondary_insurance] = useState("");

  const [first, setfirst] = useState(false);
  const [last, setlast] = useState(false);
  const [user, setuser] = useState(false);
  const [ddd, setddd] = useState(false);
  const [gen, setgen] = useState(false);
  const [we, setwe] = useState(false);
  const [add, setadd] = useState(false);
  const [cit, setcit] = useState(false);
  const [count, setcount] = useState(false);
  const [sta, setsta] = useState(false);
  const [phone, setphone] = useState(false);
  const [em, setem] = useState(false);
  const [pass, setpass] = useState(false);
  const [pinsurance, setpinsurance] = useState(false);
  const [sinsurance, setsinsurance] = useState(false);
  const Store = () => {
    if (first_name === "") {
      setfirst(true);
    } else if (last_name === "") {
      setlast(true);
    } else if (user_name === "") {
      setuser(true);
    } else if (dob === "") {
      setddd(true);
    } else if (gender === "") {
      setgen(true);
    } else if (weight === "") {
      setwe(true);
    } else if (city === "") {
      setcit(true);
    } else if (country === "") {
      setcount(true);
    } else if (state === "") {
      setsta(true);
    } else if (primary_insurance === "") {
      setpinsurance(true);
    } else if (secondary_insurance === "") {
    } else if (phone_no === "") {
      setphone(true);
    } else if (email === "") {
      setem(true);
    } else if (password === "") {
      setpass(true);
    } else {
      setLoader(true);
      handleClose(true);
      const Data = {
        doctorstaff_id: Id,
        first_name: first_name,
        last_name: last_name,
        user_name: user_name,
        dob: dob,
        gender: gender,
        weight: weight,
        address: address,
        city: city,
        country: country,
        state: state,
        phone_no: phone_no,
        email: email,
        password: password,
        primary_insurance: primary_insurance,
        secondary_insurance: secondary_insurance,
      };
      axios
        .post(`${Baseurl.baseurl}doctorstaff/patient/store`, Data)
        .then((res) => {
          console.log(res, "store");
          if (res?.data?.status === true) {
            get();
            setLoader(false);
            setfirst_name("");
            setlast_name("");
            setuser_name("");
            setdob("");
            setgender("");
            setweight("");
            setaddress("");
            setcity("");
            setcountry("");
            setstate("");
            setphone_no("");
            setemail("");
            setpassword("");
            Setprimary_insurance("");
            Setsecondary_insurance("");
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            handleClose(false);
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          handleClose(true);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };

  const Delete = (id) => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}doctorstaff/patient/delete/${id}`)
      .then((res) => {
        setLoader(false);
        if (res?.data?.status === true) {
          get();
          setLoader(false);
          enqueueSnackbar(res?.data?.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setLoader(false);
          enqueueSnackbar(res?.data?.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          }).catch((errors) => {
            setLoader(false);
            handleClose(true);
            enqueueSnackbar(errors.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          });
        }
      });
  };

  const Edit = (e) => {
    console.log(e);
    setfirst_name(e.first_name);
    setlast_name(e.last_name);
    setuser_name(e.user_name);
    setdob(e.dob);
    setgender(e.gender);
    setweight(e.weight);
    setaddress(e.address);
    setcity(e.city);
    setcountry(e.country);
    setstate(e.state);
    setphone_no(e.phone_no);
    setemail(e.email);
    setid(e.id);
    Setprimary_insurance(e.primary_insurance);
    Setsecondary_insurance(e.secondary_insurance);
    handleShow2(true);
  };
  const [id, setid] = useState("");
  const update = () => {
    if (first_name === "") {
      setfirst(true);
    } else if (last_name === "") {
      setlast(true);
    } else if (user_name === "") {
      setuser(true);
    } else if (dob === "") {
      setddd(true);
    } else if (gender === "") {
      setgen(true);
    } else if (weight === "") {
      setwe(true);
    } else if (city === "") {
      setcit(true);
    } else if (country === "") {
      setcount(true);
    } else if (state === "") {
      setsta(true);
    } else if (primary_insurance === "") {
      setpinsurance(true);
    } else if (secondary_insurance === "") {
    } else if (phone_no === "") {
      setphone(true);
    } else if (email === "") {
      setem(true);
    } else {
      setLoader(true);
      handleClose2(true);
      const Data = {
        doctorstaff_id: Id,
        first_name: first_name,
        last_name: last_name,
        user_name: user_name,
        dob: dob,
        gender: gender,
        weight: weight,
        address: address,
        city: city,
        country: country,
        state: state,
        phone_no: phone_no,
        email: email,
        primary_insurance: primary_insurance,
        secondary_insurance: secondary_insurance,
      };
      axios
        .post(`${Baseurl.baseurl}doctorstaff/patient/update/${id}`, Data)
        .then((res) => {
          console.log(res, "update");
          if (res?.data?.status === true) {
            get();
            setLoader(false);
            setfirst_name("");
            setlast_name("");
            setuser_name("");
            setdob("");
            setgender("");
            setweight("");
            setaddress("");
            setcity("");
            setcountry("");
            setstate("");
            setphone_no("");
            setemail("");
            Setprimary_insurance("");
            Setsecondary_insurance("");
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            handleClose2(false);
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          handleClose2(true);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <>
      <DoctorStaffHeader />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">Patient</h5>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <button
                  className="btn btn-primary float-right"
                  onClick={handleShow}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={[
                  { title: "Dob", field: "dob" },
                  { title: "User Name", field: "user_name" },
                  { title: "First Name", field: "first_name" },
                  { title: "Last Name", field: "last_name" },
                  { title: "Email", field: "email" },
                  { title: "Password", field: "password" },
                  { title: "Gender", field: "gender" },
                  { title: "Weight", field: "weight" },
                  { title: "Address", field: "address" },
                  { title: "Primary Insurance", field: "primary_insurance" },
                  {
                    title: "Secondary Insurance",
                    field: "secondary_insurance",
                  },
                  { title: "City", field: "city" },
                  { title: "State", field: "state" },
                  { title: "Country", field: "country" },
                ]}
                data={data}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                actions={[
                  {
                    icon: "Notes",
                    tooltip: "Notes",

                    onClick: (event, rowData) =>
                      Navigate(`/doctorstaff/notes/${rowData.id}`),
                  },
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => Edit(rowData),
                  },
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => Delete(rowData.id),
                  }),
                  {
                    icon: () => <GrDocumentText />,
                    tooltip: "Document",

                    onClick: (event, rowData) =>
                      Navigate(`/doctorstaff/document/${rowData.id}`),
                  },
                ]}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      {/* store */}
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Patient</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={first_name}
                      onChange={(e) => {
                        setfirst_name(e.target.value);
                        setfirst(false);
                      }}
                    ></input>
                    {first ? (
                      <div className="alert alert-danger" role="alert">
                        First Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        setlast_name(e.target.value);
                        setlast(false);
                      }}
                    ></input>
                    {last ? (
                      <div className="alert alert-danger" role="alert">
                        Last Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user_name}
                      onChange={(e) => {
                        setuser_name(e.target.value);
                        setuser(false);
                      }}
                    ></input>
                    {user ? (
                      <div className="alert alert-danger" role="alert">
                        User Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Dob</label>
                    <input
                      className="form-control"
                      type="text"
                      value={dob}
                      onChange={(e) => {
                        setdob(e.target.value);
                        setddd(false);
                      }}
                    ></input>
                    {ddd ? (
                      <div className="alert alert-danger" role="alert">
                        Dob is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={gender}
                      onChange={(e) => {
                        setgender(e.target.value);
                        setgen(false);
                      }}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {gen ? (
                      <div className="alert alert-danger" role="alert">
                        Gender is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Weight</label>
                    <input
                      className="form-control"
                      type="number"
                      value={weight}
                      onChange={(e) => {
                        setweight(e.target.value);
                        setwe(false);
                      }}
                    ></input>
                    {we ? (
                      <div className="alert alert-danger" role="alert">
                        Weight is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      className="form-control"
                      type="text"
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                        setcit(false);
                      }}
                    ></input>
                    {cit ? (
                      <div className="alert alert-danger" role="alert">
                        City is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      className="form-control"
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setcountry(e.target.value);
                        setcount(false);
                      }}
                    ></input>
                    {count ? (
                      <div className="alert alert-danger" role="alert">
                        Country is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      className="form-control"
                      type="text"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                        setsta(false);
                      }}
                    ></input>
                    {sta ? (
                      <div className="alert alert-danger" role="alert">
                        State is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone No</label>
                    <input
                      className="form-control"
                      type="number"
                      value={phone_no}
                      onChange={(e) => {
                        setphone_no(e.target.value);
                        setphone(false);
                      }}
                    ></input>
                    {phone ? (
                      <div className="alert alert-danger" role="alert">
                        Phone Number is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Primary Insurance</label>
                  <input
                    type="text"
                    value={primary_insurance}
                    onChange={(e) => {
                      Setprimary_insurance(e.target.value);
                      setpinsurance(false);
                    }}
                    className="form-control"
                    required
                  />
                  {pinsurance ? (
                    <div class="alert alert-danger" role="alert">
                      Primary Insurance is Required!
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-md-6">
                  <label>Secondary Insurance</label>
                  <input
                    type="text"
                    value={secondary_insurance}
                    onChange={(e) => {
                      Setsecondary_insurance(e.target.value);
                      setsinsurance(false);
                    }}
                    className="form-control"
                    required
                  />
                  {sinsurance ? (
                    <div class="alert alert-danger" role="alert">
                      Secondary Insurance is Required!
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                        setphone(false);
                      }}
                    ></input>
                    {em ? (
                      <div className="alert alert-danger" role="alert">
                        Email is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                        setpass(false);
                      }}
                    ></input>
                    {pass ? (
                      <div className="alert alert-danger" role="alert">
                        Password is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      className="form-control"
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                        setadd(false);
                      }}
                    ></input>
                    {add ? (
                      <div className="alert alert-danger" role="alert">
                        Address is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => Store()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* store */}

      {/* Edit */}
      <Modal show={show2} size="lg" onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>Update Patient</Modal.Title>
          <i
            onClick={handleClose2}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={first_name}
                      onChange={(e) => {
                        setfirst_name(e.target.value);
                        setfirst(false);
                      }}
                    ></input>
                    {first ? (
                      <div className="alert alert-danger" role="alert">
                        First Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        setlast_name(e.target.value);
                        setlast(false);
                      }}
                    ></input>
                    {last ? (
                      <div className="alert alert-danger" role="alert">
                        Last Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user_name}
                      onChange={(e) => {
                        setuser_name(e.target.value);
                        setuser(false);
                      }}
                    ></input>
                    {user ? (
                      <div className="alert alert-danger" role="alert">
                        User Name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Dob</label>
                    <input
                      className="form-control"
                      type="text"
                      value={dob}
                      onChange={(e) => {
                        setdob(e.target.value);
                        setddd(false);
                      }}
                    ></input>
                    {ddd ? (
                      <div className="alert alert-danger" role="alert">
                        Dob is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={gender}
                      onChange={(e) => {
                        setgender(e.target.value);
                        setgen(false);
                      }}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {gen ? (
                      <div className="alert alert-danger" role="alert">
                        Gender is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Weight</label>
                    <input
                      className="form-control"
                      type="number"
                      value={weight}
                      onChange={(e) => {
                        setweight(e.target.value);
                        setwe(false);
                      }}
                    ></input>
                    {we ? (
                      <div className="alert alert-danger" role="alert">
                        Weight is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      className="form-control"
                      type="text"
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                        setcit(false);
                      }}
                    ></input>
                    {cit ? (
                      <div className="alert alert-danger" role="alert">
                        City is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      className="form-control"
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setcountry(e.target.value);
                        setcount(false);
                      }}
                    ></input>
                    {count ? (
                      <div className="alert alert-danger" role="alert">
                        Country is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      className="form-control"
                      type="text"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                        setsta(false);
                      }}
                    ></input>
                    {sta ? (
                      <div className="alert alert-danger" role="alert">
                        State is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone No</label>
                    <input
                      className="form-control"
                      type="number"
                      value={phone_no}
                      onChange={(e) => {
                        setphone_no(e.target.value);
                        setphone(false);
                      }}
                    ></input>
                    {phone ? (
                      <div className="alert alert-danger" role="alert">
                        Phone Number is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Primary Insurance</label>
                  <input
                    type="text"
                    value={primary_insurance}
                    onChange={(e) => {
                      Setprimary_insurance(e.target.value);
                      setpinsurance(false);
                    }}
                    className="form-control"
                    required
                  />
                  {pinsurance ? (
                    <div class="alert alert-danger" role="alert">
                      Primary Insurance is Required!
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-md-6">
                  <label>Secondary Insurance</label>
                  <input
                    type="text"
                    value={secondary_insurance}
                    onChange={(e) => {
                      Setsecondary_insurance(e.target.value);
                      setsinsurance(false);
                    }}
                    className="form-control"
                    required
                  />
                  {sinsurance ? (
                    <div class="alert alert-danger" role="alert">
                      Secondary Insurance is Required!
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                        setphone(false);
                      }}
                    ></input>
                    {em ? (
                      <div className="alert alert-danger" role="alert">
                        Email is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                        setpass(false);
                      }}
                    ></input>
                    {pass ? (
                      <div className="alert alert-danger" role="alert">
                        Password is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      className="form-control"
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                        setadd(false);
                      }}
                    ></input>
                    {add ? (
                      <div className="alert alert-danger" role="alert">
                        Address is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => update()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Edit */}
      <Footer />
    </>
  );
}
