import React, { useState, useEffect } from "react";
import img1 from "../img/banner/1.png";
import img2 from "../img/banner/2.png";
// import img3 from '../img/banner/3.png'
import img4 from "../img/banner/4.png";
import img5 from "../img/banner/5.png";
import { Loader } from "react-overlay-loader";
import { useLocation } from "react-router-dom";
import Baseurl from "../Component/Baseurl";
import Footer from "../Component/Footer";
import DmeHeader from "../Component/Dmeheader";
import { useSnackbar } from "notistack";
import Task from "./Task";
export default function DmeDashboard() {
  const Location = useLocation();
  console.log("Locationdme", Location?.state?.id);
  const Id = localStorage.getItem("dmeId");

  const { enqueueSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(true);
  const [total, settotal] = useState("");
  const [patient, setpatient] = useState("");
  function GetCount() {
    setLoader(true);
    fetch(`${Baseurl.baseurl}supplier/total/count`)
      .then((result) => {
        result.json().then((total) => {
          settotal(total);
          setLoader(false);
        });
      })
      .catch((error) => {
        setLoader(false);
        // enqueueSnackbar(error.message, {
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   variant: "error",
        //   autoHideDuration: 2000,
        // });
      });
  }

  useEffect(() => {
    GetCount();
  }, [Id]);

  // patient

  function Patient() {
    setLoader(true);
    fetch(`${Baseurl.baseurl}supplier/patient/count/${Id}`)
      .then((result) => {
        result.json().then((patient) => {
          setpatient(patient);
          setLoader(false);
        });
      })
      .catch((error) => {
        setLoader(false);
        // enqueueSnackbar(error.message, {
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   variant: "error",
        //   autoHideDuration: 2000,
        // });
      });
  }
  useEffect(() => {
    Patient();
  }, [Id]);

  const [reffrel, setreffrel] = useState([]);
  // order
  function order() {
    setLoader(true);
    fetch(`${Baseurl.baseurl}supplier/count/view/${Id}`)
      .then((result) => {
        result.json().then((result) => {
          setreffrel(result?.reffrel);
          console.log(result?.reffrel, "order counts");
          setLoader(false);
        });
      })
      .catch((error) => {
        setLoader(false);
        // enqueueSnackbar(error.message, {
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   variant: "error",
        //   autoHideDuration: 2000,
        // });
      });
  }
  useEffect(() => {
    order();
  }, [Id]);

  return (
    <div>
      <div>
        <DmeHeader />
        {loader ? <Loader fullPage loading /> : null}
        <div classname="container">
          <div className="main-content">
            <section className="section">
              <div className="row">
                <div className="col-md-4">
                  <div className="card cardhover">
                    <div className="card-statistic-5">
                      <div className="info-box7-block">
                        <div className="row ">
                          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <div className="banner-img">
                              <img src={img2} width="100%" alt="Patient" />
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                            <h6 className="m-b-20 text-right">PATIENT</h6>
                            <h4 className="text-right">
                              <span>{patient.patient}</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card cardhover">
                    <div className="card-statistic-5">
                      <div className="info-box7-block">
                        <div className="row ">
                          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <div className="banner-img">
                              <img src={img4} width="100%" alt="DOCTORS" />
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                            <h6 className="m-b-20 text-right">DOCTORS</h6>
                            <h4 className="text-right">
                              <span>{total.total_doctor}</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card cardhover">
                    <div className="card-statistic-5">
                      <div className="info-box7-block">
                        <div className="row ">
                          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <div className="banner-img">
                              <img src={img5} width="100%" alt="THERAPIST" />
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                            <h6 className="m-b-20 text-right">THERAPIST</h6>
                            <h4 className="text-right">
                              <span>{total.total_therapist}</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card cardhover">
                    <div className="card-statistic-5">
                      <div className="info-box7-block">
                        <div className="row ">
                          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <div className="banner-img">
                              <img src={img1} width="100%" alt="ORDERS" />
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                            <h6 className="m-b-20 text-right">ORDERS</h6>
                            <h4 className="text-right">
                              <span>{reffrel}</span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Task />
        </div>
      </div>
      ;
      <Footer />
    </div>
  );
}
