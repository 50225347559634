import React from 'react';
import Header from '../Component/Header';
import img1 from '../img/users/user-8.png'
import img2 from '../img/users/user-9.png'
import img3 from '../img/users/user-10.png'
import img4 from '../img/users/user-1.png'
import img5 from '../img/users/user-4.png'
import img6 from '../img/users/user-7.png'
import img7 from '../img/users/user-2.png'
import img8 from '../img/users/user-3.png'
import img9 from '../img/users/user-7.png'
import img10 from '../img/users/user-8.png'
import img11 from '../img/users/user-10.png'
import img12 from '../img/users/user-5.png'
import img13 from '../img/users/user-6.png'
import img14 from '../img/products/product-1.png'
import img15 from '../img/products/product-2.png'
import img16 from '../img/products/product-3.png'
import img17 from '../img/products/product-4.png'
import img18 from '../img/products/product-5.png'
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';










export default function Home() {
  return <div>
  <div>
    <Header/>
    <div className="main-content">
      <section className="section">
        <ul className="breadcrumb breadcrumb-style ">
          <li className="breadcrumb-item">
            <h4 className="page-title m-b-0">Dashboard</h4>
          </li>
          <li className="breadcrumb-item">
            <Link to="/Home">
              <i data-feather="home" className='fa fa-home' /></Link>
          </li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ul>
        <div className="row ">
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-style1">
              <div className="card-statistic-3">
                <div className="card-icon card-icon-large"><i className="fa fa-award" /></div>
                <div className="card-content">
                  <h4 className="card-title">New Orders</h4>
                  <span>524</span>
                  <div className="progress mt-1 mb-1" data-height={8}>
                    <div className="progress-bar l-bg-purple" role="progressbar" data-width="25%" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                  <p className="mb-0 text-sm">
                    <span className="mr-2"><i className="fa fa-arrow-up" /> 10%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-style2">
              <div className="card-statistic-3">
                <div className="card-icon card-icon-large"><i className="fa fa-briefcase" /></div>
                <div className="card-content">
                  <h4 className="card-title">New Booking</h4>
                  <span>1,258</span>
                  <div className="progress mt-1 mb-1" data-height={8}>
                    <div className="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                  <p className="mb-0 text-sm">
                    <span className="mr-2"><i className="fa fa-arrow-up" /> 10%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-style3">
              <div className="card-statistic-3">
                <div className="card-icon card-icon-large"><i className="fa fa-globe" /></div>
                <div className="card-content">
                  <h4 className="card-title">Inquiry</h4>
                  <span>10,225</span>
                  <div className="progress mt-1 mb-1" data-height={8}>
                    <div className="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                  <p className="mb-0 text-sm">
                    <span className="mr-2"><i className="fa fa-arrow-up" /> 10%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card l-bg-style4">
              <div className="card-statistic-3">
                <div className="card-icon card-icon-large"><i className="fa fa-money-bill-alt" /></div>
                <div className="card-content">
                  <h4 className="card-title">Earning</h4>
                  <span>$2,658</span>
                  <div className="progress mt-1 mb-1" data-height={8}>
                    <div className="progress-bar l-bg-green" role="progressbar" data-width="25%" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                  <p className="mb-0 text-sm">
                    <span className="mr-2"><i className="fa fa-arrow-up" /> 10%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      <div className="settingSidebar">
        <a href="javascript:void(0)" className="settingPanelToggle"> <i className="fa fa-spin fa-cog" />
        </a>
        <div className="settingSidebar-body ps-container ps-theme-default">
          <div className=" fade show active">
            <div className="setting-panel-header">Setting Panel
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Select Layout</h6>
              <div className="selectgroup layout-color w-50">
                <label className="selectgroup-item">
                  <input type="radio" name="value" defaultValue={1} className="selectgroup-input-radio select-layout" defaultChecked />
                  <span className="selectgroup-button">Light</span>
                </label>
                <label className="selectgroup-item">
                  <input type="radio" name="value" defaultValue={2} className="selectgroup-input-radio select-layout" />
                  <span className="selectgroup-button">Dark</span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Sidebar Color</h6>
              <div className="selectgroup selectgroup-pills sidebar-color">
                <label className="selectgroup-item">
                  <input type="radio" name="icon-input" defaultValue={1} className="selectgroup-input select-sidebar" />
                  <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Light Sidebar"><i className="fas fa-sun" /></span>
                </label>
                <label className="selectgroup-item">
                  <input type="radio" name="icon-input" defaultValue={2} className="selectgroup-input select-sidebar" defaultChecked />
                  <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Dark Sidebar"><i className="fas fa-moon" /></span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Color Theme</h6>
              <div className="theme-setting-options">
                <ul className="choose-theme list-unstyled mb-0">
                  <li title="white" className="active">
                    <div className="white" />
                  </li>
                  <li title="cyan">
                    <div className="cyan" />
                  </li>
                  <li title="black">
                    <div className="black" />
                  </li>
                  <li title="purple">
                    <div className="purple" />
                  </li>
                  <li title="orange">
                    <div className="orange" />
                  </li>
                  <li title="green">
                    <div className="green" />
                  </li>
                  <li title="red">
                    <div className="red" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <div className="theme-setting-options">
                <label className="m-b-0">
                  <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="mini_sidebar_setting" />
                  <span className="custom-switch-indicator" />
                  <span className="control-label p-l-10">Mini Sidebar</span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <div className="theme-setting-options">
                <label className="m-b-0">
                  <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="sticky_header_setting" />
                  <span className="custom-switch-indicator" />
                  <span className="control-label p-l-10">Sticky Header</span>
                </label>
              </div>
            </div>
            <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
              <a href="#" className="btn btn-icon icon-left btn-primary btn-restore-theme">
                <i className="fas fa-undo" /> Restore Default
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  <Footer/>
</div>

}
