import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backgroundimg from '../img/background/back.jpg'
import { useNavigate } from "react-router-dom";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';

export default function Therapistlogin() {
    const [Email, SetEmail] = useState("");
    const [Password, SetPassword] = useState("");
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const Navigate = useNavigate();
    const Login = () => {
        if (Email === "" || Password === "") {
            enqueueSnackbar("Credentials Is Required", {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
            });
        } else {
            setLoader(true)
            var formdata = new FormData();
            formdata.append("email", Email);
            formdata.append("password", Password);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${Baseurl.baseurl}therapist/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    console.log(result);
                    if (result.status) {
                        localStorage.setItem('TharepestLogin', (result.token));
                        localStorage.setItem("therapist_id", result.therapist_id);
                        localStorage.setItem("packagename", result.therapist?.name);
                        localStorage.setItem("therapist_name", result.therapist_name);
                        localStorage.setItem("SettingValue", "light");
                        localStorage.setItem("TherapistProfile", JSON.stringify(result?.user));

                        Navigate("/TharepistDashboard");
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else {
                        if (result.message) {
                            enqueueSnackbar(result.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'error',
                                autoHideDuration: 2000,
                            });
                        } else if (result.errors.email) {
                            enqueueSnackbar(result.errors.email, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'error',
                                autoHideDuration: 2000,
                            });
                        }
                    }
                })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    return (
        <div>
            <div id="app" className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
                {loader ? <Loader fullPage loading /> : null}
                <section className="section mt-4">
                    <div className="container mt-5">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary mt-2">
                                    <div className="card-header">
                                        <h3 className="text-center">THERAPIST</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-white">EMAIL</label>
                                            <input
                                                id="email"

                                                type="email"
                                                className="form-control"
                                                name="email"
                                                value={Email}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                onChange={(e) => SetEmail(e.target.value)}
                                                tabIndex={1}
                                                required
                                                autofocus

                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label text-white">
                                                    PASSWORD
                                                </label>
                                                <div className="float-right">
                                                    <Link
                                                        to="/therapist/forget"
                                                        className="text-white"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                            </div>

                                            
                                            <input
                                                id="password"
                                                type="password"

                                                className="form-control"
                                                name="password"
                                                tabIndex={2}
                                                required
                                                value={Password}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                onChange={(e) => SetPassword
                                                    (e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={Login}
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            <Link to="/" className="text-white">Dashboard</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Don't have an account? <Link to="/TherapistReg" className="text-white" >Create One</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Design By<a href="https://hnhtechsolutions.com/" target="_blank" className="text-white">  Hnh Tech Solutions</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
