import React, { useState, useEffect, forwardRef, useContext } from 'react';
import MaterialTable from 'material-table';
import StaffHeader from '../Component/StaffHeader';
import Footer from '../Component/Footer';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from '../Component/Baseurl'
import { ThemeContext } from "../App"

export const StaffAssign = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }
  const [data, SetData] = useState([])
  useEffect(() => {
    GetApi()


  }, [])
  const ids = localStorage.getItem("id")
  const ID = localStorage.getItem("StaffLoginid")
  const GetApi = () => {
    var axios = require('axios');

    var config = {
      method: 'get',
      url: `${Baseurl.baseurl}superadmin/dme/assign/view/${ids}`,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        SetData((response.data.assign_supplier));


      })
      .catch(function (error) {
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  console.log("Data", data)

  return (
    <div>
      <StaffHeader />
      <div className='main-content'>
        <div className='card'>
          <div className="card-header row">
            <div className='col-md-9'>
              <h5 className='mb-0'>ASSIGN DME</h5>
            </div>
          </div>
          <div className='card-body'>
            <MaterialTable
              title=""
              icons={tableIcons}
              columns={[
                { title: 'First Name', field: 'first_name' },
                { title: 'Last Name', field: 'last_name' },
                { title: 'Email', field: 'email' },
              ]}
              data={data}
              style={{
                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
              }}
              options={{
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
                headerStyle: {
                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                  color: '#FFF'
                },
                rowStyle: {
                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                  color: '#FFF'
                },
                actionsColumnIndex: -1
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
