import { React, useState } from 'react';
import Header from '../Component/Header';
import img2 from "../img/user.png";
import Footer from '../Component/Footer';
import Baseurl from '../Component/Baseurl';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Loader } from 'react-overlay-loader';

export default function Profile() {

  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false)

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [na, setna] = useState(false);
  const [em, setem] = useState(false);
  const Update = () => {
    if (name === "") {
      setna(true)
    } else if (email === "") {
      setem(true)
    } else {
      setLoader(true)
      const Data = {
        'name': name,
        'email': email,
      }
      axios.post(`${Baseurl.baseurl}superadmin/update/profile`, Data)
        .then((result => {
          setLoader(false)
          console.log(result.data)
          localStorage.setItem("Name", name);
          if (result.data) {
            setname("");
            setemail("");
            enqueueSnackbar("Super Admin Profile Successfully Updated", {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result.data.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }))
        .catch(errors => {
          setLoader(false)
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }

  }

  return <div>
    {loader ? <Loader fullPage loading /> : null}
    <Header />
    <div className="main-content">
      <div className="container">
      </div>
      <div className="row justify-content-center">
        <div className="col-md-3">
          <img type="file" src={img2} className="rounded-circle" width={"100%"} alt="profile" />
        </div>
      </div>
      <div className="row text-center justify-content-center mt-5">
        <div className="col-md-10">
          <div className="row  justify-content-center">
            <div className="col-md-6">
              <input type="text" value={name} onChange={(e) => {
                setname(e.target.value)
                setna(false)
              }} placeholder='First Name' className='form-control mb-4' />
              {na ? <div className="alert alert-danger" role="alert">Name is Requiered !</div> : null}
            </div>
            <div className="col-md-6">
              <input type="email" placeholder='Email' value={email} onChange={(e) => {
                setemail(e.target.value)
                setem(false)
              }} className='form-control mb-4' />
              {em ? <div className="alert alert-danger" role="alert">Email is Requiered !</div> : null}
            </div>
          </div>
          <div className="row justify-content-center text-center text-align-center">
            <div className="col-md-6">
              <button type='button' className="btn btn-primary btn-block px-4" onClick={Update}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>;
}
