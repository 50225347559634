import { React, useEffect, useState } from "react";
import img7 from "../img/users/staff.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineHealthAndSafety,
  MdDoneAll,
} from "react-icons/md";
import { GiSkeletalHand } from "react-icons/gi";
import { SiHandshake } from "react-icons/si";
import { FaStethoscope } from "react-icons/fa";
import $ from "jquery";

export default function StaffHeader() {
  const Navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const Token = localStorage.getItem("StaffLogin");
  const staff_name = localStorage.getItem("staff_name");

  useEffect(() => {
    if (localStorage.getItem("StaffLogin") == null) {
      Navigate("/StaffLogin");
    }
  }, []);

  const logout = () => {
    Navigate("/");
    localStorage.removeItem("StaffLogin");
    localStorage.removeItem("permissions");
    console.log("logout");
  };

  let removeClass = () => {
    var x = document.getElementsByTagName("BODY")[0].classList;
    if (x.contains("sidebar-gone")) {
      x.remove("sidebar-gone");
      x.add("sidebar-show");
    } else {
      x.add("sidebar-gone");
      x.remove("sidebar-show");
    }
  };

  const settings = () => {
    console.log("1111");
    document.getElementById("settings").classList.add("showSettingPanel");
  };

  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("SettingValue");
    setThemeColor(data);
    console.log("App.Js", data);
  }, [localStorage.getItem("SettingValue")]);
  useEffect(() => {
    Setting(themeColor);
  }, [themeColor]);
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul class="navbar-nav mr-3">
            <li>
              <a href="#" class="nav-link nav-link-lg collapse-btn">
                {" "}
                <i
                  onClick={() => removeClass()}
                  className="fa fa-bars d-lg-none d-xl-none"
                  style={{ color: "" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <h4 className="text-white">DOCTOR STAFF</h4>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to=""
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {/* STAFF */}{" "}
              <img
                alt="image"
                src={img7}
                width="100%"
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Staff</div>
              <Link
                to="/StaffProfile"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-user" />
                Profile
              </Link>
              <Link
                onClick={logout}
                to="/stafflogin"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-sign-out-alt" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <side id="sidebar-wrapper">
          <div
            className="btn btn-primary float-right mt-3  d-lg-none d-xl-none"
            onClick={() => removeClass()}
          >
            <i className="fa fa-times text-white"></i>
          </div>
          <div className="sidebar-brand">
            <Link to="/StaffDashboard">
              {" "}
              <span className="logo-name">{staff_name}</span>
            </Link>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={img7} width="100%" />
            </div>
          </div>
          <ul className="sidebar-menu">
            {/* <li className="menu-header text-center">Main</li> */}
            <li className={pathname == "/StaffDashboard" ? "active" : ""}>
              <Link
                to="/StaffDashboard"
                className=" nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineDashboard />
                </i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={pathname == "/StaffDme" ? "active" : ""}>
              <Link
                to="/StaffDme"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <SiHandshake />
                </i>
                <span>DME SUPPLIER</span>
              </Link>
            </li>
            <li className={pathname == "/StaffAssign" ? "active" : ""}>
              <Link
                to="/StaffAssign"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdDoneAll />
                </i>
                <span>DME Assign</span>
              </Link>
            </li>
            <li className={pathname == "/StaffDoctor" ? "active" : ""}>
              <Link
                to="/StaffDoctor"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <FaStethoscope />
                </i>
                <span>DOCTOR</span>
              </Link>
            </li>
            <li className={pathname == "/StaffTherapist" ? "active" : ""}>
              <Link
                to="/StaffTherapist"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <GiSkeletalHand />
                </i>
                <span>THERAPIST</span>
              </Link>
            </li>
            <li className={pathname == "/staffPatient" ? "active" : ""}>
              <Link
                to="/staffPatient"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>Patients</span>
              </Link>
            </li>
          </ul>
        </side>
      </div>
    </div>
  );
}
