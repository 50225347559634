import img7 from "../img/users/staff.png";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, React, useState } from "react";
import { FaStethoscope, FaFirstOrderAlt, FaUserNurse } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { MdOutlineDashboard, MdOutlineHealthAndSafety } from "react-icons/md";
import { GiSkeletalHand } from "react-icons/gi"; 
import { CgNotes } from "react-icons/cg"; 
import $ from "jquery";
import axios from "axios";
import Baseurl from "./Baseurl";
import { useSnackbar } from "notistack";
export default function DoctorStaffheader() {
  const Navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const id = localStorage.getItem("doctorstaffid");
  const { enqueueSnackbar } = useSnackbar();
  const doctorstaffaccess = localStorage.getItem("doctorstaffaccess");
  const name = localStorage.getItem("doctorstaffname");

  useEffect(() => {
    if (localStorage.getItem("doctorstaffaccess") == null) {
      Navigate("/Doctor/staff/Login");
    }
  }, []);
  const logout = () => {
    Navigate("/Doctor/staff/Login");
    localStorage.removeItem("doctorstaffaccess");
    localStorage.removeItem("doctorstaffname");
    localStorage.removeItem("doctorstaffid");
    // localStorage.clear();
    console.log("logout");
  };

  let removeClass = () => {
    var x = document.getElementsByTagName("BODY")[0].classList;
    if (x.contains("sidebar-gone")) {
      x.remove("sidebar-gone");
      x.add("sidebar-show");
    } else {
      x.add("sidebar-gone");
      x.remove("sidebar-show");
    }
  };

  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("SettingValue");
    setThemeColor(data);
    console.log("App.Js", data);
  }, [localStorage.getItem("SettingValue")]);
  useEffect(() => {
    Setting(themeColor);
  }, [themeColor]);
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };
  const Password = () => {
    axios.post(`${Baseurl.baseurl}doctorstaff/sent/${id}`).then((res) => {
      console.log(res);
      if (res?.data?.status === true) {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    });
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a href="#" className="nav-link nav-link-lg collapse-btn">
                {" "}
                <i
                  onClick={() => removeClass()}
                  className="fa fa-bars d-lg-none d-xl-none"
                  style={{ color: "" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <h4 className="text-white">DOCTOR STAFF</h4>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to=""
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {/* DME SUPPLIER */}{" "}
              <img
                alt="image"
                src={img7}
                width="100%"
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">DOCTOR Staff</div>
              <Link to="" className="dropdown-item has-icon text-white">
                {" "}
                <i className="fas fa-user" />
                Profile
              </Link>
              <Link
                to="/doctorstaff/change/password"
                className="dropdown-item has-icon text-white"
                onClick={Password}
              >
                {" "}
                <i className="fas fa-eye" />
                Change Password
              </Link>
              <Link
                onClick={logout}
                to="/Doctor/staff/Login"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-sign-out-alt" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <side id="sidebar-wrapper">
          <div
            className="btn btn-primary float-right mt-3  d-lg-none d-xl-none"
            onClick={() => removeClass()}
          >
            <i className="fa fa-times text-white"></i>
          </div>
          <div className="sidebar-brand">
            <Link to="/DmeDashboard">
              {" "}
              <span className="logo-name">{name}</span>
            </Link>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={img7} width="100%" />
            </div>
          </div>
          <ul className="sidebar-menu">
            <li className={pathname == "/DoctorStaffDashboard" ? "active" : ""}>
              <Link
                to="/DoctorStaffDashboard"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineDashboard />
                </i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={pathname == "/doctorstaff/notes" ? "active" : ""}>
              <Link to="/doctorstaff/notes" className=" nav-link  ">
                <i>
                  <CgNotes />
                </i>
                <span>NOTES</span>
              </Link>
            </li>
            <li className={pathname == "/DoctorStaffPatient" ? "active" : ""}>
              <Link
                to="/DoctorStaffPatient"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>Patient</span>
              </Link>
            </li>
            <li className={pathname == "/DoctorStaffOrder" ? "active" : ""}>
              <Link
                to="/DoctorStaffOrder"
                className=" nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <FaFirstOrderAlt />
                </i>
                <span>Orders</span>
              </Link>
            </li>
          </ul>
        </side>
      </div>
    </div>
  );
}
