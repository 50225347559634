import React, { useState, forwardRef, useContext, useEffect } from "react";
import MaterialTable from "material-table";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Loader } from "react-overlay-loader";
import { useParams } from "react-router-dom";
import Baseurl from "../../Component/Baseurl";
import axios from "axios";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ThemeContext } from "../../App";
import { Modal } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { useSnackbar } from "notistack";

const NotesView = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { theme, toggleTheme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setnote("");
    setname("");
  };
  const handleShow = () => setShow(true);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [loader, setLoader] = useState(true);
  const [Data, setData] = useState([]);
  const { id } = useParams();
  const Get = () => {
    axios
      .get(`${Baseurl.baseurl}superadmin/reffrel/notes/${id}`)
      .then((res) => {
        setLoader(false);
        setData(res.data.data);
        console.log(res.data);
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar(err.result.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Get();
  }, []);

  const [supplier, setsupplier] = useState([]);
  const Dme = () => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}superadmin/dme`)
      .then((res) => {
        setLoader(false);
        setsupplier(res?.data?.supplier);
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar(err.result.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Dme();
  }, []);
  const Superadmin = localStorage.getItem("Name");

  const [name, setname] = useState("");
  const [note, setnote] = useState("");
  const [na, setna] = useState(false);
  const [no, setno] = useState(false);

  const Submit = () => {
    if (name === "") {
      setna(true);
    }
    if (note === "") {
      setno(true);
    } else {
      setLoader(true);
      handleClose();
      const Data = {
        reffrel_id: id,
        dme_id: name,
        superadmin_name: Superadmin,
        superadmin_notes: note,
      };
      axios
        .post(`${Baseurl.baseurl}superadmin/note/store`, Data)
        .then((res) => {
          if (res.data.status === true) {
            setLoader(false);
            Get();
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          enqueueSnackbar(err.result.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">Super Admin Notes</h5>
              </div>
              <div className="col-md-6">
                <button
                  onClick={handleShow}
                  className="btn btn-primary float-right"
                >
                  <AiOutlineUserAdd className="font-20" />
                </button>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                columns={[
                  {
                    title: "Super Admin",
                    field: "",
                    render: (rowData) => (
                      <>
                        Name : <span>{rowData?.superadmin_name}</span> <br />
                        Notes : <span>{rowData?.superadmin_notes}</span> <br />
                        Date : <span>{rowData?.date}</span> <br />
                        Time : <span>{rowData?.time}</span>
                      </>
                    ),
                  },
                  {
                    title: "Doctor",
                    field: "",
                    render: (rowData) => (
                      <>
                        Name : <span>{rowData?.doctor_name}</span> <br />
                        Notes : <span>{rowData?.doctor_notes}</span> <br />
                        Date : <span>{rowData?.date}</span> <br />
                        Time : <span>{rowData?.time}</span>
                      </>
                    ),
                  },
                  {
                    title: "Dme Supplier",
                    field: "",
                    render: (rowData) => (
                      <>
                        Name : <span>{rowData?.dme_name}</span> <br />
                        Notes : <span>{rowData?.dme_notes}</span> <br />
                        Date : <span>{rowData?.date}</span> <br />
                        Time : <span>{rowData?.time}</span>
                      </>
                    ),
                  },
                  {
                    title: "Therapist",
                    field: "",
                    render: (rowData) => (
                      <>
                        Name : <span>{rowData?.therapist_name}</span> <br />
                        Notes : <span>{rowData?.therapist_notes}</span>
                        <br />
                        Date : <span>{rowData?.date}</span>
                        <br />
                        Time : <span>{rowData?.time}</span>
                        <br />
                      </>
                    ),
                  },
                ]}
                data={Data}
                options={{
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      {/*Note  store */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Notes</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="form-group col-md-12">
              <label>Dme Supplier</label>
              <select
                className="form-control"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                  setna(false);
                }}
              >
                <option>--select a value--</option>
                {supplier.map((e) => (
                  <option value={e.id}>{e.first_name}</option>
                ))}
              </select>
              {na ? (
                <div className="alert alert-danger" role="alert">
                  Dme Supplier Name is Required
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label>Notes</label>
              <textarea
                className="form-control"
                value={note}
                onChange={(e) => {
                  setnote(e.target.value);
                  setno(false);
                }}
              />
              {no ? (
                <div className="alert alert-danger" role="alert">
                  Notes is Required
                </div>
              ) : null}
            </div>
            <div className="col-md-12">
              <button
                className="btn btn-primary float-right"
                onClick={() => Submit()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Note  store */}
      <Footer />
    </>
  );
};
export default NotesView;
