import React, { useState, useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Modal, Button } from "react-bootstrap";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { ImCross } from "react-icons/im";
import { useSnackbar } from 'notistack';

export default function Admin() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(true)
  const [About, Setaboutus] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([])
  function add() {
    fetch(`${Baseurl.baseurl}client/about`).then((result) => {
      result.json().then((resp) => {
        setData(resp.about)
        console.log("resp", resp)
      })
      setLoader(false)
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    add();
  }, [])

  const SuperAdminToken = localStorage.getItem("superAdmin")


  const UpdateApi = () => {
    handleClose();
    setLoader(true)
    console.log(About)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${SuperAdminToken}`);

    var formdata = new FormData();
    formdata.append("aboutus", About);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${Baseurl.baseurl}superadmin/about/us`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        add();

        handleClose();
        if (result.status) {
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }

  const edit = (e) => {

    console.log("editfunction=============", e);
    handleShow();
    Setaboutus(e.aboutus);





  };
  return <div>
    <Header />
    {loader ? <Loader fullPage loading /> : null}
    <div className="main-content">
      <section>
        <div className='card'>

          <div className='card-header row'>
            <div className='col-md-6'>
              <h5 className='mb-0'>ABOUT US</h5>
            </div>
            <div className='col-md-6 text-right'>
              <Button className='btn btn-primary' onClick={() => edit(data)}>
                <i className='fa fa-edit'></i>
              </Button>
            </div>
          </div>
          <div className='card-body col-md-12'>
            <div className='text-white'>{data.aboutus}</div>
          </div>

        </div>
      </section>
    </div>
    <Footer />
    <Modal show={show} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>ABOUT US</Modal.Title>
        <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>About</label>
                <textarea type="text" value={About} onChange={(e) => Setaboutus(e.target.value)} className="form-control textareaabout" defaultValue="" required />
              </div>
            </div>
            <div className="col-md-12">
              <button type='button' className='btn btn-block btn-primary' onClick={() => UpdateApi()}>Submit</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>;

}