import React, { useState, forwardRef, useContext, useEffect } from "react";
import MaterialTable from "material-table";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../../App";
import Baseurl from "../../Component/Baseurl";
import axios from "axios";
import { CgNotes } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const SuperAdminNotes = () => {
  const Navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [loader, setLoader] = useState(true);
  const [Data, setData] = useState([]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const Get = () => {
    axios
      .get(`${Baseurl.baseurl}superadmin/reffrel`)
      .then((res) => {
        setData(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar(err.result.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Get();
  }, []);
  console.log(Data);

  const deleteva = (id) => {
    axios
      .get(`${Baseurl.baseurl}superadmin/reffrel/delete/${id}`)
      .then((res) => {
        if (res.data.status === true) {
          console.log(res);
          Get();
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar(err.result.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  return (
    <>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">Super Admin Referal</h5>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                columns={[
                  { title: "Dme Supplier", field: "dme_name" },
                  { title: "Notes", field: "notes" },
                  { title: "Description", field: "machine_name" },
                  { title: "Status", field: "status" },
                ]}
                data={Data}
                actions={[
                  {
                    icon: () => <CgNotes style={{ color: "#fff" }} />,
                    tooltip: "Notes",
                    onClick: (event, rowData) =>
                      Navigate(`/superadmin/note/view/${rowData.id}`),
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => deleteva(rowData.id),
                  },
                ]}
                options={{
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default SuperAdminNotes;
