import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../Component/Dmeheader';
import Footer from '../Component/Footer';
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSnackbar } from 'notistack';

import Baseurl from '../Component/Baseurl'

export default function InviteTherapist() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Data Table
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    // Data table
    const Dme_id = localStorage.getItem("dmeId")

    // Fetch DATA
    const [loader, setLoader] = useState(true)
    const [patient, setpatient] = useState([])
    function Fetch() {
        axios.get(`${Baseurl.baseurl}supplier/therapist/patient/view/${Dme_id}`)
            .then((patient) => {
                console.log("patient", patient.data.view_patients)
                setpatient(patient.data.view_patients)
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    useEffect(() => {
        Fetch();
    }, [""])
    // Fetch DATA

    return <div>
        <Header />
        <div className="main-content">
            {loader ? <Loader fullPage loading /> : null}
            <section>
                <div className='card'>
                    <div className='card-header row'>
                        <div className='col-md-12'>
                            <Link to="/DmeTherapist">
                                <button className='btn btn-primary float-right'>THERAPIST</button>
                            </Link>
                        </div>
                    </div>
                    <div className='card-body col-md-12'>
                        <MaterialTable
                            title="INVITED THERAPIST"
                            icons={tableIcons}
                            columns={[
                                { title: 'First Name', field: 'first_name' },
                                { title: 'Last Name', field: 'last_name' },
                                // { title: 'Therapist_User_Name', field: 'user_name' },
                                { title: 'Email', field: 'email' },
                                // { title: 'Patient_Id', field: 'id', render: rowData => rowData.patient.id },
                                { title: 'Patient First Name', field: 'first_name', render: rowData => rowData.patient.first_name },
                                { title: 'Patient Last Name', field: 'last_name', render: rowData => rowData.patient.last_name },
                                { title: 'Patient Email', field: 'email', render: rowData => rowData.patient.email },
                                // { title: 'Patient_State', field: 'state', render: rowData => rowData.patient.state },
                                // { title: 'Patient_City', field: 'city', render: rowData => rowData.patient.city },
                                // { title: 'Patient_Country', field: 'country', render: rowData => rowData.patient.country },
                                // { title: 'Patient_Address', field: 'address', render: rowData => rowData.patient.address },
                                // { title: 'Patient_Phone_No', field: 'phone_no', render: rowData => rowData.patient.phone_no },
                            ]}
                            data={patient}
                            options={{
                                headerStyle: {
                                  backgroundColor: '#1896C8',
                                  color: '#FFF'
                                },
                                rowStyle: {
                                  backgroundColor: '#1896C8',
                                  color: '#FFF'
                                },
                               
                                actionsColumnIndex: -1
                
                              }}
                        />
                    </div>
                </div>
            </section>
        </div >
        <Footer />
    </div >;
}