import React, { useRef, useState , useEffect } from "react";
import backgroundimg from '../img/background/back1.jpg'
import { Loader } from 'react-overlay-loader';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import img2 from "../img/stripemadh.png";
import $ from "jquery"

export default function Package() {
    const Navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const basicpaymentRef = useRef(null)
    const premiumpaymentRef = useRef(null)
    const Location = useLocation();
    console.log("Location", Location?.state?.id)
    const [success, setsuccess] = useState(false);
    const [error, seterror] = useState(false);
    const [loader, setLoader] = useState(false)
    // BASIC
    const [name, setname] = useState("basic");
    const [amount, setamount] = useState(50 * 100);
    const basic = () => {
        basicpaymentRef.current.onClick()
        return
    }
    // BASIC
    // Premium
    const [Premiumname, setPremiumname] = useState("Premium");
    const [Premiumnameamount, setPremiumnameamount] = useState(449 * 100);

    const Premium = () => {
        premiumpaymentRef.current.onClick()
        return
    }
    // Premium

    // basic token
    const basicPaymentSuccuss = (token) => {
        setLoader(true)
        console.log(token.id);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("dme_id", Location?.state?.id);
        formData.append("amount", amount);
        formData.append("source", token.id);
        axios.post(`${Baseurl.baseurl}supplier/payment`, formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                setLoader(false)
                console.log("res", res.data);
                if (res.data) {
                    setsuccess(true)
                    setLoader(false)
                    Navigate("/Dmelogin");
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });

                } else {
                    seterror(true)
                }
            });
    }
    // basic token

    // premiumtoken
    const premiumPaymentSuccuss = (token) => {
        setLoader(true)
        console.log(token.id);
        const formData = new FormData();
        formData.append("name", Premiumname);
        formData.append("dme_id", Location?.state?.id);
        formData.append("amount", amount);
        formData.append("source", token.id);
        axios.post(`${Baseurl.baseurl}supplier/payment`, formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                setLoader(false)
                console.log("res", res.data);
                if (res.data) {
                    Navigate("/Dmelogin");
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    setsuccess(true)
                    setLoader(false)
                } else {
                    seterror(true)

                }
            });
    }
    // premiumtoken
    return (
        <div className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
            {loader ? <Loader fullPage loading /> : null}
            <div className="container">
                <div className="row">
                    <div className="offset-md-2 col-md-8 offset-md-2">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="text-center">DME SUPPLIER PACKAGES</h3>
                            </div>
                            {success ? <div className="alert alert-success" role="alert">Package Successfully Created! </div> : null}
                            {error ? <div className="alert alert-success" role="alert">Some Thing Went Wrong! </div> : null}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="pricing pricing-highlight">
                                            <div className="pricing-title text-white">
                                                BASIC
                                            </div>
                                            <div className="pricing-padding">
                                                <div className="pricing-price text-white">
                                                    <div>$50</div>
                                                    <div>per month</div>
                                                </div>
                                                <div className="pricing-details">
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Can HANDLE 10 PATIENTS.</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">INVITE 10 BASIC DOCTORS.</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">INVITE 10 BASIC THERAPIST.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pricing-cta">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={() => { basic() }}
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pricing pricing-highlight">
                                            <div className="pricing-title text-white">
                                                PREMIUM
                                            </div>
                                            <div className="pricing-padding">
                                                <div className="pricing-price text-white">
                                                    <div>$449</div>
                                                    <div>per month</div>
                                                </div>
                                                <div className="pricing-details">
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">CAN HANDLE 400+ PATIENTS</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Invite 400+ PREMIUM Doctors.</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Invite 400+ PREMIUM THERAPIST.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-cta">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={Premium}
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <StripeCheckout
                                        name="DME SUPPLIER"
                                        image={img2}
                                        ref={basicpaymentRef}
                                        className="d-none"
                                        amount={amount}
                                        token={basicPaymentSuccuss}
                                        stripeKey="pk_test_51KeE33EHvEFI2kYz3KVSPeyovWZpEOYPUmPUz6NvkOZqMoxu4olBtizG0OGAkOcPQjiAi0ZQIchW5zl2JXfpAzCr00j3J3IXyp"
                                    />
                                    <StripeCheckout
                                        name="DME SUPPLIER"
                                        image={img2}
                                        ref={premiumpaymentRef}
                                        className="d-none"
                                        amount={Premiumnameamount}
                                        token={premiumPaymentSuccuss}
                                        stripeKey="pk_test_51KeE33EHvEFI2kYz3KVSPeyovWZpEOYPUmPUz6NvkOZqMoxu4olBtizG0OGAkOcPQjiAi0ZQIchW5zl2JXfpAzCr00j3J3IXyp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
