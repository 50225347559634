import React, { useState, useEffect, forwardRef , useContext} from 'react';
import MaterialTable from 'material-table';
import StaffHeader from '../Component/StaffHeader';
import Footer from '../Component/Footer';
import { Modal, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import { ThemeContext } from "../App"

export default function StaffDoctor() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { theme, toggleTheme } = useContext(ThemeContext)

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const [loader, setLoader] = useState(true)
    const [myid, Setmyid] = useState("")
    // ADD
    const [first_name, Setfirst_name] = useState("")
    const [last_name, Setlast_name] = useState("")
    const [user_name, Setuser_name] = useState("")
    const [email, Setemail] = useState("")
    const [password, Setpassword] = useState("")
    // ADD
    const [Data, SetData] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        Setfirst_name("")
        Setlast_name("")
        Setemail("")
        Setuser_name("")
        Setpassword("")
    }
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const [data, setData] = useState([])
    //SHOW
    function add() {
        fetch(`${Baseurl.baseurl}superadmin/doctor`).then((result) => {
            result.json().then((resp) => {
                setData(resp.doctors)
                setLoader(false)
            })

        })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    useEffect(() => {
        add();
    }, [""])
    console.warn(data)
    //ADD
    const [FirstName, setFirstName] = useState(false);
    const [LastName, setLastName] = useState(false);
    const [Email, setEmail] = useState(false);
    const [Pass, setPass] = useState(false);
    const [User, setUser] = useState(false);
    const AddAdmin = () => {
        if (first_name === "") {
            setFirstName(true)
        } else
            if (last_name === "") {
                setLastName(true)
            } else
                if (user_name === "") {
                    setUser(true)
                } else if (email === "") {
                    setEmail(true)
                } else if (password === "") {
                    setPass(true)
                } else {
                    handleClose();
                    setLoader(true)
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer`);

                    var formdata = new FormData();
                    formdata.append("first_name", first_name);
                    formdata.append("last_name", last_name);
                    formdata.append("user_name", user_name);
                    formdata.append("email", email);
                    formdata.append("password", password);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${Baseurl.baseurl}superadmin/doctor/store `, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log(result)
                            setFirstName(false)
                            setLastName(false)
                            setEmail(false)
                            setPass(false)
                            setUser(false)
                            setLoader(false)
                            if (result.status) {

                                enqueueSnackbar(result.message, {
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },
                                    variant: 'success',
                                    autoHideDuration: 2000,
                                });
                                add();
                                handleClose();

                            } if (result.status === false && result.errors.email[0]) {
                                setLoader(false)
                                enqueueSnackbar(result.errors.email[0], {
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },
                                    variant: 'error',
                                    autoHideDuration: 2000,
                                });
                            }
                            if (result.status === false && result.message) {
                                enqueueSnackbar(result.message, {
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },
                                    variant: 'error',
                                    autoHideDuration: 2000,
                                });
                            }
                        })
                        .catch(error => {
                            setLoader(false)
                            enqueueSnackbar(error.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'error',
                                autoHideDuration: 2000,
                            });
                        });
                }
    }
    // delete
    function Deleteva(id) {
        setLoader(true)
        fetch(`${Baseurl.baseurl}superadmin/doctor/delete/${id}`, {
            method: "GET"
        }).then((result) => {
            result.json().then((resp) => {
                setLoader(false)
                if (result.status) {
                    enqueueSnackbar("Doctor Successfully Deleted", {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    add();
                } else {
                    enqueueSnackbar(result.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                }
                console.warn(resp)
            })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        })
        add();
    }
    // EDIT
    const edit = (e) => {
        console.log("edit=============", e);
        handleShow2();
        Setfirst_name(e.first_name);
        Setlast_name(e.last_name);
        Setuser_name(e.user_name);
        Setemail(e.email);
        Setmyid(e.id)
    };

    // update
    const UpdateApi = () => {
        if (first_name === "") {
            setFirstName(true)
        } else if (user_name === "") {
            setUser(true)
        } else if (last_name === "") {
            setLastName(true)
        } else if (email === "") {
            setEmail(true)
        } else {
            handleClose2();
            setLoader(true)
            console.log("myid", myid)
            var myHeaders = new Headers();
            var formdata = new FormData();
            formdata.append("first_name", first_name);
            formdata.append("last_name", last_name);
            formdata.append("user_name", user_name);
            formdata.append("email", email);
            formdata.append("id", myid);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${Baseurl.baseurl}superadmin/doctor/update/${myid}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setFirstName(false)
                    setLastName(false)
                    setEmail(false)
                    setUser(false)
                    setLoader(false)
                    console.log(result);
                    add();
                    handleClose();
                    if (result.status) {
                        setLoader(false)
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                        handleClose2();
                    } if (result.status === false && result.errors.email[0]) {
                        setLoader(false)
                        enqueueSnackbar(result.errors.email[0], {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                    if (result.status === false && result.message) {
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }

    //  Doctor Patient DATA
    const [dpatient, setdpatient] = useState([])
    function DoctorPatient(id) {
        setLoader(true)
        fetch(`${Baseurl.baseurl}staff/assign/view/${id}`).then((result) => {
            result.json().then((resp) => {
                console.log(resp.view_patients, "Staff Doctor Patient")
                setdpatient(resp.view_patients)
                setLoader(false)
                handleShow3();
            })
        })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    // Doctor Patient DATA

    
    return <div>
        <StaffHeader />
        {loader ? <Loader fullPage loading /> : null}
        <div className="main-content">
            <section>
                <div className='card'>
                    <div className='card-header row'>
                        <div className='col-md-6'>
                            <h5 className='mb-0'>DOCTOR</h5>
                        </div>
                        <div className='col-md-6'>
                            <Button className='btn btn-primary float-right' onClick={handleShow}>
                                <AiOutlineUserAdd className='delete-btn' />
                            </Button>
                        </div>
                    </div>
                    <div className='card-body col-md-12'>
                        <MaterialTable
                            title=""
                            icons={tableIcons}
                            columns={[
                                {
                                    title: 'First Name', field: 'first_name', render: rowData => <>
                                        <a onClick={() => DoctorPatient(rowData.id)}>{rowData.first_name}</a>
                                    </>
                                },
                                { title: 'Last Name', field: 'last_name' },
                                // { title: 'User Name', field: 'user_name' },
                                { title: 'Email', field: 'email' },
                                { title: 'Package', field: 'packages', render: rowData => rowData.packages?.name },
                                // { title: 'Patient First Name', field: 'first_name', render: rowData => rowData.patient?.first_name },
                                // { title: 'Patient Last Name', field: 'last_name', render: rowData => rowData.patient?.last_name },
                                // { title: 'Patient Email', field: 'email', render: rowData => rowData.patient?.email },
                            ]}
                            data={data}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => edit(rowData)
                                },

                                rowData => ({
                                    icon: 'delete',
                                    tooltip: 'Delete',
                                    onClick: (event, rowData) => Deleteva(rowData.id)
                                })
                            ]}
                            style={{
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                              }}
                              options={{
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                headerStyle: {
                                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                  color: '#FFF'
                                },
                                rowStyle: {
                                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                  color: '#FFF'
                                },
                                actionsColumnIndex: -1
                              }}
                        />
                    </div>
                </div>
            </section>
        </div>
        <Footer />
        {/* add */}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title>Add New Doctor</Modal.Title>
                <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row justify-content">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" id='package' value={first_name} onChange={(e) => {
                                        Setfirst_name(e.target.value)
                                        setFirstName(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                AddAdmin()
                                            }
                                        }}
                                        className="form-control" defaultValue="" required />
                                    {FirstName ? <div class="alert alert-danger" role="alert">
                                        First Name is Required!
                                    </div> : null}
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" value={last_name} onChange={(e) => {
                                        Setlast_name(e.target.value)
                                        setLastName(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                AddAdmin()
                                            }
                                        }}
                                        className="form-control" defaultValue="" required />
                                    {LastName ? <div class="alert alert-danger" role="alert">
                                        Last Name is Required!
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>User Name</label>
                                    <input type="email" value={user_name} onChange={(e) => {
                                        Setuser_name(e.target.value)
                                        setUser(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                AddAdmin()
                                            }
                                        }}
                                        className="form-control" required defaultValue="abc@gmail.com" />
                                    {User ? <div class="alert alert-danger" role="alert">
                                        User Name is Required!
                                    </div> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" value={email} onChange={(e) => {
                                        Setemail(e.target.value)
                                        setEmail(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                AddAdmin()
                                            }
                                        }}
                                        className="form-control" required defaultValue="abc@gmail.com" />
                                    {Email ? <div class="alert alert-danger" role="alert">
                                        Email is Required!
                                    </div> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" value={password} onChange={(e) => {
                                        Setpassword(e.target.value)
                                        setPass(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                AddAdmin()
                                            }
                                        }}
                                        className="form-control" defaultValue="aaallli" required />
                                    {Pass ? <div class="alert alert-danger" role="alert">
                                        Enter Maximum 8 Digit!
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button type='button' className='btn btn-block btn-primary' onClick={() => AddAdmin()} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {/* add */}

        {/* Edit  */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header >
                <Modal.Title>Update Doctor Detail</Modal.Title>
                <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" btn btn-primary"> <ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row justify-content">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" id='package' value={first_name} onChange={(e) => {
                                        Setfirst_name(e.target.value)
                                        setFirstName(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                UpdateApi()
                                            }
                                        }}
                                        className="form-control" defaultValue="" required />
                                    {FirstName ? <div class="alert alert-danger" role="alert">
                                        First Name is Required!
                                    </div> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" value={last_name} onChange={(e) => {
                                        Setlast_name(e.target.value)
                                        setLastName(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                UpdateApi()
                                            }
                                        }}
                                        className="form-control" defaultValue="" required />
                                    {LastName ? <div class="alert alert-danger" role="alert">
                                        Last Name is Required!
                                    </div> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>User Name</label>
                                    <input type="text" value={user_name} onChange={(e) => {
                                        Setuser_name(e.target.value)
                                        setUser(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                UpdateApi()
                                            }
                                        }}
                                        className="form-control" defaultValue="" required />
                                    {User ? <div class="alert alert-danger" role="alert">
                                        User Name is Required!
                                    </div> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" value={email} onChange={(e) => {
                                        Setemail(e.target.value)
                                        setEmail(false)
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                UpdateApi()
                                            }
                                        }}
                                        className="form-control" required defaultValue="abc@gmail.com" />
                                    {Email ? <div class="alert alert-danger" role="alert">
                                        Email is Required!
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button type='button' className='btn btn-block btn-primary' onClick={() => UpdateApi()} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {/* Edit  */}
        {/*   Doctor Patients */}
        <Modal
            size="xl"
            show={show3} onHide={handleClose3}>
            <Modal.Header>
                <Modal.Title>Doctor Patients</Modal.Title>
                <i onClick={handleClose3} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className='card-body col-md-12'>
                    <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                            { title: 'Doctor Email', field: 'email', render: rowData => rowData.email },
                            { title: 'Patient First Name', field: 'first_name', render: rowData => rowData.patient?.first_name },
                            { title: 'Patient Last Name', field: 'last_name', render: rowData => rowData.patient?.last_name },
                            { title: 'Patient Email', field: 'email', render: rowData => rowData.patient?.email },
                        ]}
                        data={dpatient}
                        style={{
                            backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                          }}
                          options={{
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            headerStyle: {
                              backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                              color: '#FFF'
                            },
                            rowStyle: {
                              backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                              color: '#FFF'
                            },
                            actionsColumnIndex: -1
                          }}
                    />
                </div>
            </Modal.Body>
        </Modal>
        {/* Doctor Patients */}
    </div>;
}