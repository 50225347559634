import { ThemeContext } from "../App"
import { Loader } from 'react-overlay-loader';
import React, { forwardRef, useState, useEffect, useContext } from "react";
import MaterialTable from 'material-table';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from 'notistack';
import { ImCross } from "react-icons/im";
import { Modal } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";

export default function Therapisttask() {
    const Id = localStorage.getItem("therapist_id")
    
    // modal
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        settask_name("");
        setDescription("");
        setdate("");
        setLoader(false)
        setShow(false)
    }
    const [show2, setShow2] = useState(false);
    const handleShow2 = () => setShow2(true);
    const handleClose2 = () => {
        settask_name("");
        setDescription("");
        setdate("");
        setShow2(false)
        setLoader(false)
    }
    // modal

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const { theme, toggleTheme } = useContext(ThemeContext)
    const [loader, setLoader] = useState(true)
    const { enqueueSnackbar } = useSnackbar();


    // view 
    const [Data, setData] = useState([]);
    const View = () => {
        axios.get(`${Baseurl.baseurl}therapist/therapisttask/${Id}`)
            .then((res => {
                setLoader(false)
                setData(res.data.tasks)
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    useEffect(() => {
        View();
    }, [])
    console.log(Data, "Task view")
    // view 

    // Store
    const [task_name, settask_name] = useState("");
    const [Description, setDescription] = useState("");
    const [date, setdate] = useState("");
    const [tas, settas] = useState(false);
    const [des, setdes] = useState(false);
    const [da, setda] = useState(false);
    const Store = () => {
        if (task_name === "") {
            settas(true)
        } else if (Description === "") {
            setdes(true)
        } else if (date === "") {
            setda(true)
        } else {
            handleClose();
            setLoader(true)
            const Data = {
                'description': Description,
                'task_name': task_name,
                'date': date,
                'therapist_id': Id,

            }
            axios.post(`${Baseurl.baseurl}therapist/therapisttask/store`, Data)
                .then(({ data }) => {
                    console.log('store', data)
                    if (data.status) {
                        enqueueSnackbar(data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        })
                        View();
                        settask_name("");
                        setDescription("");
                        setdate("");
                    } else {
                        setLoader(false)
                        enqueueSnackbar(data.errors.task_name[0], {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        })
                    }
                })
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    // Store

    // edit states
    const [id, setid] = useState("");
    const Edit = (e) => {
        setLoader(true)
        console.log("edit", e);
        handleShow2();
        settask_name(e.task_name);
        setid(e.id);
        setDescription(e.description);
        setdate(e.date)
    };
    // edit states

    // update
    const Update = () => {
        setLoader(false)
        if (task_name === "") {
            settas(true)
        } else if (Description === "") {
            setdes(true)
        } else if (date === "") {
            setda(true)
        } else {
            handleClose2();
            setLoader(true)
            const Data = {
                'description': Description,
                'task_name': task_name,
                'date': date,
                'therapist_id': Id,

            }
            axios.post(`${Baseurl.baseurl}therapist/therapisttask/update/${id}`, Data)
                .then(({ data }) => {
                    console.log('edit', data)
                    if (data.status) {
                        enqueueSnackbar(data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        })
                        View();
                        settask_name("");
                        setDescription("");
                        setdate("");
                    } else {
                        enqueueSnackbar(data.errors.task_name[0], {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        })
                    }
                })
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    // update

    // delete
    const Delete = (id) => {
        setLoader(true)
        axios.get(`${Baseurl.baseurl}therapist/therapisttask/delete/${id}`)
            .then((result) => {
                setLoader(false)
                console.log(result.data)
                if (result.status) {
                    View();
                    setLoader(false)
                    enqueueSnackbar(result.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                } else {
                    setLoader(false)
                    enqueueSnackbar(result.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                }
            })
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            })
    }
    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <div className="main-content">
                <section>
                    <div className='card'>
                        <div className='card-header row'>
                            <div className='col-md-6'>
                                <h5 className='mb-0'>Task</h5>
                            </div>
                            <div className="col-md-6">
                                <button className='btn btn-primary float-right' onClick={handleShow}>
                                    <AiOutlineUserAdd className='delete-btn' />
                                </button>
                            </div>
                        </div>
                        <div className='card-body col-md-12'>
                            <MaterialTable
                                title=""
                                icons={tableIcons}
                                columns={[
                                    { title: 'Task Name', field: 'task_name' },
                                    { title: 'Date', field: 'date' },
                                    { title: 'Description', field: 'description' },
                                ]}

                                data={Data}
                                actions={[
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit',
                                        onClick: (event, rowData) => Edit(rowData)
                                    },
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        onClick: (event, rowData) => Delete(rowData.id)
                                    })
                                ]}

                                style={{
                                    backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                }}
                                options={{
                                    // pageSize: 20,
                                    // pageSizeOptions: [20, 50, 100],
                                    headerStyle: {
                                        backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                        color: '#FFF'
                                    },
                                    rowStyle: {
                                        backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                        color: '#FFF'
                                    },
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>

            {/* store */}
            <Modal
                show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Add Task</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Task name</label>
                                        <input type="text" value={task_name} onChange={(e) => {
                                            settas(false)
                                            settask_name(e.target.value)
                                        }} className="form-control" />
                                        {tas ? <div className="alert alert-danger" role="alert">Task Name is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input type="text" value={Description} onChange={(e) => {
                                            setdes(false)
                                            setDescription(e.target.value)
                                        }} className="form-control" defaultValue="" required />
                                        {des ? <div className="alert alert-danger" role="alert">Description is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <input type="date" value={date} onChange={(e) => {
                                            setda(false)
                                            setdate(e.target.value)
                                        }} className="form-control" />
                                        {da ? <div className="alert alert-danger" role="alert">Date is required </div> : null}

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => Store()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* store */}

            {/* store Update */}
            <Modal
                show={show2} onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Edit Task</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Task name</label>
                                        <input type="text" value={task_name} onChange={(e) => {
                                            settas(false)
                                            settask_name(e.target.value)
                                        }} className="form-control" />
                                        {tas ? <div className="alert alert-danger" role="alert">Task Name is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input type="text" value={Description} onChange={(e) => {
                                            setdes(false)
                                            setDescription(e.target.value)
                                        }} className="form-control" defaultValue="" required />
                                        {des ? <div className="alert alert-danger" role="alert">Description is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <input type="date" value={date} onChange={(e) => {
                                            setda(false)
                                            setdate(e.target.value)
                                        }} className="form-control" />
                                        {da ? <div className="alert alert-danger" role="alert">Date is required </div> : null}

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => Update()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* store  Update*/}
        </>
    )
}