import React, { useState, useEffect , useContext } from 'react';
import StaffHeader from '../Component/StaffHeader';
import Footer from '../Component/Footer';
import img1 from '../img/banner/1.png'
import img2 from '../img/banner/2.png'
import img3 from '../img/banner/3.png'
import img4 from '../img/banner/4.png'
import img5 from '../img/banner/5.png'
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
// import Task from './Task'
import { ThemeContext } from "../App"

export default function StaffDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const { theme, toggleTheme } = useContext(ThemeContext)

  const [loader, setLoader] = useState(true)
  const [total, settotal] = useState("")
  function GetCount() {
    fetch(`${Baseurl.baseurl}superadmin/total`).then((result) => {
      result.json().then((total) => {
        settotal(total)
        setLoader(false)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar("Please Check Your Internet Connection!", {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    GetCount();
  }, [])

  return <div>
    <StaffHeader />
    {loader ? <Loader fullPage loading /> : null}
    <div classname="container">
      <div className="main-content">
        <section className="section">
          <div className="row">
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img1} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">DME SUPPLIER</h6>
                        <h4 className="text-right"><span>{total.total_dme}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img2} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">STAFF</h6>
                        <h4 className="text-right"><span>{total.total_staff}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img3} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">PATIENT</h6>
                        <h4 className="text-right"><span>{total.total_patient}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-2 col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img4} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">DOCTORS</h6>
                        <h4 className="text-right"><span>{total.total_doctor}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img5} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">THERAPIST</h6>
                        <h4 className="text-right"><span>{total.total_therapist}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    {/* <Task /> */}
    <Footer />
  </div>;
}
