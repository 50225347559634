import React, { useRef, useState , useEffect } from "react";
import backgroundimg from '../img/background/back1.jpg'
import { Loader } from 'react-overlay-loader';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import Baseurl from '../Component/Baseurl';
import { useSnackbar } from 'notistack';
import img2 from "../img/stripemadh.png";
import $ from 'jquery'
export default function Doctorpackage() {
    const { enqueueSnackbar} = useSnackbar();

    // FREE
    const [name, setname] = useState("free")
    const [amount, setamount] = useState("0")
    const [error, seterror] = useState(false);

    const FREE = () => {
        const formData = {
            'name': name,
            'doctor_id': Location?.state?.id,
            'amount': amount
        }
        axios.post(`${Baseurl.baseurl}doctor/free/package`, formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                console.log("res", res.data);
                if (res.data) {
                    Navigate("/Doctorlogin");
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    setLoader(false)
                } else {
                    seterror(true)

                }
            });
    }
    // FREE

    const Navigate = useNavigate();
    const premiumpaymentRef = useRef(null)
    const Location = useLocation();
    console.log("Location", Location?.state?.id)
    // const [error, seterror] = useState(false);

    const [loader, setLoader] = useState(false)
    // Premium
    const [pname, setpname] = useState("Premium");
    const [pamount, setpamount] = useState(49 * 100);

    const Premium = () => {
        premiumpaymentRef.current.onClick()
        return
    }
    // Premium


    // premiumtoken
    const premiumPaymentSuccuss = (token) => {
        console.log(token.id);
        const formData = {
            'name': pname,
            'doctor_id': Location?.state?.id,
            'amount': pamount,
            'source': token.id,
        }
        axios.post(`${Baseurl.baseurl}doctor/payment`, formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                console.log("res", res.data);
                if (res.data) {
                    Navigate("/Doctorlogin");
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    setLoader(false)
                } else {
                    seterror(true)

                }
            });
    }
    // premiumtoken

    const [themeColor, setThemeColor] = useState('')
    useEffect(() => {
      let data = localStorage.getItem('SettingValue');
      setThemeColor(data)
      console.log("App.Js", data);
    }, [localStorage.getItem('SettingValue')])
    useEffect(() => {
      Setting(themeColor)
    }, [themeColor])
    const Setting = (value) => {
      if (value == "light") {
        $("body").removeClass("dark-sidebar");
        $("body").addClass("light-sidebar");
        $("body").removeClass();
        $("body").addClass("light");
        $("body").addClass("light-sidebar");
        $("body").addClass("theme-white");
        $(".choose-theme li").removeClass("active");
        $(".choose-theme li[title|='white']").addClass("active");
        $(".selectgroup-input[value|='1']").prop("checked", true);
        $('.MuiPaper-root').removeClass('table-dark')
        $('.MTableHeader-header-13').removeClass('table-dark')
        $('.MuiTable-root').removeClass('table-dark')
        $('.MTableToolbar-root-5').removeClass('table-dark')
      } else {
        console.log('th -> ', $('table thead tr th'))
        $('.MuiPaper-root').addClass('table-dark')
        $('.MuiTable-root').addClass('table-dark')
        $('.MTableHeader-header-13').addClass('table-dark')
        $('.MTableHeader-header-13 ').addClass('table-dark')
        $('.MTableToolbar-root-5').addClass('table-dark')
        $("body").removeClass("light-sidebar");
        $("body").addClass("dark-sidebar");
        $("body").removeClass();
        $("body").addClass("dark");
        $("body").addClass("dark-sidebar");
        $("body").addClass("theme-black");
        $(".choose-theme li").removeClass("active");
        $(".choose-theme li[title|='black']").addClass("active");
        $(".selectgroup-input[value|='2']").prop("checked", true);
      }
    }
    return (
        <div className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
            {loader ? <Loader fullPage loading /> : null}
            <div className="container">
                <div className="row">
                    <div className="offset-md-2 col-md-8 offset-md-2">
                        <div className="card card-primary mt-4">
                            <div className="card-header">
                                <h3 className="text-center">DOCTOR PACKAGES</h3>
                            </div>
                            {error ? <div className="alert alert-success" role="alert">Some Thing Went Wrong! </div> : null}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="pricing pricing-highlight">
                                            <div className="pricing-title text-white">FREE</div>
                                            <div className="pricing-padding">
                                                <div className="pricing-price text-white">
                                                    <div>Free</div>
                                                    <div>per month</div>
                                                </div>
                                                <div className="pricing-details">
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pricing-cta">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={FREE}
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pricing pricing-highlight">
                                            <div className="pricing-title text-white">
                                                PREMIUM
                                            </div>
                                            <div className="pricing-padding">
                                                <div className="pricing-price text-white">
                                                    <div>$49</div>
                                                    <div>per month</div>
                                                </div>
                                                <div className="pricing-details">
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                    <div className="pricing-item">
                                                        <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                        <div className="pricing-item-label text-white">Lorem Ipsum</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-cta">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={Premium}
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <StripeCheckout
                                        name="DOCTOR"
                                        image={img2}
                                        ref={premiumpaymentRef}
                                        className="d-none"
                                        amount={pamount}
                                        token={premiumPaymentSuccuss}
                                        stripeKey="pk_test_51KeE33EHvEFI2kYz3KVSPeyovWZpEOYPUmPUz6NvkOZqMoxu4olBtizG0OGAkOcPQjiAi0ZQIchW5zl2JXfpAzCr00j3J3IXyp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
