import React, { useState, useEffect, forwardRef, useContext } from "react";
import MaterialTable from "material-table";
import Header from "../Component/Dmeheader";
import Footer from "../Component/Footer";
import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";
import { GrDocumentText } from 'react-icons/gr';
import { useNavigate } from "react-router-dom";

export default function DmeSupplierPatient() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const Navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const Id = localStorage.getItem("dmeId");

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    get();
  }, []);

  //GET
  function get() {
    fetch(`${Baseurl.baseurl}supplier/patient/view/${Id}`)
      .then((result) => {
        result.json().then((resp) => {
          setLoader(false);
          console.log(resp.view_patients);
          setData(resp.view_patients[0].patients);
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  const [reffrel, setreffrel] = useState([]);

  //GET Refferal
  function Refferal(id) {
    setLoader(true);
    fetch(`${Baseurl.baseurl}supplier/patient/reffrel/${id}`)
      .then((result) => {
        result.json().then((resp) => {
          setLoader(false);
          console.log(resp.view[0].reffrel);
          setreffrel(resp.view[0].reffrel);
          handleShow5();
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  //GET Refferal

  return (
    <div>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-12">
                <h5 className="mb-0">Patient's Documents</h5>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={[
                  {
                    title: "First Name",
                    field: "first_name",
                    render: (rowData) => (
                      <>
                        <a onClick={() => Refferal(rowData.id)}>
                          {rowData.first_name}
                        </a>
                      </>
                    ),
                  },
                  { title: "Last Name", field: "last_name" },
                  { title: "Address", field: "address" },
                  { title: "City", field: "city" },
                  { title: "Country", field: "country" },
                  { title: "State", field: "state" },
                  { title: "Phone No", field: "phone_no" },
                  { title: "Email", field: "email" },
                ]}
                actions={[
                  {
                    icon: () => <GrDocumentText />,
                    tooltip: "Document",

                    onClick: (event, rowData) =>
                      Navigate(`/dme/document/${rowData.id}`),
                  },
                ]}
                data={data}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/*   Patients orders */}
      <Modal size="xl" show={show5} onHide={handleClose5}>
        <Modal.Header>
          <Modal.Title>Patients Orders</Modal.Title>
          <i
            onClick={handleClose5}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body col-md-12">
            <MaterialTable
              title=""
              icons={tableIcons}
              style={{
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
              }}
              columns={[
                { title: "ID", field: "id", render: (rowData) => rowData.id },
                {
                  title: "Doctor/Therapist Name",
                  field: "doctor_name",
                  render: (rowData) => rowData.doctor_name,
                },
                {
                  title: "Description",
                  field: "machine_name",
                  render: (rowData) => rowData.machine_name,
                },
                {
                  title: "Notes",
                  field: "notes",
                  render: (rowData) => rowData.notes,
                },
                {
                  title: "Status",
                  field: "status",
                  render: (rowData) => rowData.status,
                },
              ]}
              data={reffrel}
              options={{
                // pageSize: 20,
                // pageSizeOptions: [20, 50, 100],
                headerStyle: {
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                  color: "#FFF",
                },
                rowStyle: {
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                  color: "#FFF",
                },
                actionsColumnIndex: -1,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* Patients orders */}
      <Footer />
    </div>
  );
}
