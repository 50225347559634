import React from "react";
import { Link } from "react-router-dom";
import backgroundimg from "../img/background/back.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import axios from "axios";

export default function DoctorstaffLogin() {
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const Navigate = useNavigate();

    const [email, setemail] = useState('');
    const [em, setem] = useState(false);
    const [password, setpassword] = useState('');
    const [pss, setpss] = useState(false);
    const Login = () => {
        if (email === "") {
            setem(true)
        } else if (password === "") {
            setpss(true)
        } else {
            setLoader(true)
            const Data = {
                'email': email,
                'password': password
            }
            axios.post(`${Baseurl.baseurl}dmestaff/login`, Data)
                .then((res => {
                    console.log(res)
                    if (res?.data?.status == true) {
                        setLoader(false)
                        localStorage.setItem("dmestaffaccess", res.data?.token)
                        localStorage.setItem("dmestaffname", res.data?.user?.first_name)
                        localStorage.setItem("dmestaffid", res.data?.user?.id)
                        localStorage.setItem("dmeId", res.data?.user?.dme_id)
                        localStorage.setItem("SettingValue", "light")
                        Navigate("/DmeStaffDashboard");
                        enqueueSnackbar(res?.data?.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        })
                    } if (res?.data?.status == false && res?.data?.message == "Unauthorised") {
                        setLoader(false)
                        enqueueSnackbar(res?.data?.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        })
                    } if (res?.data?.errors?.email[0]) {
                        setLoader(false)
                        enqueueSnackbar(res?.data?.errors?.email[0], {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        })
                    } else {

                    }
                }))
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }

    return (
        <div>
            <div
                id="app"
                className="page-head-bg"
                style={{ backgroundImage: `url(${backgroundimg})` }}>
                {loader ? <Loader fullPage loading /> : null}
                <section className="section mt-4">
                    <div className="container mt-5">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary mt-2">
                                    <div className="card-header">
                                        <h3 className="text-center">DME-STAFF LOGIN</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-white">
                                                EMAIL
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                value={email}
                                                onChange={(e) => {
                                                    setemail(e.target.value)
                                                    setem(false)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label
                                                    className="control-label text-white"
                                                >
                                                    PASSWORD
                                                </label>
                                            </div>
                                            <input
                                                value={password}
                                                onChange={(e) => {
                                                    setpassword(e.target.value)
                                                    setpss(false)
                                                }}
                                                type="password"
                                                className="form-control"
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                name="password"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={() => Login()}
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            <Link to="/" className="text-white">Dashboard</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Don't have an account? <Link to="/Dme/staff/Register" className="text-white" >Create One</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Design By<a href="https://hnhtechsolutions.com/" target="_blank" className="text-white">  Hnh Tech Solutions</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
