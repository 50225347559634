import React from 'react';
import backgroundimg from '../img/background/back.jpg'
import img1 from '../img/banner/1.png'
import img2 from '../img/banner/2.png'
import img3 from '../img/banner/3.png'
import img4 from '../img/banner/4.png'
import img5 from '../img/banner/5.png'
import { Link } from 'react-router-dom';
export default function Madh() {
    return (
        <div className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
            <div className='container-fluid hight'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-md-12">
                                <h2 className="d-flex justify-content-center">MADH</h2>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-md-2 ml-2'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img1} width="100%" className='img-fluid' alt="SUPERADMIN" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/Madh" className='btn btn-primary d-flex justify-content-center' >SUPER ADMIN</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 ml-2'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img2} width="100%" className='img-fluid' alt="DME" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/DmeLogin" className='btn btn-primary d-flex justify-content-center' >DME SUPPLIER</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 ml-3'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img3} width="100%" className='img-fluid' alt="STAFF" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/StaffLogin" className='btn btn-primary d-flex justify-content-center' >VA</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img4} width="100%" className='img-fluid' alt="THERAPIST" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/Therapistlogin" className='btn btn-primary  d-flex justify-content-center'>THERAPIST</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img5} width="100%" className='img-fluid' alt="Doctor" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/Doctorlogin" className='btn btn-primary  d-flex justify-content-center'>DOCTOR</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 mr-4'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img1} width="100%" className='img-fluid' alt="Doctor" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/Dme/staff/Login" className='btn btn-primary  d-flex justify-content-center'>DME-STAFF</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='card cardhover'>
                                    <div className='card-header'>
                                        <div className="banner-img">
                                            <img src={img3} width="100%" className='img-fluid' alt="Doctor" />
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <Link to="/Doctor/staff/Login" className='btn btn-primary  d-flex justify-content-center'>DOCTOR-STAFF</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 text-white text-center">
                                <h6>Design By<a href="https://hnhtechsolutions.com/" target="_blank" className="text-white"> Hnh Tech Solutions</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}