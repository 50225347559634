import DmeHeader from "../Component/Dmeheader";
import Footer from "../Component/Footer";
import React, { useState, useEffect, forwardRef, useContext } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import Select from "react-select";
import { ThemeContext } from "../App";

export default function DmeOrders() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const { enqueueSnackbar } = useSnackbar();
  const Id = localStorage.getItem("dmeId");
  const dme_name = localStorage.getItem("dme_name");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setmachine_name("");
    setid("");
    setnotes("");
    setpatient_id("");
    setShow2(false);
  };

  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => {
    setShow4(false);
    setreffrel_notes("");
  };

  const handleShow4 = () => setShow4(true);
  const [loader, setLoader] = useState(true);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // NEW REFFERAL SHOW
  const [refferal, setrefferal] = useState([]);

  const Refferalshow = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/pending/${Id}`)
      .then((res) => {
        console.log(res.data?.reffrel, "Refferal show");
        setLoader(false);
        setrefferal(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  useEffect(() => {
    Refferalshow();
  }, []);
  // NEW REFFERAL SHOW

  // DME PATIENT
  const [Data, setData] = useState([]);
  const Fetch = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/patient/view/${Id}`)
      .then((Patient) => {
        console.log(Patient.data.view_patients[0].patients, "dme Patient");
        if (Patient.data.view_patients.length <= 0) {
          setData([]);
          setLoader(false);
        } else {
          setLoader(false);
          setData(Patient.data.view_patients[0].patients);
          console.log(Data, "+++++++++> daTA");
        }
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  const arr = Data.map((v) => ({
    value: v.id,
    label: v.first_name,
  }));
  console.log(arr, "array");
  useEffect(() => {
    Fetch();
  }, []);
  // DME PATIENT

  // NEW REFFERAL STORE
  const [machine_name, setmachine_name] = useState("");
  const [mach, setmach] = useState(false);
  const [notes, setnotes] = useState("");
  const [not, setnot] = useState(false);
  const [patient_id, setpatient_id] = useState("");
  const [pt, setpt] = useState(false);

  const Store = () => {
    if (patient_id === "") {
      setpt(true);
    } else if (machine_name === "") {
      setmach(true);
    } else if (notes === "") {
      setnot(true);
    } else {
      handleClose();
      setLoader(true);
      var myHeaders = new Headers();
      var formdata = new FormData();
      formdata.append("patient_id", patient_id);
      formdata.append("dme_name", dme_name);
      formdata.append("dme_id", Id);
      formdata.append("machine_name", machine_name);
      formdata.append("notes", notes);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${Baseurl.baseurl}supplier/order/store`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            Refferalshow();
            handleClose();
            setmachine_name("");
            setnotes("");
            setpatient_id("");
          } else if (result.status === false) {
            setLoader(false);
            setmachine_name("");
            setnotes("");
            setpatient_id("");
            enqueueSnackbar("Please Fill The Fields", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  // NEW REFFERAL STORE

  // delete
  const Deleteorder = (id) => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/order/delete/${id}`)
      .then((result) => {
        setLoader(false);
        console.log(result.status);
        if (result.status) {
          Refferalshow();
          enqueueSnackbar("Reffrel Successfully Deleted", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setLoader(false);
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  // edit states
  const Editorder = (e) => {
    console.log("edit=============", e);
    handleShow2();
    setmachine_name(e.machine_name);
    setid(e.id);
    setnotes(e.notes);
    setpatient_id(e.patient_id);
  };
  // edit states

  // update
  const [id, setid] = useState([]);
  const update = () => {
    if (patient_id === "") {
      setpt(true);
    } else if (machine_name === "") {
      setmach(true);
    } else if (notes === "") {
      setnot(true);
    } else {
      setLoader(true);
      handleClose2();
      var myHeaders = new Headers();
      var formdata = new FormData();
      formdata.append("patient_id", patient_id);
      formdata.append("dme_id", Id);
      formdata.append("dme_name", dme_name);
      formdata.append("machine_name", machine_name);
      formdata.append("notes", notes);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${Baseurl.baseurl}supplier/order/update/${id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === true) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            Refferalshow();
            handleClose();
            setmachine_name("");
            setnotes("");
            setpatient_id("");
          } else if (result.status === false) {
            handleClose();
            setmachine_name("");
            setnotes("");
            setpatient_id("");
            setLoader(false);
            enqueueSnackbar("Please Fill The Fields", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  // update

  // order status
  const [value, setvalue] = useState("");
  const [sta, setsta] = useState(false);
  const [id2, setid2] = useState([]);

  const [StatusArr, setStatusArr] = useState([]);
  const Status = (e, status) => {
    setStatusArr(status);
    handleShow3();
    setid2(e);
    console.log(e, "patient id");
  };

  const submit = () => {
    if (value === "") {
      setsta(true);
    } else {
      handleClose3();
      setLoader(true);
      const Data = {
        status: value,
      };
      axios
        .post(`${Baseurl.baseurl}supplier/order/status/${id2}`, Data)
        .then((result) => {
          console.log(result);
          if (result.status) {
            setLoader(false);
            // console.log("StatusArr",StatusArr)
            if (StatusArr[0].value == "evaluation") {
              Refferalshow();
              ROrder();
            } else if (StatusArr[0].value == "evaluation_completed") {
              Eva();
            } else if (StatusArr[0].value == "paperwork_in_process") {
              EvaComplete();
            } else if (StatusArr[0].value == "prior_auth_status") {
              PapWork();
            } else if (StatusArr[0].value == "prior_auth_received") {
              authStatus();
            } else if (StatusArr[0].value == "holding_rto") {
              authReceive();
            } else if (StatusArr[0].value == "rto") {
              holdingRto();
            } else if (StatusArr[0].value == "delivered") {
              rto();
            } else if (StatusArr[0].value == "authorization") {
              delivered();
            }
            enqueueSnackbar("Reffrel Status Successfully Updated", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            handleClose3();
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  // order status

  // Evalution REFFERAL SHOW
  const [Evalution, setEvalution] = useState([]);

  const Eva = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/evalaution/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setEvalution(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [EvalutionComplete, setEvalutionComplete] = useState([]);

  const EvaComplete = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/completed/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setEvalutionComplete(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [PaperWork, setPaperWork] = useState([]);

  const PapWork = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/work/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setPaperWork(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [PriorAuthStatus, setPriorAuthStatus] = useState([]);
  const authStatus = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/prior/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setPriorAuthStatus(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [PriorAuthReceive, setPriorAuthReceive] = useState([]);
  const authReceive = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/recevied/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setPriorAuthReceive(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [HoldingRto, setHoldingRto] = useState([]);
  const holdingRto = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/holding/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setHoldingRto(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [Rto, setRto] = useState([]);
  const rto = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/rto/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setRto(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [Delivered, setDelivered] = useState([]);
  const delivered = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/delivered/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setDelivered(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  const [Authorization, setAuthorization] = useState([]);
  const authorization = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/authorization/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "Evalution show");
        setLoader(false);
        setAuthorization(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Eva();
  }, []);
  // Evalution REFFERAL SHOW

  // Pending REFFERAL SHOW
  const [Pending, setPending] = useState([]);

  const Pen = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/cancel/${Id}`)
      .then((res) => {
        console.log(res.data.reffrel[0], "cancel");
        setLoader(false);
        setPending(res.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  useEffect(() => {
    Pen();
  }, []);
  // Pending REFFERAL SHOW

  // add note
  const [id3, setid3] = useState([]);

  const Notes = (e) => {
    console.log("Note", e);
    setid3(e);
    handleShow4();
  };

  const [reffrel_notes, setreffrel_notes] = useState("");
  const [refnot, setrefnot] = useState(false);

  const NoteStore = () => {
    if (reffrel_notes === "") {
      setrefnot(true);
    } else {
      handleClose4();
      setLoader(true);
      const Data = {
        dme_notes: reffrel_notes,
        dme_id: Id,
        reffrel_id: id3,
        dme_name: dme_name,
      };
      axios
        .post(`${Baseurl.baseurl}supplier/order/notes`, Data)
        .then((result) => {
          console.log(result.data);
          if (result.status) {
            setLoader(false);
            handleClose4();
            Refferalshow();
            enqueueSnackbar("Note Successfully Created", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            handleClose4();
            enqueueSnackbar("Something Wents wrong!", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  // add note

  // view notes
  const [view, setview] = useState([]);

  const viewnotes = () => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/order/notes/view/${Id}`)
      .then((result) => {
        console.log(result.data.reffrel, "Notes");
        setLoader(false);
        result.data?.reffrel?.map((e) => {
          setview(e?.notes);
        });
        handleShow5();
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  console.log(view);
  // view notes
  // RequestData
  const [RequestData, setRequestData] = useState([]);
  const ROrder = () => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/order/request/${Id}`)
      .then((res) => {
        console.log(res?.data);
        setRequestData(res?.data?.reffrel);
        setLoader(false);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    ROrder();
  }, []);

  return (
    <>
      <DmeHeader />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <div className="section">
          <div className="card">
            <div className="card-body">
              <div class="row">
                <div>
                  <ul class="nav nav-pills" id="myTab3" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        type="button"
                        onClick={() => Refferalshow()}
                        id="Refferal"
                        data-toggle="tab"
                        href="#Refferal1"
                        role="tab"
                        aria-controls="Refferal"
                        aria-selected="true"
                      >
                        New Referral
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        type="button"
                        onClick={() => Pen()}
                        id="cancelledorder"
                        data-toggle="tab"
                        href="#cancelledorder1"
                        role="tab"
                        aria-controls="cancelledorder"
                        aria-selected="false"
                      >
                        Cancelled Order
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        type="button"
                        onClick={() => Eva()}
                        id="Evaluation"
                        data-toggle="tab"
                        href="#Evaluation1"
                        role="tab"
                        aria-controls="Evaluation"
                        aria-selected="false"
                      >
                        Evaluation
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="EvaluationCompleted"
                        onClick={() => EvaComplete()}
                        data-toggle="tab"
                        href="#EvaluationCompleted1"
                        role="tab"
                        aria-controls="EvaluationCompleted"
                        aria-selected="false"
                      >
                        Evaluation Completed
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="PaperWork"
                        onClick={() => PapWork()}
                        data-toggle="tab"
                        href="#PaperWork1"
                        role="tab"
                        aria-controls="PaperWork"
                        aria-selected="false"
                      >
                        PaperWork in Process
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="PaperWorkRcvd"
                        onClick={() => authStatus()}
                        data-toggle="tab"
                        href="#PaperWorkRcvd1"
                        role="tab"
                        aria-controls="PaperWorkRcvd"
                        aria-selected="false"
                      >
                        Prior Auth Status
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="Status"
                        onClick={() => authReceive()}
                        data-toggle="tab"
                        href="#Status1"
                        role="tab"
                        aria-controls="Status"
                        aria-selected="false"
                      >
                        Prior Auth Received
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="holdingRtos"
                        onClick={() => holdingRto()}
                        data-toggle="tab"
                        href="#holdingRto"
                        role="tab"
                        aria-controls="holdingRtos"
                        aria-selected="false"
                      >
                        Holding RTO
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="Rtos"
                        onClick={() => rto()}
                        data-toggle="tab"
                        href="#Rto"
                        role="tab"
                        aria-controls="Rtos"
                        aria-selected="false"
                      >
                        RTO
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="delivered0"
                        onClick={() => delivered()}
                        data-toggle="tab"
                        href="#delivered"
                        role="tab"
                        aria-controls="delivered0"
                        aria-selected="false"
                      >
                        Delivered
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="authorization0"
                        onClick={() => authorization()}
                        data-toggle="tab"
                        href="#authorization"
                        role="tab"
                        aria-controls="authorization0"
                        aria-selected="false"
                      >
                        Authorization Expirations F/U
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="request0"
                        onClick={() => ROrder()}
                        data-toggle="tab"
                        href="#request"
                        role="tab"
                        aria-controls="request0"
                        aria-selected="false"
                      >
                        Order Request
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent2">
                    <div
                      class="tab-pane fade show active"
                      id="Refferal1"
                      role="tabpanel"
                      aria-labelledby="Refferal"
                    >
                      <section>
                        <div className="card">
                          <div className="card-header row">
                            <div className="col-md-6">
                              <h5 className="mb-0"></h5>
                            </div>
                            <div className="col-md-6">
                              <button
                                className="btn btn-primary float-right"
                                onClick={handleShow}
                              >
                                <AiOutlineUserAdd className="delete-btn" />
                              </button>
                            </div>
                          </div>
                          <div className="card-body col-md-12">
                            <MaterialTable
                              title=""
                              icons={tableIcons}
                              columns={[
                                {
                                  title: "Patient Name",
                                  field: "first_name",
                                  render: (rowData) =>
                                    rowData.patient_reffrel.first_name,
                                },
                                {
                                  title: "Patient Email",
                                  field: "email",
                                  render: (rowData) =>
                                    rowData.patient_reffrel.email,
                                },
                                { title: "Description", field: "machine_name" },
                                {
                                  title: "Notes",
                                  field: "notes",
                                  render: (rowData) => (
                                    <>
                                      <a onClick={() => viewnotes(rowData.id)}>
                                        {rowData.notes}
                                      </a>
                                    </>
                                  ),
                                },
                                { title: "Status", field: "status" },
                                { title: "Progress", field: "Progress" },
                              ]}
                              actions={[
                                {
                                  icon: "edit",
                                  tooltip: "Edit",
                                  onClick: (event, rowData) =>
                                    Editorder(rowData),
                                },
                                {
                                  icon: "assignment_turned_in",
                                  tooltip: "Add Notes",
                                  onClick: (event, rowData) =>
                                    Notes(rowData.id),
                                },
                                {
                                  icon: "settings_accessibility",
                                  tooltip: "Status",
                                  onClick: (event, rowData) =>
                                    Status(rowData.id, [
                                      {
                                        name: "Evaluation",
                                        value: "evaluation",
                                      },
                                      { name: "Canceled", value: "cancel" },
                                    ]),
                                },
                                (rowData) => ({
                                  icon: "delete",
                                  tooltip: "Delete",
                                  onClick: (event, rowData) =>
                                    Deleteorder(rowData.id),
                                }),
                              ]}
                              data={refferal}
                              style={{
                                backgroundColor:
                                  theme === "dark" ? "#353c48" : "#1896c8",
                              }}
                              options={{
                                // pageSize: 20,
                                // pageSizeOptions: [20, 50, 100],
                                headerStyle: {
                                  backgroundColor:
                                    theme === "dark" ? "#353c48" : "#1896c8",
                                  color: "#FFF",
                                },
                                rowStyle: {
                                  backgroundColor:
                                    theme === "dark" ? "#353c48" : "#1896c8",
                                  color: "#FFF",
                                },
                                actionsColumnIndex: -1,
                              }}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="cancelledorder1"
                      role="tabpanel"
                      aria-labelledby="cancelledorder"
                    >
                      <section>
                        <div className="card">
                          <div className="card-header row">
                            <div className="col-md-6">
                              <h5 className="mb-0"></h5>
                            </div>
                            <div className="col-md-6">
                              <button
                                className="btn btn-primary float-right"
                                onClick={handleShow}
                              >
                                <AiOutlineUserAdd className="delete-btn" />
                              </button>
                            </div>
                          </div>
                          <div className="card-body col-md-12">
                            <MaterialTable
                              title=""
                              icons={tableIcons}
                              columns={[
                                {
                                  title: "Patient Name",
                                  field: "first_name",
                                  render: (rowData) =>
                                    rowData.patient_reffrel.first_name,
                                },
                                {
                                  title: "Patient Email",
                                  field: "email",
                                  render: (rowData) =>
                                    rowData.patient_reffrel.email,
                                },
                                { title: "Description", field: "machine_name" },
                                {
                                  title: "Notes",
                                  field: "notes",
                                  render: (rowData) => (
                                    <>
                                      <a onClick={() => viewnotes(rowData.id)}>
                                        {rowData.notes}
                                      </a>
                                    </>
                                  ),
                                },
                                { title: "Status", field: "status" },
                              ]}
                              data={Pending}
                              style={{
                                backgroundColor:
                                  theme === "dark" ? "#353c48" : "#1896c8",
                              }}
                              options={{
                                // : 20,
                                // ppageSizeageSizeOptions: [20, 50, 100],
                                headerStyle: {
                                  backgroundColor:
                                    theme === "dark" ? "#353c48" : "#1896c8",
                                  color: "#FFF",
                                },
                                rowStyle: {
                                  backgroundColor:
                                    theme === "dark" ? "#353c48" : "#1896c8",
                                  color: "#FFF",
                                },
                                actionsColumnIndex: -1,
                              }}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="Evaluation1"
                      role="tabpanel"
                      aria-labelledby="Evaluation"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={Evalution}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "Evaluation Completed",
                                  value: "evaluation_completed",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="EvaluationCompleted1"
                      role="tabpanel"
                      aria-labelledby="EvaluationCompleted"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={EvalutionComplete}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "PaperWork in Process",
                                  value: "paperwork_in_process",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="PaperWork1"
                      role="tabpanel"
                      aria-labelledby="PaperWork"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={PaperWork}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "Prior Auth Status",
                                  value: "prior_auth_status",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="PaperWorkRcvd1"
                      role="tabpanel"
                      aria-labelledby="PaperWorkRcvd"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={PriorAuthStatus}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "Prior Auth Receive",
                                  value: "prior_auth_received",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="Status1"
                      role="tabpanel"
                      aria-labelledby="Status"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={PriorAuthReceive}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                { name: "Holding RTO", value: "holding_rto" },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="holdingRto"
                      role="tabpanel"
                      aria-labelledby="holdingRtos"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={HoldingRto}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                { name: "RTO", value: "rto" },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="Rto"
                      role="tabpanel"
                      aria-labelledby="Rtos"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={Rto}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                { name: "Delivered", value: "delivered" },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="delivered"
                      role="tabpanel"
                      aria-labelledby="delivered0"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={Delivered}
                        actions={[
                          {
                            icon: "edit",
                            tooltip: "Edit",
                            onClick: (event, rowData) => Editorder(rowData),
                          },
                          {
                            icon: "assignment_turned_in",
                            tooltip: "Add Notes",
                            onClick: (event, rowData) => Notes(rowData.id),
                          },
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "Authorization",
                                  value: "authorization",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="authorization"
                      role="tabpanel"
                      aria-labelledby="authorization0"
                    >
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            render: (rowData) => (
                              <>
                                <a onClick={() => viewnotes(rowData.id)}>
                                  {rowData.notes}
                                </a>
                              </>
                            ),
                          },
                          { title: "Description", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={Authorization}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="request"
                      role="tabpanel"
                      aria-labelledby="request0"
                    >
                      <MaterialTable
                        title="Order Request"
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Patient Name",
                            field: "first_name",
                            render: (rowData) =>
                              rowData.patient_reffrel.first_name,
                          },
                          {
                            title: "Patient Email",
                            field: "email",
                            render: (rowData) => rowData.patient_reffrel.email,
                          },
                          { title: "Description", field: "desc" },
                          { title: "Machnine Name", field: "machine_name" },
                          { title: "Status", field: "status" },
                        ]}
                        data={RequestData}
                        style={{
                          backgroundColor:
                            theme === "dark" ? "#353c48" : "#1896c8",
                        }}
                        actions={[
                          {
                            icon: "settings_accessibility",
                            tooltip: "Status",
                            onClick: (event, rowData) =>
                              Status(rowData.id, [
                                {
                                  name: "Evaluation",
                                  value: "evaluation",
                                },
                                { name: "Canceled", value: "cancel" },
                              ]),
                          },
                        ]}
                        options={{
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100],
                          headerStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          rowStyle: {
                            backgroundColor:
                              theme === "dark" ? "#353c48" : "#1896c8",
                            color: "#FFF",
                          },
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Refferal  store */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Order</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Dme Supplier</label>
                    <input
                      type="text"
                      value={dme_name}
                      className="form-control"
                      defaultValue=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Patient Name</label>
                    <Select
                      onChange={(e) => {
                        setpatient_id(e.value);
                        setpt(false);
                      }}
                      options={arr}
                    />
                    {pt ? (
                      <div className="alert alert-danger" role="alert">
                        Patient name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      value={machine_name}
                      onChange={(e) => {
                        setmachine_name(e.target.value);
                        setmach(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          Store();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {mach ? (
                      <div className="alert alert-danger" role="alert">
                        Discription is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      type="text"
                      value={notes}
                      onChange={(e) => {
                        setnotes(e.target.value);
                        setnot(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          Store();
                        }
                      }}
                      className="form-control"
                    />
                    {not ? (
                      <div className="alert alert-danger" role="alert">
                        Notes is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => Store()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Refferal  store */}

      {/*Refferal store Update */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>Update Order</Modal.Title>
          <i
            onClick={handleClose2}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Dme name</label>
                    <input
                      type="text"
                      value={dme_name}
                      className="form-control"
                      defaultValue=""
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Patient Name</label>
                    <Select
                      onChange={(e) => {
                        setpatient_id(e.value);
                        setpt(false);
                      }}
                      options={arr}
                    />
                    {pt ? (
                      <div className="alert alert-danger" role="alert">
                        Patient name is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      value={machine_name}
                      onChange={(e) => {
                        setmachine_name(e.target.value);
                        setmach(false);
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {mach ? (
                      <div className="alert alert-danger" role="alert">
                        Discription is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      type="text"
                      value={notes}
                      onChange={(e) => {
                        setnotes(e.target.value);
                        setnot(false);
                      }}
                      className="form-control"
                    />
                    {not ? (
                      <div className="alert alert-danger" role="alert">
                        Notes is required{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => update()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* store  Update*/}

      {/*Update Status  */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header className="">
          <Modal.Title>Update Status</Modal.Title>
          <i
            onClick={handleClose3}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <label className="text-white">Select Your Status</label>
                  <select
                    className="form-control mt-4 mb-4"
                    value={value}
                    onChange={(e) => {
                      setvalue(e.target.value);
                      setsta(false);
                    }}
                  >
                    <option>Select a Status</option>
                    {StatusArr.map((e) => (
                      <option value={e.value}>{e.name}</option>
                    ))}
                    {/* <option value={"evaluation"}>Evaluation</option> */}
                    {/* <option value={"cancel"}>Canceled</option> */}
                  </select>
                  {sta ? (
                    <div className="alert alert-danger" role="alert">
                      Please select status
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => submit()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Update Status  */}

      {/*Note  store */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header>
          <Modal.Title>Add Notes</Modal.Title>
          <i
            onClick={handleClose4}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      type="text"
                      value={reffrel_notes}
                      onChange={(e) => {
                        setreffrel_notes(e.target.value);
                        setrefnot(false);
                      }}
                      className="form-control"
                    />
                    {refnot ? (
                      <div className="alert alert-danger" role="alert">
                        Notes is Requiered
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => NoteStore()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Note  store */}

      {/*Note  View */}
      <Modal size="xl" show={show5} onHide={handleClose5}>
        <Modal.Header>
          <i
            onClick={handleClose5}
            style={{ fontSize: "24px" }}
            className="btn btn-primary float-right"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
              {
                title: "Super Admin",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.superadmin_name}</span> <br />
                    Notes : <span>{rowData?.superadmin_notes}</span> <br />
                    Date : <span>{rowData?.date}</span> <br />
                    Time : <span>{rowData?.time}</span>
                  </>
                ),
              },
              {
                title: "Doctor",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.doctor_name}</span> <br />
                    Notes : <span>{rowData?.doctor_notes}</span> <br />
                    Date : <span>{rowData?.date}</span> <br />
                    Time : <span>{rowData?.time}</span>
                  </>
                ),
              },
              {
                title: "Dme Supplier",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.dme_name}</span> <br />
                    Notes : <span>{rowData?.dme_notes}</span> <br />
                    Date : <span>{rowData?.date}</span> <br />
                    Time : <span>{rowData?.time}</span>
                  </>
                ),
              },
              {
                title: "Therapist",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.therapist_name}</span> <br />
                    Notes : <span>{rowData?.therapist_notes}</span>
                    <br />
                    Date : <span>{rowData?.date}</span>
                    <br />
                    Time : <span>{rowData?.time}</span>
                    <br />
                  </>
                ),
              },
            ]}
            data={view}
            style={{
              backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
            }}
            options={{
              // pageSize: 20,
              // pageSizeOptions: [20, 50, 100],
              headerStyle: {
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                color: "#FFF",
              },
              rowStyle: {
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
            }}
          />
        </Modal.Body>
      </Modal>
      {/*Note  View */}
      <Footer />
    </>
  );
}
