import React, { useState, useEffect, forwardRef, useContext } from 'react';
import DmeHeader from "../Component/Dmeheader";
import Footer from '../Component/Footer'
import { ThemeContext } from "../App"
import MaterialTable from 'material-table';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
export default function DmeStaff() {
    const { theme, toggleTheme } = useContext(ThemeContext)
    const { enqueueSnackbar } = useSnackbar();
    const Id = localStorage.getItem("dmeId")
    const dme_name = localStorage.getItem("dme_name")
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => {
        setShow(false)
        setemail("")
    }
    const handleClose2 = () => {
        setShow2(false)
        setemail("")
        setfirst_name("")
        setlast_name("")
    }
    const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const [email, setemail] = useState("");
    const [em, setem] = useState(false);
    const Invite = () => {
        if (email === "") {
            setem(true)
        } else {
            setLoader(true)
            handleClose()
            const Data = {
                'dme_id': Id,
                'email': email,
            }
            axios.post(`${Baseurl.baseurl}supplier/staff/invite`, Data)
                .then(result => {
                    console.log(result.data)
                    setLoader(false)
                    if (result.status == true) {
                        enqueueSnackbar(result.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                        handleClose();
                        setemail("");
                        Registered();
                    } else {
                        handleClose();
                        setemail("");
                        setLoader(false)
                        enqueueSnackbar(result.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    }
                })
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    const [inviteview, setinviteview] = useState([])
    const Invited = () => {
        axios.get(`${Baseurl.baseurl}supplier/staff/invite/view/${Id}`)
            .then((res => {
                console.log(res.data.dme[0].assign_dme)
                setinviteview(res?.data?.dme[0].assign_dme)
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });

    }
    useEffect(() => {
        Invited();
    }, [])

    const [registerview, setregisterview] = useState([])
    const Registered = () => {
        axios.get(`${Baseurl.baseurl}dmestaff/register/view/${Id}`)
            .then((res => {
                console.log(res.data.dme[0].dme_staff)
                setregisterview(res.data.dme[0].dme_staff)
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    useEffect(() => {
        Registered();
    }, [])

    const [first_name, setfirst_name] = useState([])
    const [last_name, setlast_name] = useState([])
    const [id, setid] = useState([])
    const [ln, setln] = useState(false)
    const [fn, setfn] = useState(false)
    const Edit = (e) => {
        setemail(e.email)
        setid(e.id)
        setfirst_name(e.first_name)
        setlast_name(e.last_name)
        handleShow2();
    }

    const update = () => {
        if (email === "") {
            setem(true)
        } else if (first_name === "") {
            setfn(true)
        } else if (last_name === "") {
            ln(true)
        } else {
            handleClose2();
            setLoader(true)
            const Data = {
                'email': email,
                'first_name': first_name,
                'last_name': last_name,
            }
            axios.post(`${Baseurl.baseurl}dmestaff/register/update/${id}`, Data)
                .then(result => {
                    console.log(result.data)
                    setLoader(false)
                    if (result.status == true) {
                        enqueueSnackbar(result.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                        handleClose2();
                        Registered();
                    } else {
                        handleClose2();
                        setemail("");
                        setLoader(false)
                        enqueueSnackbar(result.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    }
                })
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }

    const Delete = (id) => {
        setLoader(true)
        axios.get(`${Baseurl.baseurl}dmestaff/register/delete/${id}`)
            .then((res => {
                console.log(res.data)
                if (res.data) {
                    setLoader(false)
                    Registered();
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                } else {
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                }
            }))
            .catch(errors => {
                setLoader(false)
                enqueueSnackbar(errors.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    return (
        <>
            <DmeHeader />
            {loader ? <Loader fullPage loading /> : null}
            <div className='main-content'>
                <div className='section'>
                    <div className='card'>
                        <div className='card-body'>
                            <div>
                                <ul class="nav nav-pills" id="myTab3" role="tablist">
                                    <li class="nav-item col-md-6">
                                        <a class="nav-link" type="button" onClick={() => Invited()} id="invite" data-toggle="tab" href="#invite1" role="tab"
                                            aria-controls="invite" aria-selected="true">INVITED</a>
                                    </li>
                                    <li class="nav-item col-md-6">
                                        <a class="nav-link" type="button" onClick={() => Registered()} id="register" data-toggle="tab" href="#register1" role="tab"
                                            aria-controls="register" aria-selected="false">REGISTERED</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent2">
                                    <div class="tab-pane fade show active" id="invite1" role="tabpanel" aria-labelledby="invite">
                                        <section>
                                            <div className='card'>
                                                <div className='card-header row'>
                                                    <div className='col-md-6'>
                                                        <h5 className='mb-0'></h5>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <button className='btn btn-primary float-right' onClick={handleShow}>
                                                            <AiOutlineUserAdd className='delete-btn' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='card-body col-md-12'>
                                                    <MaterialTable
                                                        title=""
                                                        icons={tableIcons}
                                                        columns={[
                                                            { title: 'Email', field: 'email' },
                                                        ]}

                                                        data={inviteview}
                                                        style={{
                                                            backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                        }}
                                                        options={{
                                                            // pageSize: 20,
                                                            // pageSizeOptions: [20, 50, 100],
                                                            headerStyle: {
                                                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                                color: '#FFF'
                                                            },
                                                            rowStyle: {
                                                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                                color: '#FFF'
                                                            },
                                                            actionsColumnIndex: -1
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="tab-pane fade" id="register1" role="tabpanel" aria-labelledby="register">
                                        <section>
                                            <div className='card'>
                                                <div className='card-header row'>
                                                    <div className='col-md-6'>
                                                        <h5 className='mb-0'></h5>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <button className='btn btn-primary float-right' onClick={handleShow}>
                                                            <AiOutlineUserAdd className='delete-btn' />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='card-body col-md-12'>
                                                    <MaterialTable
                                                        title=""
                                                        icons={tableIcons}
                                                        columns={[
                                                            { title: 'First Name', field: 'first_name' },
                                                            { title: 'Last Name', field: 'last_name' },
                                                            { title: 'Email', field: 'email' },
                                                        ]}
                                                        actions={[
                                                            {
                                                                icon: 'edit',
                                                                tooltip: 'Edit',
                                                                onClick: (event, rowData) => Edit(rowData)
                                                            },
                                                            rowData => ({
                                                                icon: 'delete',
                                                                tooltip: 'Delete',
                                                                onClick: (event, rowData) => Delete(rowData.id)
                                                            })
                                                        ]}
                                                        data={registerview}
                                                        style={{
                                                            backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                        }}
                                                        options={{
                                                            // pageSize: 20,
                                                            // pageSizeOptions: [20, 50, 100],
                                                            headerStyle: {
                                                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                                color: '#FFF'
                                                            },
                                                            rowStyle: {
                                                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                                                color: '#FFF'
                                                            },
                                                            actionsColumnIndex: -1
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*invite*/}
            <Modal
                show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Invite</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Dme name</label>
                                        <input type="text" value={dme_name} className="form-control" defaultValue="" readOnly />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email"
                                            onChange={(e) => {
                                                setemail(e.target.value)
                                                setem(false)
                                            }}
                                            value={email} className="form-control" />
                                        {em ? <div className="alert alert-danger" role="alert">Email is required </div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => Invite()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/*invite*/}

            {/*Edit*/}
            <Modal
                show={show2} onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Invite</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: "156%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="row justify-content">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Dme name</label>
                                        <input type="text" value={dme_name} className="form-control" defaultValue="" readOnly />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email"
                                            onChange={(e) => {
                                                setemail(e.target.value)
                                                setem(false)
                                            }}
                                            value={email} className="form-control" />
                                        {em ? <div className="alert alert-danger" role="alert">Email is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text"
                                            onChange={(e) => {
                                                setfirst_name(e.target.value)
                                                setfn(false)
                                            }}
                                            value={first_name} className="form-control" />
                                        {fn ? <div className="alert alert-danger" role="alert">First Name is required </div> : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text"
                                            onChange={(e) => {
                                                setlast_name(e.target.value)
                                                setln(false)
                                            }}
                                            value={last_name} className="form-control" />
                                        {ln ? <div className="alert alert-danger" role="alert">Last Name is required </div> : null}
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type='button' className='btn btn-block btn-primary' onClick={() => update()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/*Edit*/}

            <Footer />
        </>
    )
}