import React, { forwardRef, useState, useEffect, useContext } from "react";
import DoctorHeader from "../Component/DoctorHeader";
import MaterialTable from "material-table";
import axios from "axios";
import Footer from "../Component/Footer";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";
import MADH from "../img/user.png";
import PDF from "../img/pdf.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useParams, Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
export default function PatientDocuments() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const { id } = useParams();

  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Id = localStorage.getItem("DoctorId");
  // Documents
  const [Data, setData] = useState([]);
  const ImageExtensions = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"];
  const Documents = () => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/sent/document/view/${id}`)
      .then((res) => {
        console.log(res?.data?.documents[0]?.patient_document, "document");
        setData(res?.data?.documents[0]?.patient_document);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Documents();
  }, []);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [ids, setids] = useState([]);
  const [File, setFile] = useState("");
  const [ff, setff] = useState(false);
  console.log(ids);
  const Add = () => {
    setLoader(true);
    if (File === "") {
      setff(true);
    } else {
      var formdata = new FormData();
      formdata.append("patient_id", id);
      formdata.append("doctor_id", Id);
      formdata.append("file", File);
      console.log(Data);
      axios
        .post(`${Baseurl.baseurl}doctor/document/store`, formdata)
        .then((res) => {
          console.log(res);
          if (res?.data?.status === true) {
            Documents();
            handleClose();
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <div>
      <DoctorHeader />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-body">
              <div className="row">
                {Data.length === 0 ? (
                  <div className="col-md-12">
                    <h5 className="text-white text-center">
                      No Document Available
                    </h5>
                  </div>
                ) : null}
                {Data.map((e) => (
                  <div className="col-md-4">
                    <div className="card cardhover">
                      <div className="row">
                        <div className="col-md-12">
                          <DropdownButton
                            id="dropdown-item-button"
                            className="float-right"
                            style={{ fontSize: "20px" }}
                            title={<BsThreeDotsVertical />}
                          >
                            <Dropdown.Item
                              as="button"
                              className="text-white"
                              onClick={() => {
                                handleShow();
                                setids(e.patient_id);
                              }}
                            >
                              Add
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                      <div className="card-header">
                        <div className="banner-img">
                          {ImageExtensions.includes(e.file.split(".").pop()) ? (
                            <img
                              src={e.file !== null ? e.file : MADH}
                              className="img-fluid w-100"
                            />
                          ) : (
                            <img src={PDF} className="img-fluid w-100" />
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        {ImageExtensions.includes(e.file.split(".").pop()) ? (
                          <a
                            target="_blank"
                            href={e.file}
                            className="btn btn-primary w-100"
                            download
                          >
                            Image
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            href={e.file}
                            className="btn btn-primary w-100"
                            download
                          >
                            Pdf
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*Document*/}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Document</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body style={{ maxWidth: "156%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Document</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setff(false);
                      }}
                      required
                    />
                    {ff ? (
                      <div className="alert text-danger">File is Required</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => Add()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*Document*/}

      <Footer />
    </div>
  );
}
