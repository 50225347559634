import React, { useState, useEffect, forwardRef , useContext} from 'react';
import MaterialTable from 'material-table';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Modal, Button } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import { ThemeContext } from "../App"


export default function Admin() {
  const { enqueueSnackbar} = useSnackbar();
  const { theme, toggleTheme } = useContext(ThemeContext)

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const [loader, setLoader] = useState(true)
  const [myid, Setmyid] = useState("")
  // const [myid, Setid] = useState("")
  const [first_name, Setfirst_name] = useState("")
  const [last_name, Setlast_name] = useState("")
  const [email, Setemail] = useState("")
  const [password, Setpassword] = useState("")
  const [Data, SetData] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false)


  };

  const handleShow = () => {
    setShow(true);
    Setfirst_name("")
    Setlast_name("")
    Setemail("")
    Setpassword("")

  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [data, setData] = useState([])
  // show
  function add() {
    fetch(`${Baseurl.baseurl}superadmin/staff`).then((result) => {
      result.json().then((resp) => {
        setData(resp.staff)
        setLoader(false)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    add();
  }, [])
  // show



  console.log("Data", Data)
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Pass, setPass] = useState(false);
  // add
  const AddAdmin = () => {
    if (first_name === "") {
      setFirstName(true)
    } else if (last_name === "") {
      setLastName(true)
    } else if (email === "") {
      setEmail(true)
    } else if (password === "") {
      setPass(true)
    } else {
      handleClose();
      setLoader(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Token}`);

      var formdata = new FormData();
      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", email);
      formdata.append("password", password);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(`${Baseurl.baseurl}superadmin/staff/store`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setLoader(false)
          setFirstName(false)
          setLastName(false)
          setEmail(false)
          setPass(false)
          if (result.status) {

            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
              autoHideDuration: 2000,
            });
            add();
            handleClose();

          } else {
            if (result.status === false && result.errors.email[0]) {
              enqueueSnackbar(result.errors.email[0], {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
              });
            }
            if (result.status === false && result.message) {
              enqueueSnackbar(result.message, {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
              });
            }

          }
        })
        .catch(error => {
          setLoader(false)
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  }
  const Token = localStorage.getItem("superAdmin");
  // add

  // delete
  function Deleteva(id) {
    setLoader(true)
    fetch(`${Baseurl.baseurl}superadmin/staff/delete/${id}`, {
      method: "GET"
    }).then((result) => {
      result.json().then((resp) => {
        setLoader(false)

        if (result.status) {
          enqueueSnackbar("Staff Successfully Deleted", {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
            autoHideDuration: 2000,
          });
          add();
        } else {
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
        console.warn(resp)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
    add();
  }
  // delete

  // edit
  const edit = (e) => {
    console.log("edit=============", e);
    handleShow2();
    Setfirst_name(e.first_name);
    Setlast_name(e.last_name);
    Setemail(e.email);
    Setmyid(e.id)
  };
  // edit

  // update
  const UpdateApi = () => {
    if (first_name === "") {
      setFirstName(true)
    } else if (last_name === "") {
      setLastName(true)
    } else if (email === "") {
      setEmail(true)
    } else if (password === "") {
      setPass(true)
    } else {
      handleClose2();
      setLoader(true)
      console.log("myid", myid)
      var myHeaders = new Headers();
      var formdata = new FormData();
      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", email);
      formdata.append("id", myid);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(`${Baseurl.baseurl}superadmin/staff/update/${myid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false)
          setFirstName(false)
          setLastName(false)
          setEmail(false)
          add();
          handleClose();
          if (result.status) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
              autoHideDuration: 2000,
            });
            handleClose2();
          } else {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        })
        .catch(error => {
          setLoader(false)
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  }
  // update

  return <div>
    <Header />
    {loader ? <Loader fullPage loading /> : null}
    <div className="main-content">
      <section>
        <div className='card'>
          <div className='card-header row'>
            <div className='col-md-6'>
              <h5 className='mb-0'>STAFF</h5>
            </div>
            <div className='col-md-6'>
              <Button className='btn btn-primary float-right' onClick={handleShow}>
                <AiOutlineUserAdd className='delete-btn' />
              </Button>
            </div>
          </div>
          <div className='card-body col-md-12'>
            <MaterialTable
              title=""
              icons={tableIcons}
              style={{
                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
              }}
              columns={[
                // { title: 'ID', field: 'id' },
                { title: 'First Name', field: 'first_name' },
                { title: 'Last Name', field: 'last_name' },
                { title: 'Email', field: 'email' },

              ]}

              data={data}
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit',
                  onClick: (event, rowData) => edit(rowData)
                },
                rowData => ({
                  icon: 'delete',
                  tooltip: 'Delete',
                  onClick: (event, rowData) => Deleteva(rowData.id)

                })
              ]}
              options={{
                // pageSize: 20,
                // pageSizeOptions: [20, 50, 100],
                headerStyle: {
                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                  color: '#FFF'
                },
                rowStyle: {
                  backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                  color: '#FFF'
                },
                actionsColumnIndex: -1
              }}
            />
          </div>
        </div>
      </section>
    </div>
    <Footer />
    {/* add */}
    <Modal show={show} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>Add Staff</Modal.Title>
        <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row justify-content">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" id='package' value={first_name} onChange={(e) => {
                    Setfirst_name(e.target.value)
                    setFirstName(false)
                  }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        AddAdmin()
                      }
                    }}
                    className="form-control" defaultValue="" required />
                  {FirstName ? <div class="alert alert-danger" role="alert">
                    First Name is Required!
                  </div> : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" value={last_name} onChange={(e) => {
                    Setlast_name(e.target.value)
                    setLastName(false)
                  }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        AddAdmin()
                      }
                    }}
                    className="form-control" defaultValue="" required />
                  {LastName ? <div class="alert alert-danger" role="alert">
                    Last Name is Required!
                  </div> : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" value={email} onChange={(e) => {
                    Setemail(e.target.value)
                    setEmail(false)
                  }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        AddAdmin()
                      }
                    }}
                    className="form-control" required defaultValue="abc@gmail.com" />
                  {Email ? <div class="alert alert-danger" role="alert">
                    Email is Required!
                  </div> : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" value={password}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        AddAdmin()
                      }
                    }}
                    onChange={(e) => {
                      Setpassword(e.target.value)
                      setPass(false)
                    }} className="form-control" defaultValue="aaallli" required />
                  {Pass ? <div class="alert alert-danger" role="alert">
                    Enter Maximum 8 Digit!
                  </div> : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button type='button' className='btn btn-block btn-primary' onClick={() => AddAdmin()} >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    {/* add */}

    {/* Edit  */}
    <Modal show={show2} onHide={handleClose2}>
      <Modal.Header >
        <Modal.Title>Update Staff Details</Modal.Title>
        <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" btn btn-primary"> <ImCross className='delete-btn' /></i>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row justify-content">
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" id='package' value={first_name} onChange={(e) => {
                    Setfirst_name(e.target.value)
                    setFirstName(false)
                  }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        UpdateApi()
                      }
                    }}
                    className="form-control" defaultValue="" required />
                  {FirstName ? <div class="alert alert-danger" role="alert">
                    First Name is Required!
                  </div> : null}
                </div>

              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" value={last_name}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        UpdateApi()
                      }
                    }}
                    onChange={(e) => {
                      Setlast_name(e.target.value)
                      setLastName(false)
                    }} className="form-control" defaultValue="" required />
                  {LastName ? <div class="alert alert-danger" role="alert">
                    Last Name is Required!
                  </div> : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" value={email}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        UpdateApi()
                      }
                    }}
                    onChange={(e) => {
                      Setemail(e.target.value)
                      setEmail(false)
                    }} className="form-control" required defaultValue="abc@gmail.com" />
                  {Email ? <div class="alert alert-danger" role="alert">
                    Email is Required!
                  </div> : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button type='button' className='btn btn-block btn-primary' onClick={() => UpdateApi()} >UPDATE</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    {/* Edit  */}

  </div>;
}