import React, { useContext} from "react";
import $ from "jquery";
import { ThemeContext } from "../App";

export default function Footer() {
  

  const { theme, toggleTheme } = useContext(ThemeContext);
  const Setting = (value) => {
    localStorage.setItem("SettingValue", value);
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };
  const settings = () => {
    document.getElementById("settings").classList.add("showSettingPanel");
  };
  return (
    <div>
      <div className="m-footer">

        <footer className="main-footer fixed-bottom">
          <div className="text-center text-white">
            Copyright © 2022 MADH
            <div className="bullet" /> Design By
            <a
              href="https://hnhtechsolutions.com/"
              target="_blank"
              className="text-white"
            >
              {" "}
              Hnh Tech Solutions
            </a>
          </div>
          <div className="footer-right"></div>
        </footer>
        {/* setting */}
        <div id="settings" className="settingSidebar">
          <a className="settingPanelToggle" onClick={() => settings()}>
            {" "}
            <i className="fa fa-spin fa-cog" />
          </a>
          <div className="settingSidebar-body ps-container ps-theme-default">
            <div className=" fade show active">
              <div className="setting-panel-header">Setting Panel</div>
              <div className="p-15 border-bottom">
                <div className="selectgroup layout-color">
                  <div className="selectgroup-item">
                    <button
                      onClick={(event) => {
                        toggleTheme("light");
                      }}
                      className="btn btn-outline-light mr-3"
                    >
                      Light Panel
                    </button>
                  </div>
                  <div className="selectgroup-item">
                    <button
                      onClick={(event) => {
                        toggleTheme("dark");
                      }}
                      className="btn btn-outline-dark float-right"
                    >
                      Dark Panel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* setting */}
      </div>
    </div>
  );
}
