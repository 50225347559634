import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from "sweetalert2";
import backgroundimg from '../img/background/back1.jpg'
import { Loader } from 'react-overlay-loader';
import { useNavigate } from "react-router-dom";
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';

export default function DmeForget() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // forget api post!!!
    const [loader, setLoader] = useState(false)
    const [email, setemail] = useState("");
    const Navigate = useNavigate();
    const Submit = () => {
        if (email === "") {
            enqueueSnackbar("Email Is Required", {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
            });
        } else {

            const formData = new FormData();
            formData.append("email", email);
            setLoader(true)
            axios.post(`${Baseurl.baseurl}supplier/forget`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then((res) => {
                    console.log("res", res.data);
                    if (res.data.status) {
                        Navigate("/Reset");
                        setLoader(false)
                        enqueueSnackbar(res.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else {
                        setLoader(false)
                        enqueueSnackbar(res.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                });
        }

    };
    return (
        <div>
            {loader ? <Loader fullPage loading /> : null}
            <div id="app" className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
                <section className="section mt-4">
                    <div className="container mt-5">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary mt-2">
                                    <div className="card-header">
                                        <h3 className="text-center">FORGET PASSWORD</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="email" className="text-white">EMAIL</label>
                                            <input
                                                value={email}
                                                onChange={(e) => setemail(e.target.value)}
                                                type="email"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={Submit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            <Link to="/DmeLogin" className="text-white">DME SUPPLIER LOGIN</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Design By <Link to="https://hnhtechsolutions.com" className="text-white">Hnh Tech Solutions</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
