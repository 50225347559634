import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Superadmin/Home";
import Dashboard from "./Superadmin/Dashboard";
import Aboutus from "./Superadmin/Aboutus";
import Register from "./Dme/Register";
import Package from "./Dme/Package";
import Profile from "./Superadmin/Profile";
import Dme from "./Superadmin/Dme";
import StaffDme from "./Staff/Staffdme";
import Privacy from "./Superadmin/Privacy";
import Permission from "./Superadmin/Permission";
import Staff from "./Superadmin/Staff";
import StaffLogin from "./Staff/StaffLogin";
import StaffDashboard from "./Staff/StaffDashboard";
import SuperAdminLogin from "./Superadmin/SuperAdmin";
import DmeLogin from "./Dme/DmeLogin";
import DmeDashboard from "./Dme/DmeDashboard";
import DmeForget from "./Dme/DmeForget";
import Reset from "./Dme/Reset";
import Madh from "./Superadmin/Madh";
import Doctor from "./Superadmin/Doctor";
import Therapist from "./Superadmin/Therapist";
import SuperadminPatient from "./Superadmin/SuperadminPatient";
import StaffRegister from "./Staff/StaffRegister";
import Therapistlogin from "./Therapist/Therapistlogin";
import DoctorReg from "./Doctor/DoctorReg";
import Doctorlogin from "./Doctor/Doctorlogin";
import TherapistReg from "./Therapist/TherapistReg";
import DoctorDashboard from "./Doctor/DoctorDashboard";
import { StaffAssign } from "./Staff/StaffAssign";
import TharepistDashboard from "./Therapist/TrarepistDashboard";
import DmeDoctor from "./Dme/DmeDoctor";
import InviteDoctor from "./Dme/InviteDoctor";
import DmeTherapist from "./Dme/DmeTherapist";
import InviteTherapist from "./Dme/InvitedTherapist";
import DmeSupplierPatient from "./Dme/DmeSupplierPatient";
import Doctorpackage from "./Doctor/Doctorpackages";
import Therapistpackage from "./Therapist/Therapistpackage";
import { SnackbarProvider } from "notistack";
import DoctorPatient from "../src/Doctor/DoctorPatient";
import TherapistPatient from "../src/Therapist/TherapistPatient";
import StaffDoctor from "../src/Staff/StaffDoctors";
import StaffTherapist from "../src/Staff/StaffTherapist";
import TherapistUpdate from "../src/Therapist/Premiumupdate";
import DmesupplierUpdate from "../src/Dme/DmesupplierUpdate";
import DoctorUpdate from "../src/Doctor/DoctorUpdate";
// staff
import StaffPatient from "./Staff/patients";
import DmeOrders from "./Dme/DmeOrders";
import DoctorOrder from "./Doctor/DoctorOrder";
import TherapistOrder from "./Therapist/TherapistOrder";
import StaffProfile from "./Staff/Staffprofile";
import DoctorProfile from "./Doctor/Profile";
import Therapistprofile from "./Therapist/Therapistprofile";
import DmeStaff from "./Dme/DmeStaff";
import DoctorStaff from "./Doctor/DoctorStaff";
import Error404 from "./Error/Error-404";
import Dmestafflogin from "./Dme-staff/Login";
import Dmestaffregister from "./Dme-staff/Register";
import DmeStaffDashboard from "./Dme-staff/Dashboard";
import DoctorstaffLogin from "./Doctor-staff/Login";
import DoctorstaffRegister from "./Doctor-staff/Register";
import DoctorStaffDashboard from "./Doctor-staff/DoctorStaffDashboard";
import DmeStaffPatient from "./Dme-staff/Patient";
import DmeStaffDoctor from "./Dme-staff/Doctor";
import DmeStaffTherapist from "./Dme-staff/Therapist";
import DmeStaffOrders from "./Dme-staff/Order";
import DoctorStaffPatient from "./Doctor-staff/Patient";
import DoctorStaffOrder from "./Doctor-staff/Order";
import DoctorForget from "./Doctor/Forget";
import TherapistForget from "./Therapist/Forget";
import Dmeprofile from "./Dme/Profile";
// import DoctorProfile from "./Doctor/Doctorprofile";
import Patientsdoctor from "./Doctor/Patients";
import Patient from "./Dme/Patients";
import DmeStaffPatients from "./Dme-staff/Dme-Staff-Patient";
import DmeChangePassword from "./Dme/Changepassword";
import DmeStaffChangePassword from "./Dme-staff/Changepassword";
import DoctorChangePassword from "./Doctor/Changepassword";
import TherapistChangePassword from "./Therapist/Changepassword";
import DoctorStaffChangePassword from "./Doctor-staff/Changepassword";
import Document from "./Dme/Document";
import StaffDocument from "./Dme-staff/StaffDocument";
import PatientDocuments from "./Doctor/PatientDocuments";
import DoctorPatientDocuments from "./Doctor-staff/Document";
import SuperAdminNotes from "./Superadmin/Notes/table";
import NotesView from "./Superadmin/Notes/NotesView";
import DmeNotes from "./Dme/Notes";
import DoctorNotes from './Doctor/Notes'
import DoctorStaffNotes from './Doctor-staff/Notes'
import DmeStaffNotes from "./Dme-staff/Notes";
import TherapistNotes from "./Therapist/Notes";
export const ThemeContext = React.createContext({
  theme: "light",
  toggleTheme: () => {},
});

export default function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    setTheme(localStorage.getItem("SettingValue"));
  }, [localStorage.getItem("SettingValue")]);

  const toggleTheme = (value) => {
    localStorage.setItem("SettingValue", value);
    setTheme(value);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Routes>
              <Route exact path="/Madh" element={<SuperAdminLogin />}></Route>
              <Route
                exact
                path="/TharepistDashboard"
                element={<TharepistDashboard />}
              ></Route>
              <Route
                exact
                path="/DoctorDashboard"
                element={<DoctorDashboard />}
              ></Route>
              <Route
                exact
                path="/StaffAssign"
                element={<StaffAssign />}
              ></Route>
              <Route
                exact
                path="/DmeDashboard"
                element={<DmeDashboard />}
              ></Route>
              <Route
                exact
                path="/StaffDashboard"
                element={<StaffDashboard />}
              ></Route>
              <Route exact path="/Staff" element={<Staff />}></Route>
              <Route exact path="/Permission" element={<Permission />}></Route>
              <Route exact path="/Privacy" element={<Privacy />}></Route>
              <Route exact path="/Dme" element={<Dme />}></Route>
              <Route exact path="/StaffDme" element={<StaffDme />}></Route>
              <Route exact path="/Home" element={<Home />}></Route>
              <Route exact path="/DmeRegister" element={<Register />}></Route>
              <Route exact path="/DmePackage" element={<Package />}></Route>
              <Route exact path="/Dashboard" element={<Dashboard />}></Route>
              <Route exact path="/Aboutus" element={<Aboutus />}></Route>
              <Route exact path="/Profile" element={<Profile />}></Route>
              <Route exact path="/StaffLogin" element={<StaffLogin />}></Route>
              <Route exact path="/DmeLogin" element={<DmeLogin />}></Route>
              <Route exact path="/Forget" element={<DmeForget />}></Route>
              <Route exact path="/Reset" element={<Reset />}></Route>
              <Route exact path="/" element={<Madh />}></Route>
              <Route exact path="/Doctor" element={<Doctor />}></Route>
              <Route exact path="/Therapist" element={<Therapist />}></Route>
              <Route
                exact
                path="/SuperAdminPatient"
                element={<SuperadminPatient />}
              ></Route>
              <Route
                exact
                path="/staffregister"
                element={<StaffRegister />}
              ></Route>
              <Route
                exact
                path="/TherapistReg"
                element={<TherapistReg />}
              ></Route>
              <Route
                exact
                path="/Doctorlogin"
                element={<Doctorlogin />}
              ></Route>
              <Route exact path="/DoctorReg" element={<DoctorReg />}></Route>
              <Route
                exact
                path="/Therapistlogin"
                element={<Therapistlogin />}
              ></Route>
              <Route exact path="/DmeDoctor" element={<DmeDoctor />}></Route>
              <Route
                exact
                path="/DmeTherapist"
                element={<DmeTherapist />}
              ></Route>
              <Route
                exact
                path="/InviteDoctor"
                element={<InviteDoctor />}
              ></Route>
              <Route
                exact
                path="/InviteTherapist"
                element={<InviteTherapist />}
              ></Route>
              <Route
                exact
                path="/DmeSupplierPatient"
                element={<DmeSupplierPatient />}
              ></Route>
              <Route
                exact
                path="/staffPatient"
                element={<StaffPatient />}
              ></Route>
              <Route
                exact
                path="/Doctorpackage"
                element={<Doctorpackage />}
              ></Route>
              <Route
                exact
                path="/Therapistpackage"
                element={<Therapistpackage />}
              ></Route>
              <Route
                exact
                path="/DoctorPatient"
                element={<DoctorPatient />}
              ></Route>
              <Route
                exact
                path="/TherapistPatient"
                element={<TherapistPatient />}
              ></Route>
              <Route
                exact
                path="/StaffDoctor"
                element={<StaffDoctor />}
              ></Route>
              <Route
                exact
                path="/StaffTherapist"
                element={<StaffTherapist />}
              ></Route>
              <Route
                exact
                path="/TherapistUpdate"
                element={<TherapistUpdate />}
              ></Route>
              <Route
                exact
                path="/DmesupplierUpdate"
                element={<DmesupplierUpdate />}
              ></Route>
              <Route
                exact
                path="/DoctorUpdate"
                element={<DoctorUpdate />}
              ></Route>
              <Route exact path="/Dme/Orders" element={<DmeOrders />}></Route>
              <Route
                exact
                path="/Doctor/Orders"
                element={<DoctorOrder />}
              ></Route>
              <Route
                exact
                path="/Therapist/Order"
                element={<TherapistOrder />}
              ></Route>
              <Route
                exact
                path="/StaffProfile"
                element={<StaffProfile />}
              ></Route>
              <Route
                exact
                path="/DoctorProfile"
                element={<DoctorProfile />}
              ></Route>
              <Route
                exact
                path="/Therapistprofile"
                element={<Therapistprofile />}
              ></Route>
              <Route exact path="/DmeStaff" element={<DmeStaff />}></Route>
              <Route
                exact
                path="/DoctorStaff"
                element={<DoctorStaff />}
              ></Route>
              <Route
                exact
                path="/Dme/staff/Login"
                element={<Dmestafflogin />}
              ></Route>
              <Route
                exact
                path="/Dme/staff/Register"
                element={<Dmestaffregister />}
              ></Route>
              <Route
                exact
                path="/DmeStaffDashboard"
                element={<DmeStaffDashboard />}
              ></Route>
              <Route
                exact
                path="/Doctor/staff/Register"
                element={<DoctorstaffRegister />}
              ></Route>
              <Route
                exact
                path="/Doctor/staff/Login"
                element={<DoctorstaffLogin />}
              ></Route>
              <Route
                exact
                path="/DoctorStaffDashboard"
                element={<DoctorStaffDashboard />}
              ></Route>
              <Route
                exact
                path="/DmeStaffPatient"
                element={<DmeStaffPatient />}
              ></Route>
              <Route
                exact
                path="/DmeStaffDoctor"
                element={<DmeStaffDoctor />}
              ></Route>
              <Route
                exact
                path="/DmeStaffTherapist"
                element={<DmeStaffDoctor />}
              ></Route>
              <Route
                exact
                path="/DmeStaffOrders"
                element={<DmeStaffOrders />}
              ></Route>
              <Route
                exact
                path="/DoctorStaffPatient"
                element={<DoctorStaffPatient />}
              ></Route>
              <Route
                exact
                path="/DoctorStaffOrder"
                element={<DoctorStaffOrder />}
              ></Route>
              <Route
                exact
                path="/doctor/forget"
                element={<DoctorForget />}
              ></Route>
              <Route
                exact
                path="/therapist/forget"
                element={<TherapistForget />}
              ></Route>
              <Route exact path="/dme/profile" element={<Dmeprofile />}></Route>
              <Route exact path="*" element={<Error404 />}></Route>
              <Route exact path="/dme/patient" element={<Patient />}></Route>
              <Route
                exact
                path="/doctor/patient"
                element={<Patientsdoctor />}
              ></Route>
              <Route
                exact
                path="/DmeStaffPatients"
                element={<DmeStaffPatients />}
              ></Route>
              <Route
                exact
                path="/dme/password/change"
                element={<DmeChangePassword />}
              ></Route>
              <Route
                exact
                path="/dmestaff/change/password"
                element={<DmeStaffChangePassword />}
              ></Route>
              <Route
                exact
                path="/doctor/change/password"
                element={<DoctorChangePassword />}
              ></Route>
              <Route
                exact
                path="/therapist/change/password"
                element={<TherapistChangePassword />}
              ></Route>
              <Route
                exact
                path="/doctorstaff/change/password"
                element={<DoctorStaffChangePassword />}
              ></Route>
              <Route
                exact
                path="/dme/document/:id"
                element={<Document />}
              ></Route>
              <Route
                exact
                path="/dmestaff/document/:id"
                element={<StaffDocument />}
              ></Route>
              <Route
                exact
                path="/doctorstaff/document/:id"
                element={<DoctorPatientDocuments />}
              ></Route>
              <Route
                exact
                path="/doctor/patient/documents/:id"
                element={<PatientDocuments />}
              ></Route>

              {/*Superadmin note  */}
              <Route
                exact
                path="/superadmin/referal"
                element={<SuperAdminNotes />}
              ></Route>
              <Route
                exact
                path="/superadmin/note/view/:id"
                element={<NotesView />}
              ></Route>

              {/* dme note */}
              <Route exact path="/Dme/notes/:id" element={<DmeNotes />}></Route>
              <Route exact path="/Dmestaff/notes/:id" element={<DmeStaffNotes />}></Route>

               {/* doctor note */}
               <Route exact path="/doctor/notes/:id" element={<DoctorNotes />}></Route>
               <Route exact path="/doctorstaff/notes/:id" element={<DoctorStaffNotes />}></Route>


               <Route exact path="/therapist/notes/:id" element={<TherapistNotes />}></Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </div>
    </ThemeContext.Provider>
  );
}
