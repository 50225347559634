import React, { useEffect, useState } from "react";
import img2 from "../img/user.png";
import {
  MdPrivacyTip,
  MdManageAccounts,
  MdOutlineHealthAndSafety,
  MdOutlineDashboard,
} from "react-icons/md";
import { RiInformationFill } from "react-icons/ri";
import { FaStethoscope, FaUserNurse } from "react-icons/fa";
import { SiHandshake } from "react-icons/si";
import { GiSkeletalHand } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
export default function Header() {
  const location = useLocation();
  const { pathname } = location;
  const Token = localStorage.getItem("superAdmin");
  const Name = localStorage.getItem("Name");
  const Navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("superAdmin") == null) {
      localStorage.clear();
      Navigate("/");
    }
  }, []);

  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("SettingValue");
    setThemeColor(data);
    console.log("App.Js", data);
  }, [localStorage.getItem("SettingValue")]);
  useEffect(() => {
    Setting(themeColor);
  }, [themeColor]);
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };

  const logout = () => {
    Navigate("/");
    localStorage.clear();
    console.log("logout");
  };

  let removeClass = () => {
    var x = document.getElementsByTagName("BODY")[0].classList;
    if (x.contains("sidebar-gone")) {
      x.remove("sidebar-gone");
      x.add("sidebar-show");
    } else {
      x.add("sidebar-gone");
      x.remove("sidebar-show");
    }
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a href="#" className="nav-link nav-link-lg collapse-btn">
                {" "}
                <i
                  onClick={() => removeClass()}
                  className="fa fa-bars d-lg-none d-xl-none"
                  style={{ color: "" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <h4 className="text-white">SUPER ADMIN</h4>
          </ul>
        </div>

        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to=""
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {/* Super Admin */}{" "}
              <img
                alt="image"
                src={img2}
                width="100%"
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Super Admin</div>
              <Link to="/Profile" className="dropdown-item has-icon text-white">
                {" "}
                <i className="fas fa-user" />
                Profile
              </Link>
              <Link
                to="/"
                className="dropdown-item has-icon text-white"
                onClick={logout}
              >
                {" "}
                <i className="fas fa-sign-out-alt" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <side id="sidebar-wrapper">
          <div
            className="btn btn-primary float-right mt-3  d-lg-none d-xl-none"
            onClick={() => removeClass()}
          >
            <i className="fa fa-times text-white"></i>
          </div>
          <div className="sidebar-brand">
            <Link to="/Dashboard">
              {" "}
              <span className="logo-name">{Name}</span>
            </Link>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={img2} />
            </div>
          </div>
          <ul className="sidebar-menu">
            <li className={pathname == "/Dashboard" ? "active" : ""}>
              <Link
                to="/Dashboard"
                // onClick={() => removeClass()}
                className=" nav-link"
              >
                <i>
                  <MdOutlineDashboard />
                </i>
                <span>DASHBOARD</span>
              </Link>
            </li>
            <li className={pathname == "/Staff" ? "active" : ""}>
              <Link
                to="/Staff"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <FaUserNurse />
                </i>
                <span>VA(STAFF)</span>
              </Link>
            </li>

            <li className={pathname == "/Dme" ? "active" : ""}>
              <Link
                to="/Dme"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <SiHandshake />
                </i>
                <span>DME SUPPLIER</span>
              </Link>
            </li>
            <li className={pathname == "/Doctor" ? "active" : ""}>
              <Link
                to="/Doctor"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <FaStethoscope />
                </i>
                <span>DOCTOR</span>
              </Link>
            </li>
            <li className={pathname == "/Therapist" ? "active" : ""}>
              <Link
                to="/Therapist"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <GiSkeletalHand />
                </i>
                <span>THERAPIST</span>
              </Link>
            </li>
            <li className={pathname == "/SuperAdminPatient" ? "active" : ""}>
              <Link
                to="/SuperAdminPatient"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>PATIENT</span>
              </Link>
            </li>
            <li className={pathname == "/superadmin/referal" ? "active" : ""}>
              <Link to="/superadmin/referal" className=" nav-link  ">
                <i>
                  <CgNotes />
                </i>
                <span>NOTES</span>
              </Link>
            </li>
            <li className={pathname == "/Permission" ? "active" : ""}>
              <Link
                to="/Permission"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdManageAccounts />
                </i>
                <span>PERMISSION</span>
              </Link>
            </li>
            {/* <li className={pathname == "/Profile"? "active":""}>
              <Link to="/Profile" className=" nav-link  ">
                <i data-feather="monitor" className="fa fa-user" />
                <span>Profile</span>
              </Link>
            </li> */}
            <li className={pathname == "/Aboutus" ? "active" : ""}>
              <Link
                to="/Aboutus"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <RiInformationFill />
                </i>
                <span>ABOUT US</span>
              </Link>
            </li>
            <li className={pathname == "/Privacy" ? "active" : ""}>
              <Link
                to="/Privacy"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdPrivacyTip />
                </i>
                <span>PRIVACY POLICY</span>
              </Link>
            </li>
          </ul>
        </side>
      </div>
    </div>
  );
}
