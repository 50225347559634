import React, { useContext } from 'react';
import DmeStaffheader from '../Component/DmeStaffheader';
import Footer from '../Component/Footer';
import img1 from '../img/banner/1.png'
import img2 from '../img/banner/2.png'
import img3 from '../img/banner/3.png'
import img4 from '../img/banner/4.png'
import img5 from '../img/banner/5.png'
// import { Link } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import { ThemeContext } from "../App"
import Task from './Dme-staff-task'
export default function Dashboard() {
    // const { enqueueSnackbar } = useSnackbar();
    const { theme, toggleTheme } = useContext(ThemeContext)
    return <div>
        <DmeStaffheader />
        {/* {loader ? <Loader fullPage loading /> : null} */}
        <div classname="container">
            <div className="main-content">
                <section className="section">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card cardhover">
                                <div className="card-statistic-5">
                                    <div className="info-box7-block">
                                        {/* <Link to="/Dme" > */}
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="banner-img">
                                                    <img src={img1} width="100%" alt="DME SUPPLIER" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                <h6 className="m-b-20 text-right">DME SUPPLIER</h6>
                                                <h4 className="text-right"><span></span></h4>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card cardhover">
                                <div className="card-statistic-5">
                                    <div className="info-box7-block">
                                        {/* <Link to="/Staff" > */}
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="banner-img">
                                                    <img src={img2} width="100%" alt="STAFF"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                <h6 className="m-b-20 text-right">STAFF</h6>
                                                <h4 className="text-right"><span></span>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card cardhover">
                                <div className="card-statistic-5">
                                    <div className="info-box7-block">
                                        {/* <Link to="/SuperAdminPatient"> */}
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="banner-img">
                                                    <img src={img3} width="100%" alt="PATIENT" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                <h6 className="m-b-20 text-right">PATIENT</h6>
                                                <h4 className="text-right"><span></span>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-md-2 col-md-4">
                            <div className="card cardhover">
                                <div className="card-statistic-5">
                                    <div className="info-box7-block">
                                        {/* <Link to="/Doctor"> */}
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="banner-img">
                                                    <img src={img4} width="100%" alt="DOCTORS" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                <h6 className="m-b-20 text-right">DOCTORS</h6>
                                                <h4 className="text-right"><span></span>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card cardhover">
                                <div className="card-statistic-5">
                                    <div className="info-box7-block">
                                        {/* <Link to="/Therapist"> */}
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <div className="banner-img">
                                                    <img src={img5} width="100%" alt="THERAPIST" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                <h6 className="m-b-20 text-right">THERAPIST</h6>
                                                <h4 className="text-right"><span></span>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Task />
        <Footer />
    </div>;
}
