import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import backgroundimg from "../img/background/back1.jpg";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
export default function TherapistForget() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // forget api post!!!
  const [loader, setLoader] = useState(false);
  const [For, setFor] = useState(true);
  const [OPT, setOPT] = useState(false);
  const [very, setvery] = useState(false);
  const [email, setemail] = useState("");
  const Navigate = useNavigate();
  const Submit = () => {
    if (email === "") {
      enqueueSnackbar("Email Is Required", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
        autoHideDuration: 2000,
      });
    } else {
      const formData = new FormData();
      formData.append("email", email);
      setLoader(true);
      axios
        .post(`${Baseurl.baseurl}therapist/forget`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data);
          if (res.data.status) {
            setFor(false);
            setOPT(true);
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        });
    }
  };
  const [token, settoken] = useState("");
  const [code, setcode] = useState(false);
  const [to, setto] = useState([]);

  const Forget = () => {
    if (token === "") {
      setcode(true);
    } else {
      const formData = new FormData();
      formData.append("token", token);
      setLoader(true);
      axios
        .post(`${Baseurl.baseurl}therapist/otp`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data.token);
          setto(res.data.token);
          if (res?.data?.status === true) {
            setLoader(false);
            setOPT(false);
            setvery(true);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        });
    }
  };
  const [pass, setpass] = useState(false);
  const [pss, setpss] = useState(false);
  const [password, setpassword] = useState("");
  const [password_confirmation, setpassword_confirmation] = useState("");
  const Verify = () => {
    if (password === "") {
      setpass(true);
    } else if (password_confirmation === "") {
      setpss(true);
    } else {
      const formData = new FormData();
      formData.append("token", to);
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);
      setLoader(true);
      axios
        .post(`${Baseurl.baseurl}therapist/otp`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data.status);
          if (res?.data?.status === true) {
            setLoader(false);
            Navigate("/Therapistlogin");
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        });
    }
  };
  return (
    <>
      <div>
        {loader ? <Loader fullPage loading /> : null}
        <div
          id="app"
          className="page-head-bg"
          style={{ backgroundImage: `url(${backgroundimg})` }}
        >
          <section className="section mt-4">
            <div className="container mt-5">
              <div className="row mt-4">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                  {For ? (
                    <div className="card card-primary mt-2">
                      <div className="card-header">
                        <h3 className="text-center">FORGET PASSWORD</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="email" className="text-white">
                            EMAIL
                          </label>
                          <input
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            type="email"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-lg btn-block"
                            onClick={Submit}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="mt-2 text-white text-center">
                          <Link to="/Therapistlogin" className="text-white">
                            THERAPIST LOGIN
                          </Link>
                        </div>
                        <div className="mt-2 text-white text-center">
                          Design By{" "}
                          <Link
                            to="https://hnhtechsolutions.com"
                            className="text-white"
                          >
                            Hnh Tech Solutions
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {OPT ? (
                    <div className="card card-primary mt-2">
                      <div className="card-header">
                        <h3 className="text-center">THERAPIST VERIFY</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label className="text-white">Code</label>
                            <input
                              value={token}
                              onChange={(e) => {
                                settoken(e.target.value);
                                setcode(false);
                              }}
                              type="number"
                              className="form-control"
                            />
                            {code ? (
                              <div className="alert alert-danger" role="alert">
                                Code is required{" "}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-lg btn-block"
                            onClick={() => {
                              Forget();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {very ? (
                    <div className="card card-primary mt-2">
                      <div className="card-header">
                        <h3 className="text-center">THERAPIST RESET</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label className="text-white">password</label>
                            <input
                              value={password}
                              onChange={(e) => {
                                setpassword(e.target.value);
                                setpass(false);
                              }}
                              type="password"
                              className="form-control"
                            />
                            {pass ? (
                              <div className="alert alert-danger" role="alert">
                                Password is required{" "}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-md-12">
                            <label className="text-white">
                              Password Confirmation
                            </label>
                            <input
                              value={password_confirmation}
                              onChange={(e) => {
                                setpassword_confirmation(e.target.value);
                                setpss(false);
                              }}
                              type="password"
                              className="form-control"
                            />
                            {pss ? (
                              <div className="alert alert-danger" role="alert">
                                Confirm Password is required{" "}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-lg btn-block"
                            onClick={() => {
                              Verify();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
