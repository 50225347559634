import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backgroundimg from '../img/background/back.jpg'
import { useNavigate } from "react-router-dom";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl';
import { useSnackbar } from 'notistack';
export default function DmeLogin() {
    // Dme Supplier Login
    const [Error, setError] = useState(false);
    const [pass, setpass] = useState(false);
    const [em, setem] = useState(false);
    const [Email, SetEmail] = useState("");
    const [Password, SetPassword] = useState("");
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const Navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("user-info")
        ) {
        }
    }, []);
    const Login = () => {
        if (Email === "" || Password === "") {
            enqueueSnackbar("Credentials Is Required", {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
            });
        } else {
            setLoader(true)
            var formdata = new FormData();
            formdata.append("email", Email);
            formdata.append("password", Password);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${Baseurl.baseurl}supplier/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    console.log(result);
                    if (result.status) {
                        console.log("dmeLogin", result)
                        localStorage.setItem('dmeLogin', (result?.access));
                        localStorage.setItem('dmeId', (result?.dme_id));
                        localStorage.setItem('package', (result?.dme?.name));
                        localStorage.setItem('dme_name', (result?.dme_name));
                        localStorage.setItem("SettingValue", "light");
                        localStorage.setItem('DmeProfile', JSON.stringify(result?.user));

                        // Navigate("/DmeDashboard")
                        Navigate("/DmeDashboard", { state: { id: result?.dme_id } });
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else if (result.message) {
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                    else if (result.errors.email) {
                        enqueueSnackbar(result.errors.email, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    // Dme Supplier Login
    return (
        <div>
            <div id="app" className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }}>
                {loader ? <Loader fullPage loading /> : null}
                <section className="section mt-4">
                    <div className="container mt-5">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary mt-2">
                                    <div className="card-header">
                                        <h3 className="text-center">DME SUPPLIER</h3>
                                    </div>
                                    {Error ? <div class="alert alert-danger" role="alert"> Please Wait For Admin Approved!   </div> : null}
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="email"
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                className="text-white">EMAIL</label>
                                            <input
                                                id="email"
                                                value={Email}
                                                onChange={(e) => SetEmail(e.target.value)}
                                                type="email"
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                className="form-control"
                                                name="email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label text-white">
                                                    PASSWORD
                                                </label>
                                                <div className="float-right">
                                                    <Link
                                                        to="/Forget"
                                                        className="text-white"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                </div>
                                            </div>
                                            <input
                                                type="password"
                                                value={Password}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        Login()
                                                    }
                                                }}
                                                onChange={(e) => SetPassword(e.target.value)}
                                                className="form-control"
                                                name="password"
                                                tabIndex={2}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary btn-lg btn-block"
                                                onClick={Login}
                                                tabIndex={4}
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            <Link to="/" className="text-white">Dashboard</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Don't have an account? <Link to="/DmeRegister" className="text-white" >Create One</Link>
                                        </div>
                                        <div className="mt-2 text-white text-center">
                                            Design By<a href="https://hnhtechsolutions.com/" target="_blank" className="text-white">  Hnh Tech Solutions</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <Footer /> */}
        </div>
    );
}
