import React, { useState, useEffect, useContext } from "react";
import Header from "../Component/DmeStaffheader";
import Footer from "../Component/Footer";
import { Loader } from "react-overlay-loader";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import MADH from "../img/user.png";
import PDF from "../img/pdf.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
export default function Document() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [Doctor, setDoctor] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // Documents
  const [Data, setData] = useState([]);
  const ImageExtensions = ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"];
  const Documents = () => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/document/view/${id}`)
      .then((res) => {
        console.log(res?.data?.documents, "document");
        setData(res?.data?.documents);
        console.log(res?.data?.documents[0]?.doctor_id, "doctor id");
        setDoctor(res?.data?.documents[0]?.doctor_id);

        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Documents();
  }, []);

  const Delete = (e) => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}supplier/document/delete/${e}`)
      .then((res) => {
        console.log(res);
        if (res?.data?.status === true) {
          Documents();
          setLoader(false);
          enqueueSnackbar(res?.data?.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res?.data?.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  const Send = (id) => {
    setLoader(true);
    const Data = {
      doctor_id: Doctor,
    };
    axios
      .post(`${Baseurl.baseurl}supplier/sent/document/${id}`, Data)
      .then((res) => {
        if (res.data.status === true) {
          console.log(res);
          setLoader(false);
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setLoader(false);
          enqueueSnackbar(res.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  return (
    <>
      <div>
        <Header />
        {loader ? <Loader fullPage loading /> : null}
        <div className="main-content">
          <section>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {Data.length === 0 ? (
                    <div className="col-md-12">
                      <h5 className="text-white text-center">
                        No Document Available
                      </h5>
                    </div>
                  ) : null}
                  {Data.map((e) => (
                    <div className="col-md-4">
                      <div className="card cardhover">
                        <div className="row">
                          <div className="col-md-12">
                            <DropdownButton
                              id="dropdown-item-button"
                              className="float-right"
                              style={{ fontSize: "20px" }}
                              title={<BsThreeDotsVertical />}
                            >
                              <Dropdown.Item
                                as="button"
                                className="text-white"
                                onClick={() => Delete(e.id)}
                              >
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                className="text-white"
                                onClick={() => Send(e.id)}
                              >
                                Send To Doctor
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                        <div className="card-header">
                          <div className="banner-img">
                            {ImageExtensions.includes(
                              e.file.split(".").pop()
                            ) ? (
                              <img
                                src={e.file !== null ? e.file : MADH}
                                className="img-fluid w-100"
                              />
                            ) : (
                              <img src={PDF} className="img-fluid w-100" />
                            )}
                          </div>
                        </div>
                        <div className="card-body">
                          {ImageExtensions.includes(e.file.split(".").pop()) ? (
                            <a
                              target="_blank"
                              href={e.file}
                              className="btn btn-primary w-100"
                              download
                            >
                              Image
                            </a>
                          ) : (
                            <a
                              target="_blank"
                              href={e.file}
                              className="btn btn-primary w-100"
                              download
                            >
                              Pdf
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}
