import img7 from "../img/users/staff.png";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, React, useState } from "react";
import { FaStethoscope, FaFirstOrderAlt, FaUserNurse } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { MdOutlineDashboard, MdOutlineHealthAndSafety } from "react-icons/md";
import { GiSkeletalHand } from "react-icons/gi";
import $ from "jquery";
import axios from "axios";
import Baseurl from "./Baseurl";
import { useSnackbar } from "notistack";
import { CgNotes } from "react-icons/cg";

export default function DmeHeader() {
  const Navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const Token = localStorage.getItem("dmeLogin");
  const Package = localStorage.getItem("package");
  const dme_name = localStorage.getItem("dme_name");
  const id = localStorage.getItem("dmeId");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (localStorage.getItem("dmeLogin") == null) {
      Navigate("/dmeLogin");
    }
  }, []);

  const logout = () => {
    Navigate("/");
    localStorage.removeItem("dmeLogin");
    localStorage.removeItem("permissions");
    localStorage.removeItem("dmeId");
    localStorage.removeItem("package");
    console.log("logout");
  };

  let removeClass = () => {
    var x = document.getElementsByTagName("BODY")[0].classList;
    if (x.contains("sidebar-gone")) {
      x.remove("sidebar-gone");
      x.add("sidebar-show");
    } else {
      x.add("sidebar-gone");
      x.remove("sidebar-show");
    }
  };

  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    let data = localStorage.getItem("SettingValue");
    setThemeColor(data);
    console.log("App.Js", data);
  }, [localStorage.getItem("SettingValue")]);
  useEffect(() => {
    Setting(themeColor);
  }, [themeColor]);
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
    } else {
      console.log("th -> ", $("table thead tr th"));
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  };

  const Password = () => {
    axios.post(`${Baseurl.baseurl}supplier/sent/${id}`).then((res) => {
      console.log(res);
      if (res?.data?.status === true) {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    });
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a href="#" className="nav-link nav-link-lg collapse-btn">
                {" "}
                <i
                  onClick={() => removeClass()}
                  className="fa fa-bars d-lg-none d-xl-none"
                  style={{ color: "" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <h4 className="text-white">DME SUPPLIER</h4>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <Link
              to=""
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {/* DME SUPPLIER */}{" "}
              <img
                alt="image"
                src={img7}
                width="100%"
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">DME</div>
              <Link
                to="/dme/profile"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-user" />
                Profile
              </Link>
              <Link
                to="/dme/password/change"
                className="dropdown-item has-icon text-white"
                onClick={Password}
              >
                {" "}
                <i className="fas fa-eye" />
                Change Password
              </Link>
              <Link
                onClick={logout}
                to="/DmeLogin"
                className="dropdown-item has-icon text-white"
              >
                {" "}
                <i className="fas fa-sign-out-alt" />
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <side id="sidebar-wrapper">
          <div
            className="btn btn-primary float-right mt-3  d-lg-none d-xl-none"
            onClick={() => removeClass()}
          >
            <i className="fa fa-times text-white"></i>
          </div>
          <div className="sidebar-brand">
            <Link to="/DmeDashboard">
              {" "}
              <span className="logo-name">{dme_name}</span>
            </Link>
          </div>
          <div className="sidebar-user">
            <div className="sidebar-user-picture">
              <img alt="image" src={img7} width="100%" />
            </div>
          </div>
          <ul className="sidebar-menu">
            {/* <li className="menu-header text-center">Main</li> */}
            <li className={pathname == "/DmeDashboard" ? "active" : ""}>
              <Link
                to="/DmeDashboard"
                className=" nav-link "
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineDashboard />
                </i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={pathname == " /Dme/Orders" ? "active" : ""}>
              <Link
                to="/Dme/Orders"
                className=" nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <FaFirstOrderAlt />
                </i>
                <span>Orders</span>
              </Link>
            </li>
            {/* <li className={pathname == "/Dme/notes" ? "active" : ""}>
              <Link
                to="/Dme/notes"
                className=" nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <CgNotes />
                </i>
                <span>NOTES</span>
              </Link>
            </li> */}
            <li className={pathname == "/DmeDoctor" ? "active" : ""}>
              <Link
                to="/DmeDoctor"
                className=" nav-link  "
                // onClick={() => removeClass()}
              >
                <i>
                  <FaStethoscope />
                </i>
                <span>Doctor</span>
              </Link>
            </li>
            <li className={pathname == "/DmeTherapist" ? "active" : ""}>
              <Link
                to="/DmeTherapist"
                className="nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <GiSkeletalHand />
                </i>
                <span>Therapist</span>
              </Link>
            </li>
            {/* <li className={pathname == "/DmeSupplierPatient" ? "active" : ""}>
              <Link
                to="/DmeSupplierPatient"
                className="nav-link"
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>Patient's Documents</span>
              </Link>
            </li> */}
            <li className={pathname == "/dme/patient" ? "active" : ""}>
              <Link
                to="/dme/patient"
                className="nav-link"
                // onClick={() => removeClass()}
              >
                <i>
                  <MdOutlineHealthAndSafety />
                </i>
                <span>Patient</span>
              </Link>
            </li>
            {Package?.includes("Premium") ? (
              <li className={pathname == "/DmeStaff" ? "active" : ""}>
                <Link
                  to="/DmeStaff"
                  className=" nav-link "
                  // onClick={() => removeClass()}
                >
                  <i>
                    <FaUserNurse />
                  </i>
                  <span>Staff</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {Package?.includes("basic") ? (
              <li className={pathname == "/DmesupplierUpdate" ? "active" : ""}>
                <Link
                  to="/DmesupplierUpdate"
                  className=" nav-link "
                  // onClick={() => removeClass()}
                >
                  <i>
                    <FiPackage />
                  </i>
                  <span>Update To Premium</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </side>
      </div>
    </div>
  );
}
