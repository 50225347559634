import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import backgroundimg from "../img/background/back1.jpg";
import { useNavigate } from "react-router-dom";
import { Loader } from "react-overlay-loader";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";

export default function Staffregistration() {
  // validation
  const [error, seterror] = useState(false);
  // validation
  // staff Registeration
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(false);
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [staffid, setstaffid] = useState("");
  const Navigate = useNavigate();
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Pass, setPass] = useState(false);
  const [registerscreen, setregisterscreen] = useState(true);
  const [otpscreen, setotpscreen] = useState(false);
  const Submit = () => {
    if (first_name === "") {
      setFirstName(true)
    } else if (last_name === "") {
      setLastName(true)
    } else if (email === "") {
      setEmail(true)
    } else if (password === "") {
      setPass(true)
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("password", password);
      axios
        .post(`${Baseurl.baseurl}staff/auth/register`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("Staff", res.data);
          setstaffid(res?.data?.staff_id)
          if (res.data.status) {
            setLoader(false);
            setregisterscreen(false)
            setotpscreen(true)
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            seterror(true);
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });

    }
  };
  const [code, setcode] = useState("");
  const [otp, setotp] = useState(false);
  const Otp = () => {
    if (code === "") {
      setotp(true)
    } else {
      setLoader(true)
      const Data = {
        'verified_code': code,
      }
      axios.post(`${Baseurl.baseurl}staff/verify/process/${staffid}`, Data)
        .then((res) => {
          console.log("Staff", res.data);
          if (res.data.status) {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            Navigate("/StaffLogin");
          } else {
            setLoader(false)
            enqueueSnackbar("The verified code must be Valid", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  }

  return (
    <div className="page-head-bg" style={{ backgroundImage: `url(${backgroundimg})` }} >
      {loader ? <Loader fullPage loading /> : null}
      {registerscreen ?
        <div className="container mt-5">
          <div className="row">
            <div className="offset-md-2 col-md-8 offset-md-2">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">STAFF REGISTRATION</h3>
                </div>
                {error ? (
                  <div class="alert alert-danger" role="alert">
                    The email has already been taken!{" "}
                  </div>
                ) : null}
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        First Name
                      </label>
                      <input
                        id="frist_name"
                        value={first_name}
                        onChange={(e) => {
                          setfirstname(e.target.value)
                          setFirstName(false)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit()
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                        autofocus
                      />
                      {FirstName ? (
                        <div class="alert alert-danger" role="alert">
                          First Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        Last Name
                      </label>
                      <input
                        id="frist_name"
                        value={last_name}
                        onChange={(e) => {
                          setlastname(e.target.value)
                          setLastName(false)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit()
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                        autofocus
                      />
                      {LastName ? (
                        <div class="alert alert-danger" role="alert">
                          Last Name is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="email" className="text-white">
                        Email
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value)
                          setEmail(false)
                          seterror(false)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit()
                          }
                        }}
                        className="form-control"
                        name="email"
                      />
                      {Email ? (
                        <div class="alert alert-danger" role="alert">
                          Email is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="last_name" className="text-white">
                        Password
                      </label>
                      <input
                        id="last_name"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value)
                          setPass(false)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit()
                          }
                        }}
                        className="form-control"
                        name="last_name"
                      />
                      {Pass ? (
                        <div class="alert alert-danger" role="alert">
                          Password is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-2 form-group col-md-8 offset-md-2">
                      <button
                        type="submit"
                        onClick={Submit}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
                <div className="text-white text-center">
                  <Link to="/" className="text-white">
                    DASHBOARD
                  </Link>
                </div>
                <div className="mt-2 mb-2 text-white text-center">
                  Already Registered?{" "}
                  <Link to="/StaffLogin" className="text-white">
                    Login
                  </Link>
                </div>
                <div className="mb-2 text-white text-center">
                  Design By
                  <a
                    href="https://hnhtechsolutions.com/"
                    target="_blank"
                    className="text-white"
                  >
                    {" "}
                    Hnh Tech Solutions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
      {otpscreen ?
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">OTP</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <input
                        type="number"
                        placeholder="Please Enter Otp Pin"
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value)
                          setotp(false)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Otp()
                          }
                        }}
                        className="form-control"
                        name="otp"
                      />
                      {otp ? (
                        <div class="alert alert-danger" role="alert">
                          Otp is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-2 form-group col-md-8 offset-md-2">
                      <button
                        type="submit"
                        onClick={Otp}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
    </div >
  )
}
