import React, { forwardRef, useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import { Modal, Button } from "react-bootstrap";
import DmeStaffheader from '../Component/DmeStaffheader'

import Footer from '../Component/Footer';
import { Loader } from 'react-overlay-loader';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import { ThemeContext } from "../App"



export default function DmeStaffDoctor() {
    const [Doctor, setDoctor] = useState(true)
    const { theme, toggleTheme } = useContext(ThemeContext)

    const { enqueueSnackbar } = useSnackbar();

    // Data Table
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    // Data table
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [myids2, Setids2] = useState("")
    const [myid, Setid] = useState("")
    const [email, setemail] = useState("")


    // const [ManualPatientId, setManualPatientId] = useState("")
    const [Email, setEmail] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    // Fetch DATA
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [Data2, SetData2] = useState([])
    const Dme_id = localStorage.getItem("dmeId")

    const edit = (e) => {
        console.log("doctors", e)
        setemail(e.email)
        Setid(e.id)
        handleShow()

    }
    function Fetch() {
        fetch(`${Baseurl.baseurl}superadmin/doctor`).then((result) => {
            result.json().then((resp) => {
                setData(resp.doctors)
                setLoader(false)
            })
        })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    useEffect(() => {
        Fetch();
        GetApi()
    }, [""])
    // Fetch DATA
    const GetApi = () => {
        var myHeaders = new Headers();
        var formdata = new FormData();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            //   body: formdata,
            redirect: 'follow'
        };

        fetch(`${Baseurl.baseurl}supplier/patient/view/${Dme_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("patient", result.view_patients[0].patients)
                SetData2(result.view_patients[0].patients)
            })
            .catch(error => console.log('error', error));
    }
    console.log("Data2", Data2)
    const [na, setna] = useState(false)
    const InviteDoctor = () => {
        if (myids2 === "") {
            setna(true)
        } else {
            handleClose();
            setLoader(true)
            var formdata = new FormData();
            formdata.append("patient_id", myids2);
            formdata.append("dme_id", Dme_id);
            formdata.append("doctor_id", myid);
            formdata.append("email", email);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${Baseurl.baseurl}supplier/patient/assign`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    handleClose()
                    setLoader(false)
                    if (result.status) {
                        setLoader(false)
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else if (result.message) {
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    } else {
                        enqueueSnackbar(result.errors.patient_id, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }

                })
                .catch(error => {
                    setLoader(false)
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }
    const [em, setem] = useState(false);
    const InviteDoctorByEmail = () => {
        if (Email === "") {
            setem(true)
        } else {
            setLoader(true)
            handleClose2()
            var formdata = new FormData();
            formdata.append("dme_id", Dme_id);
            formdata.append("email", Email);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch(`${Baseurl.baseurl}supplier/manual/patient/assign`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    setEmail("")
                    if (result.status) {
                        setLoader(false)
                        handleClose2()
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else {
                        handleShow2()
                        enqueueSnackbar(result.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                }).catch(error => {
                    setLoader(false)
                    handleShow2()
                    enqueueSnackbar(error.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }
    }

    //  Doctor Patient DATA
    const [dpatient, setdpatient] = useState([])
    function DoctorPatient(id) {
        setLoader(true)
        fetch(`${Baseurl.baseurl}supplier/patient/assign/view/${id}`).then((result) => {
            result.json().then((resp) => {
                console.log(resp.view_patients, "Doctor")
                setdpatient(resp.view_patients)
                setLoader(false)
                handleShow3();
            })
        })
            .catch(error => {
                setLoader(false)
                enqueueSnackbar(error.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            });
    }
    // Doctor Patient DATA
    return <div>
        <DmeStaffheader />
        <div className="main-content">
            {loader ? <Loader fullPage loading /> : null}
            <section>
                <div className='card'>
                    <div className='card-header row'>
                        <div className='col-md-6'>
                            <h5 className='mb-0'>DOCTOR</h5>
                        </div>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-3'>
                            <button className='btn btn-primary float-right'
                                onClick={handleShow2}
                            >Invite Doctors By Email</button>
                        </div>
                    </div>
                    <div className='card-body col-md-12'>
                        <MaterialTable
                            title=""
                            icons={tableIcons}
                            columns={[
                                // { title: 'ID', field: 'id' },
                                {
                                    title: 'First Name', field: 'first_name', render: rowData => <>
                                        <a onClick={() => DoctorPatient(rowData.id)}>{rowData.first_name}</a>
                                    </>
                                },
                                { title: 'Last Name', field: 'last_name' },
                                { title: 'Email', field: 'email' },
                            ]}
                            data={data}
                            actions={[
                                rowData => ({
                                    icon: 'insert_invitation',
                                    tooltip: 'Invite',
                                    onClick: (event, rowData) => edit(rowData)
                                })
                            ]}
                            style={{
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                            }}
                            options={{
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                headerStyle: {
                                    backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                    color: '#FFF'
                                },
                                rowStyle: {
                                    backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                    color: '#FFF'
                                },
                                actionsColumnIndex: -1
                            }}
                        />
                    </div>
                </div>
            </section>
        </div>
        <Footer />
        {/*  invite modal */}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className=''>
                <Modal.Title>Invite Doctor</Modal.Title>
                <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="">PATIENTS FIRST NAME</label>
                            <select className='form-control' onClick={(e) => {
                                Setids2(e.target.value)
                                setna(false)
                            }}>
                                <option value={"Select a value"}>Select a value</option>
                                {
                                    Data2.map((e) =>
                                        <option value={e.id} >{e.first_name}</option>
                                    )
                                }
                            </select>
                            {na ? <div className="alert alert-danger" role="alert">PATIENTS Name is Requiered</div> : null}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-primary col-md-12' onClick={InviteDoctor}>Invite</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {/*  invite modal */}

        {/*   invite by email */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header>
                <Modal.Title>Invite Doctor By Email</Modal.Title>
                <i onClick={handleClose2} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label htmlFor='email'>Email Doctor</label>
                        <input
                            id="email"
                            value={Email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setem(false)
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    InviteDoctorByEmail();
                                }
                            }}
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Doctor"
                        />
                        {em ? <div className="alert alert-danger" role="alert">Email is Requiered</div> : null}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-primary col-md-12' onClick={InviteDoctorByEmail}>Invite</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {/*  invite by email */}

        {/*   Doctor Patients */}
        <Modal
            size="xl"
            show={show3} onHide={handleClose3}>
            <Modal.Header>
                <Modal.Title>Doctor Patients</Modal.Title>
                <i onClick={handleClose3} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
            </Modal.Header>
            <Modal.Body>
                <div className='card-body col-md-12'>
                    <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                            { title: 'Doctor First Name', field: 'first_name', render: rowData => rowData.doctor.first_name },
                            { title: 'Doctor Email', field: 'email', render: rowData => rowData.doctor.email },
                            { title: 'Patient First Name', field: 'first_name', render: rowData => rowData.patient.first_name },
                            { title: 'Patient Last Name', field: 'last_name', render: rowData => rowData.patient.last_name },
                            { title: 'Patient Email', field: 'email', render: rowData => rowData.patient.email },
                        ]}
                        data={dpatient}
                        style={{
                            backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                        }}
                        options={{
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            headerStyle: {
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                color: '#FFF'
                            },
                            rowStyle: {
                                backgroundColor: theme === "dark" ? '#353c48' : "#1896c8",
                                color: '#FFF'
                            },
                            actionsColumnIndex: -1
                        }}
                    />
                </div>
            </Modal.Body>
        </Modal>
        {/* Doctor Patients */}
    </div>

}