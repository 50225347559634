import { React, useState , useEffect } from 'react';
import StaffHeader from '../Component/StaffHeader';
import img2 from "../img/user.png";
import Footer from '../Component/Footer';
import Baseurl from '../Component/Baseurl';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Loader } from 'react-overlay-loader';
import $ from 'jquery';
export default function StaffProfile() {
  const StaffLoginid = localStorage.getItem("StaffLoginid")
    const { enqueueSnackbar } = useSnackbar();
    const [loader, setLoader] = useState(false)

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [lname, setlname] = useState("");
    const [na, setna] = useState(false);
    const [lna, setlna] = useState(false);
    const [em, setem] = useState(false);
    const Update = () => {
        if (name === "") {
            setna(true)
        } else if (lname === "") {
            setlna(true)
        } else if (email === "") {
            setem(true)
        } else {
            setLoader(true)
            const Data = {
                'first_name': name,
                'email': email,
                'last_name': lname,
            }
            axios.post(`${Baseurl.baseurl}staff/auth/profile/${StaffLoginid}`, Data)
                .then((result => {
                    setLoader(false)
                    console.log(result.data)
                    localStorage.setItem("staffname", name);
                    if (result.data) {
                        setname("");
                        setemail("");
                        setlname("");
                        enqueueSnackbar("Staff Profile Successfully Updated", {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'success',
                            autoHideDuration: 2000,
                        });
                    } else {
                        enqueueSnackbar(result.data.message, {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            variant: 'error',
                            autoHideDuration: 2000,
                        });
                    }
                }))
                .catch(errors => {
                    setLoader(false)
                    enqueueSnackbar(errors.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });
                });
        }

    }
    const [themeColor, setThemeColor] = useState('')
    useEffect(() => {
      let data = localStorage.getItem('SettingValue');
      setThemeColor(data)
      console.log("App.Js", data);
    }, [localStorage.getItem('SettingValue')])
    useEffect(() => {
      Setting(themeColor)
    }, [themeColor])
    const Setting = (value) => {
      if (value == "light") {
        $("body").removeClass("dark-sidebar");
        $("body").addClass("light-sidebar");
        $("body").removeClass();
        $("body").addClass("light");
        $("body").addClass("light-sidebar");
        $("body").addClass("theme-white");
        $(".choose-theme li").removeClass("active");
        $(".choose-theme li[title|='white']").addClass("active");
        $(".selectgroup-input[value|='1']").prop("checked", true);
        $('.MuiPaper-root').removeClass('table-dark')
        $('.MTableHeader-header-13').removeClass('table-dark')
        $('.MuiTable-root').removeClass('table-dark')
        $('.MTableToolbar-root-5').removeClass('table-dark')
      } else {
        console.log('th -> ', $('table thead tr th'))
        $('.MuiPaper-root').addClass('table-dark')
        $('.MuiTable-root').addClass('table-dark')
        $('.MTableHeader-header-13').addClass('table-dark')
        $('.MTableHeader-header-13 ').addClass('table-dark')
        $('.MTableToolbar-root-5').addClass('table-dark')
        $("body").removeClass("light-sidebar");
        $("body").addClass("dark-sidebar");
        $("body").removeClass();
        $("body").addClass("dark");
        $("body").addClass("dark-sidebar");
        $("body").addClass("theme-black");
        $(".choose-theme li").removeClass("active");
        $(".choose-theme li[title|='black']").addClass("active");
        $(".selectgroup-input[value|='2']").prop("checked", true);
      }
    }
    return <div>
        {loader ? <Loader fullPage loading /> : null}
        <StaffHeader />
        <div className="main-content">
            <div className="container">
            </div>
            <div className="row justify-content-center">
                <div className="col-md-3">
                    <img type="file" src={img2} className="rounded-circle" width={"100%"} alt="profile" />
                </div>
            </div>
            <div className="row text-center justify-content-center mt-5">
                <div className="col-md-10">
                    <div className="row  justify-content-center">
                        <div className="col-md-6">
                            <input type="text" value={name} onChange={(e) => {
                                setname(e.target.value)
                                setna(false)
                            }} placeholder='First Name' className='form-control mb-4' />
                            {na ? <div className="alert alert-danger" role="alert">First Name is Requiered !</div> : null}
                        </div>
                        <div className="col-md-6">
                            <input type="text" placeholder='Last Name' value={lname} onChange={(e) => {
                                setlname(e.target.value)
                                setlna(false)
                            }} className='form-control mb-4' />
                            {lna ? <div className="alert alert-danger" role="alert">Last Name is Requiered !</div> : null}
                        </div>
                    </div>
                    <div className="row  justify-content-center">
                        <div className="col-md-12">
                            <input type="email" placeholder='Email' value={email} onChange={(e) => {
                                setemail(e.target.value)
                                setem(false)
                            }} className='form-control mb-4' />
                            {em ? <div className="alert alert-danger" role="alert">Email is Requiered !</div> : null}
                        </div>
                    </div>
                    <div className="row justify-content-center text-center text-align-center">
                        <div className="col-md-6">
                            <button type='button' className="btn btn-primary btn-block px-4" onClick={Update}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}
