import React, { useState, useEffect } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';

export default function Permission() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(true)

  const [Data, SetData] = useState([])
  // const [item_id , Set_id] = useState()
  const [role_name, Setrole_name] = useState("staff")
  const [role_id, Setrole_id] = useState("")
  const [create, Setcreate] = useState("false")
  const [view, Setview] = useState("true")
  const [Delete, SetDelete] = useState("false")
  const [update, Setupdate] = useState("false")
  const [staffer, setstaffer] = useState(false)
  const Add = () => {
    setLoader(true)
    if (role_id === "") {
      setstaffer(true)
      setLoader(false)

    } else {
      setstaffer(false)
      var myHeaders = new Headers();
      var formdata = new FormData();
      formdata.append("role_name", role_name);
      formdata.append("role_id", role_id);
      if (create == 'true') {
        formdata.append("create", create);
      }
      if (view == 'true')
        formdata.append("view", "view-dme");
      if (Delete == 'true')
        formdata.append("Delete", Delete);
      if (update == 'true')
        formdata.append("update", update);
      console.log(formdata);
      console.log(role_id);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(`${Baseurl.baseurl}superadmin/permission/store`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status) {
            setLoader(false)
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false)
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        })
        .catch(error => {
          setLoader(false)
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  }

  function GetData() {
    fetch(`${Baseurl.baseurl}superadmin/staff`).then((result) => {
      result.json().then((resp) => {
        SetData(resp.staff)
        setLoader(false)
        console.log(Data)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    GetData();
  }, [])
  return (
    <div>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <div className="card">
          <div className="card-header ">
            <div className='row'>
              <div className='col-md-6'>
                <h5 className='mb-0'>PERMISSION</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <label className="text-white">Staff Name</label>
                  <select className='form-control' value={role_id} onChange={(e) => Setrole_id(e.target.value)}>
                    <option value={"Select a value"}>{"Select a value"}</option>
                    {
                      Data.map((item) =>
                        <option value={item.id}>{item.first_name}</option>
                      )
                    }
                  </select>
                  {staffer ? <div class="alert alert-danger" role="alert">Staff Name is Required! </div> : null}
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label className="d-block text-white">Authority</label>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value={create} onChange={(e) => Setcreate(e.target.value == "false" ? "true" : "false")} id="inlineCheckbox1" />
                    <label className="form-check-label text-white" for="inlineCheckbox1">Create</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" checked value={view} onChange={(e) => Setview(e.target.value == "false" ? "true" : "false")} id="inlineCheckbox4" />
                    <label className="form-check-label text-white" for="inlineCheckbox4">view</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value={Delete} onChange={(e) => SetDelete(e.target.value == "false" ? "true" : "false")} id="inlineCheckbox3" />
                    <label className="form-check-label text-white" for="inlineCheckbox3">Delete</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value={update} onChange={(e) => Setupdate(e.target.value == "false" ? "true" : "false")} id="inlineCheckbox2" />
                    <label className="form-check-label text-white" for="inlineCheckbox2">Update</label>
                  </div>


                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button type='button' className='btn btn-primary float-right' onClick={() => Add()} >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
