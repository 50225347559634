import React, { useState, useEffect } from 'react';
import Footer from '../Component/Footer';
import img1 from '../img/banner/1.png'
import img2 from '../img/banner/2.png'
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import DoctorHeader from '../Component/DoctorHeader';
import { useSnackbar } from 'notistack';
import $ from 'jquery';
import Task from './Task'
export default function DoctorDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const DoctorId = localStorage.getItem("DoctorId")

  const [loader, setLoader] = useState(true)
  const [total, settotal] = useState("")
  function GetCount() {
    fetch(`${Baseurl.baseurl}doctor/reffrel/counts/view/${DoctorId}`)
      .then((result) => {
        result.json().then((total) => {
          settotal(total.reffrel)
          console.log(total.reffrel)
          setLoader(false)
        })
      })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    GetCount();
  }, [])
  const [patient, setpatient] = useState("")
  function Patientcounts() {
    fetch(`${Baseurl.baseurl}doctor/count/patients/${DoctorId}`)
      .then((result) => {
        result.json().then((total) => {
          setpatient(total?.count_patients)
          console.log(total?.count_patients, "patient")
          setLoader(false)
        })
      })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    Patientcounts();
  }, [])

  const [themeColor, setThemeColor] = useState('')
  useEffect(() => {
    let data = localStorage.getItem('SettingValue');
    setThemeColor(data)
    console.log("App.Js", data);
  }, [localStorage.getItem('SettingValue')])
  useEffect(() => {
    Setting(themeColor)
  }, [themeColor])
  const Setting = (value) => {
    if (value == "light") {
      $("body").removeClass("dark-sidebar");
      $("body").addClass("light-sidebar");
      $("body").removeClass();
      $("body").addClass("light");
      $("body").addClass("light-sidebar");
      $("body").addClass("theme-white");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='white']").addClass("active");
      $(".selectgroup-input[value|='1']").prop("checked", true);
      $('.MuiPaper-root').removeClass('table-dark')
      $('.MTableHeader-header-13').removeClass('table-dark')
      $('.MuiTable-root').removeClass('table-dark')
      $('.MTableToolbar-root-5').removeClass('table-dark')
      $('.MTableToolbar-root-5').removeClass('table-dark')
    } else {
      console.log('th -> ', $('table thead tr th'))
      $('.MuiPaper-root').addClass('table-dark')
      $('.MuiTable-root').addClass('table-dark')
      $('.MTableHeader-header-13').addClass('table-dark')
      $('.MTableHeader-header-13 ').addClass('table-dark')
      $('.MTableToolbar-root-5').addClass('table-dark')
      $("body").removeClass("light-sidebar");
      $("body").addClass("dark-sidebar");
      $("body").removeClass();
      $("body").addClass("dark");
      $("body").addClass("dark-sidebar");
      $("body").addClass("theme-black");
      $(".choose-theme li").removeClass("active");
      $(".choose-theme li[title|='black']").addClass("active");
      $(".selectgroup-input[value|='2']").prop("checked", true);
    }
  }
  return <div>
    <DoctorHeader />
    {loader ? <Loader fullPage loading /> : null}
    <div classname="container">
      <div className="main-content">
        <section className="section">
          <div className="row">
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img1} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">ORDERS</h6>
                        <h4 className="text-right"><span>{total}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img2} width="100%" alt />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">PATIENT</h6>
                        <h4 className="text-right"><span>{patient}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Task />
    <Footer />
  </div>

}
