import TharepistHeader from "../Component/TharepistHeader";
import Footer from "../Component/Footer";
import React, { forwardRef, useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ImCross } from "react-icons/im";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../App";

export default function TherapistOrder() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const Dmesupplierid = localStorage.getItem("Dmesupplierid");
  const therapist_id = localStorage.getItem("therapist_id");
  const therapist_name = localStorage.getItem("therapist_name");
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  // order view
  const [Data, setData] = useState([]);
  const view = () => {
    axios
      .get(`${Baseurl.baseurl}supplier/order/view/${Dmesupplierid}`)
      .then((resp) => {
        console.log(resp?.data?.reffrel, "orders");
        setLoader(false);
        setData(resp?.data?.reffrel);
      })
      .catch((errors) => {
        setLoader(false);
        // enqueueSnackbar(errors.message, {
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   variant: "error",
        //   autoHideDuration: 2000,
        // });
      });
  };
  useEffect(() => {
    view();
  }, []);

  // order view

  const [therapistnotes, settherapistnotes] = useState([]);
  const [notes, setnotes] = useState(false);
  const [id, setid] = useState([]);
  const Notes = (e) => {
    setid(e.id);
    handleShow();
    console.log(e.id, "reffrelid");
  };
  const Addnotes = () => {
    if (therapistnotes === "") {
      setnotes(true);
    } else {
      setLoader(true);
      handleClose();
      const formData = {
        reffrel_id: id,
        therapist_id: therapist_id,
        dme_id: Dmesupplierid,
        therapist_notes: therapistnotes,
        therapist_name: therapist_name,
      };
      axios
        .post(`${Baseurl.baseurl}therapist/notes`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data);
          if (res.data) {
            setLoader(false);
            settherapistnotes("");
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };

  const [Data2, setData2] = useState([]);
  const NotesView = (id) => {
    setLoader(true);
    axios
      .get(`${Baseurl.baseurl}therapist/notes/view/${therapist_id}/${id}`)
      .then((result) => {
        setLoader(false);
        handleShow2();
        console.log(result.data.reffrel[0]?.notes);
        setData2(result.data?.reffrel[0]?.notes);
      })
      .catch((errors) => {
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  return (
    <>
      <TharepistHeader />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-9">
                <h5 className="mb-0">Order</h5>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={[
                  // { title: 'Dme Name', field: 'dme_name' },
                  { title: "Order Description", field: "machine_name" },
                  {
                    title: "Notes",
                    field: "notes",
                    render: (rowData) => (
                      <>
                        <a onClick={() => NotesView(rowData.id)}>
                          {rowData.notes}
                        </a>
                      </>
                    ),
                  },
                  { title: "Status", field: "status" },
                ]}
                data={Data}
                actions={[
                  {
                    icon: "assignment_turned_in",
                    tooltip: "Add Notes",
                    onClick: (event, rowData) => Notes(rowData),
                  },
                ]}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>

      {/*  Notes modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="">
          <Modal.Title>Add Notes</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="">Notes</label>
                <textarea
                  value={therapistnotes}
                  onChange={(e) => {
                    settherapistnotes(e.target.value);
                    setnotes(false);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      Addnotes();
                    }
                  }}
                  className="form-control"
                ></textarea>
                {notes ? (
                  <div className="alert alert-danger" role="alert">
                    Notes is Requiered
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary col-md-12" onClick={Addnotes}>
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Notes modal */}

      {/*  Notes view */}
      <Modal show={show2} size="xl" onHide={handleClose2}>
        <Modal.Header className="">
          <Modal.Title>Notes</Modal.Title>
          <i
            onClick={handleClose2}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
              {
                title: "Docter",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.doctor_name}</span> <br />
                    Notes : <span>{rowData?.doctor_notes}</span> <br />
                    Date : <span>{rowData?.date}</span> <br />
                    Time : <span>{rowData?.time}</span>
                  </>
                ),
              },
              {
                title: "Dme Supplier",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.dme_name}</span> <br />
                    Notes : <span>{rowData?.dme_notes}</span> <br />
                    Date : <span>{rowData?.date}</span> <br />
                    Time : <span>{rowData?.time}</span>
                  </>
                ),
              },
              {
                title: "Therapist",
                field: "",
                render: (rowData) => (
                  <>
                    Name : <span>{rowData?.therapist_name}</span> <br />
                    Notes : <span>{rowData?.therapist_notes}</span>
                    <br />
                    Date : <span>{rowData?.date}</span>
                    <br />
                    Time : <span>{rowData?.time}</span>
                    <br />
                  </>
                ),
              },
            ]}
            data={Data2}
            style={{
              backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
            }}
            options={{
              // pageSize: 20,
              // pageSizeOptions: [20, 50, 100],
              headerStyle: {
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                color: "#FFF",
              },
              rowStyle: {
                backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
            }}
          />
        </Modal.Body>
      </Modal>
      {/*  Notes view */}
      <Footer />
    </>
  );
}
