import React, { useState, useEffect ,useContext } from 'react';
import TharepistHeader from '../Component/TharepistHeader';
import Footer from '../Component/Footer';
import img1 from '../img/banner/1.png'
import img2 from '../img/banner/2.png'
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import Task from './Task';
import { ThemeContext } from "../App"

export default function TharepistDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const therapist_id = localStorage.getItem("therapist_id")
  const { theme, toggleTheme } = useContext(ThemeContext)

  const [loader, setLoader] = useState(true)
  const [total, settotal] = useState("")
  function GetCount() {
    fetch(`${Baseurl.baseurl}therapist/reffrel/count/${therapist_id}`).then((result) => {
      result.json().then((total) => {
        settotal(total)
        console.log(total)
        setLoader(false)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar("Please Check Your Internet Connection!", {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    GetCount();
  }, [])
  const [patient, setpatient] = useState("")
  function counting() {
    fetch(`${Baseurl.baseurl}therapist/count/assign/patients/${therapist_id}`).then((result) => {
      result.json().then((total) => {
        setpatient(total.count_patients)
        console.log(total.count_patients, "patient")
        setLoader(false)
      })
    })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar("Please Check Your Internet Connection!", {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    counting();
  }, [])


  return <div>
    <TharepistHeader />
    {loader ? <Loader fullPage loading /> : null}
    <div classname="container">
      <div className="main-content">
        <section className="section">
          <div className="row">
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img1} width="100%" alt="Dme Supplier" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">ORDERS</h6>
                        <h4 className="text-right"><span>{total.reffrel}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card cardhover">
                <div className="card-statistic-5">
                  <div className="info-box7-block">
                    <div className="row ">
                      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="banner-img">
                          <img src={img2} width="100%" alt="Dme Supplier" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <h6 className="m-b-20 text-right">PATIENT</h6>
                        <h4 className="text-right"><span>{patient}</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Task />
    </div>
    <Footer />
  </div>;
}
