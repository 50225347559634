import React, { useState, useEffect } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { ImCross } from "react-icons/im";
import { useSnackbar } from 'notistack';

export default function Admin() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(true)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, Setname] = useState("")
  const [Data, setData] = useState([])
  const SuperAdminToken = localStorage.getItem("superAdmin")
  // integrate get GetApi
  const Navigate = useNavigate();

  const GetApi = () => {
    var requestOptions = {
      method: 'GET',
      // body: formdata,
      redirect: 'follow'
    };

    fetch(`${Baseurl.baseurl}client/policy`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        setData(result.policy)
        setLoader(false)
      })

      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });

  }


  useEffect(() => {
    GetApi();
  }, [])


  console.log("data", Data)
  // integrate update api
  const UpdateApi = () => {
    handleClose();
    setLoader(true)
    console.log("Name", name)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${SuperAdminToken}`);

    var formdata = new FormData();
    formdata.append("name", name);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${Baseurl.baseurl}superadmin/privacy/policy`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        GetApi();

        handleClose();
        if (result.status) {

          localStorage.setItem('superAdmin', (result.access));

          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      })
      .catch(error => {
        setLoader(false)
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          autoHideDuration: 2000,
        });
      });
  }


  const edit = (e) => {

    console.log("editfunction=============", e);
    handleShow();
    Setname(e.name);





  };
  return <div>
    <Header />
    {loader ? <Loader fullPage loading /> : null}
    <div className="main-content">
      <section>
        <div className='card'>
          <div className='card-header row'>
            <div className='col-md-6'>
              <h5 className='mb-0'>PRIVACY & POLICY</h5>
            </div>
            <div className='col-md-6 text-right'>
              <Button className='btn btn-primary' onClick={() => edit(Data)} >
                <i className='fa fa-edit'></i>
              </Button>
            </div>
          </div>
          <div className='card-body col-md-12'>
            <div className='text-white'>{Data.name}</div>
          </div>



        </div>
      </section>
    </div>
    <Footer />
    <Modal show={show} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>Add Privacy/Policy</Modal.Title>
        <i onClick={handleClose} style={{ fontSize: "24px" }} className="btn btn-primary"><ImCross className='delete-btn' /></i>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row justify-content">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Privacy Policy</label>
                  <textarea value={name} onChange={(e) => Setname(e.target.value)} className="form-control" required />
                  <div className="valid-feedback">
                    Good job!
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button type='button' className='btn btn-block btn-primary' onClick={() => UpdateApi()} >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>;
}