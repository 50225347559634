import React, { useState, useEffect, forwardRef, useContext } from "react";
import MaterialTable from "material-table";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";

export default function Doctor() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { theme, toggleTheme } = useContext(ThemeContext);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [loader, setLoader] = useState(true);
  const [myid, Setmyid] = useState("");
  // ADD
  const [first_name, Setfirst_name] = useState("");
  const [last_name, Setlast_name] = useState("");
  const [user_name, Setuser_name] = useState("");
  const [email, Setemail] = useState("");
  const [password, Setpassword] = useState("");
  // ADD
  const [Data, SetData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    Setfirst_name("");
    Setlast_name("");
    Setemail("");
    // Setuser_name("");
    Setpassword("");
    setcompany_name("");
    setaddress("");
    setcity("");
    setcountry("");
    setzip_code("");
  };

  // const [company_name, setcompany_name] = useState("");
  // const [address, setaddress] = useState("");
  // const [city, setcity] = useState("");
  // const [country, setcountry] = useState("");
  // const [zip_code, setzip_code] = useState("");
  // const [state, setstate] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [data, setData] = useState([]);
  //SHOW
  function add() {
    fetch(`${Baseurl.baseurl}superadmin/doctor`)
      .then((result) => {
        result.json().then((resp) => {
          setData(resp.doctors);
          setLoader(false);
        });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  useEffect(() => {
    add();
  }, [""]);
  console.warn(data);
  //ADD
  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Pass, setPass] = useState(false);
  const [User, setUser] = useState(false);
  const [companyname, setcompanyname] = useState(false);
  const [company_name, setcompany_name] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [state, setstate] = useState("");

  const [cit, setcit] = useState(false);
  const [coun, setcoun] = useState(false);
  const [zip, setzip] = useState(false);
  const [sta, setsta] = useState(false);
  const [addr, setaddr] = useState(false);

  const AddAdmin = () => {
    if (company_name === "") {
      setcompanyname(true);
    } else if (first_name === "") {
      setFirstName(true);
    } else if (last_name === "") {
      setLastName(true);
    } else if (address === "") {
      setaddr(true);
    } else if (city === "") {
      setcit(true);
    } else if (country === "") {
      setcoun(true);
    } else if (zip_code === "") {
      setzip(true);
    } else if (state === "") {
      setsta(true);
    } else {
      handleClose();
      setLoader(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer`);
      var formdata = new FormData();
      formdata.append("company_name", company_name);
      formdata.append("firstname", first_name);
      formdata.append("lastname", last_name);
      formdata.append("address", address);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("zip_code", zip_code);
      formdata.append("city", city);
      formdata.append("email", email);
      formdata.append("password", password);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${Baseurl.baseurl}superadmin/doctor/store `, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setFirstName(false);
          setLastName(false);
          setEmail(false);
          setaddr(false);
          setcit(false);
          setcoun(false);
          setzip(false);
          setsta(false);
          setcompanyname(false);
          setLoader(false);
          if (result.status) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            add();
            handleClose();
          }
          if (result.status === false && result.errors.email[0]) {
            setLoader(false);
            enqueueSnackbar(result.errors.email[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
          if (result.status === false && result.message) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };

  // delete
  function Deleteva(id) {
    setLoader(true);
    fetch(`${Baseurl.baseurl}superadmin/doctor/delete/${id}`, {
      method: "GET",
    }).then((result) => {
      result
        .json()
        .then((resp) => {
          setLoader(false);
          if (result.status) {
            enqueueSnackbar("Doctor Successfully Deleted", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            add();
          } else {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
          console.warn(resp);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    });
    add();
  }
  // EDIT
  const edit = (e) => {
    console.log("edit=============", e);
    handleShow2();
    Setfirst_name(e.first_name);
    Setlast_name(e.last_name);
    Setuser_name(e.user_name);
    Setemail(e.email);
    setcompany_name(e.company_name);
    setaddress(e.address);
    setcity(e.city);
    setcountry(e.country);
    setzip_code(e.zip_code);
    Setmyid(e.id);
  };

  // update
  const UpdateApi = () => {
    if (company_name === "") {
      setcompanyname(true);
    } else if (first_name === "") {
      setFirstName(true);
    } else if (last_name === "") {
      setLastName(true);
    } else if (address === "") {
      setaddr(true);
    } else if (city === "") {
      setcit(true);
    } else if (country === "") {
      setcoun(true);
    } else if (zip_code === "") {
      setzip(true);
    } else if (state === "") {
      setsta(true);
    } else {
      handleClose();
      setLoader(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer`);
      var formdata = new FormData();
      formdata.append("company_name", company_name);
      formdata.append("firstname", first_name);
      formdata.append("lastname", last_name);
      formdata.append("address", address);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("zip_code", zip_code);
      formdata.append("city", city);
      formdata.append("email", email);

      // formdata.append("password", password);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        `${Baseurl.baseurl}superadmin/doctor/update/${myid}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setFirstName(false);
          setLastName(false);
          setEmail(false);
          setaddr(false);
          setcit(false);
          setcoun(false);
          setzip(false);
          setsta(false);
          setcompanyname(false);
          setLoader(false);
          console.log(result);
          add();
          handleClose();
          if (result.status) {
            setLoader(false);
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            handleClose2();
          }
          if (result.status === false && result.errors.email[0]) {
            setLoader(false);
            enqueueSnackbar(result.errors.email[0], {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
          if (result.status === false && result.message) {
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <div>
      <Header />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">DOCTOR</h5>
              </div>
              <div className="col-md-6">
                <Button
                  className="btn btn-primary float-right"
                  onClick={handleShow}
                >
                  <AiOutlineUserAdd className="delete-btn" />
                </Button>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                columns={[
                  { title: "Company Name", field: "company_name" },
                  { title: "First Name", field: "first_name" },
                  { title: "Last Name", field: "last_name" },
                  { title: "Address", field: "address" },
                  { title: "Country", field: "country" },
                  { title: "City", field: "city" },
                  { title: "State", field: "state" },
                  { title: "Email", field: "email" },
                  { title: "Password", field: "password" },
                  {
                    title: "Package",
                    field: "packages",
                    render: (rowData) => rowData.packages?.name,
                  },
                ]}
                data={data}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => edit(rowData),
                  },

                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete",
                    onClick: (event, rowData) => Deleteva(rowData.id),
                  }),
                ]}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
      {/* add */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add New Doctor</Modal.Title>
          <i
            onClick={handleClose}
            style={{ fontSize: "24px" }}
            className="btn btn-primary"
          >
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      id="package"
                      value={company_name}
                      onChange={(e) => {
                        setcompany_name(e.target.value);
                        setcompanyname(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {companyname ? (
                      <div class="alert alert-danger" role="alert">
                        Company Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="package"
                      value={first_name}
                      onChange={(e) => {
                        Setfirst_name(e.target.value);
                        setFirstName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {FirstName ? (
                      <div class="alert alert-danger" role="alert">
                        First Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        Setlast_name(e.target.value);
                        setLastName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {LastName ? (
                      <div class="alert alert-danger" role="alert">
                        Last Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                        setaddr(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {addr ? (
                      <div class="alert alert-danger" role="alert">
                        Address is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>city</label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                        setcit(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {cit ? (
                      <div class="alert alert-danger" role="alert">
                        City is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>country</label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setcountry(e.target.value);
                        setcoun(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {coun ? (
                      <div class="alert alert-danger" role="alert">
                        Country is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>zip</label>
                    <input
                      type="text"
                      value={zip_code}
                      onChange={(e) => {
                        setzip_code(e.target.value);
                        setzip(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {zip ? (
                      <div class="alert alert-danger" role="alert">
                        Zip code is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                        setsta(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {sta ? (
                      <div class="alert alert-danger" role="alert">
                        State is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        Setemail(e.target.value);
                        setEmail(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {Email ? (
                      <div class="alert alert-danger" role="alert">
                        Email is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        Setpassword(e.target.value);
                        setPass(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue="aaallli"
                      required
                    />
                    {Pass ? (
                      <div class="alert alert-danger" role="alert">
                        Enter Maximum 8 Digit!
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => AddAdmin()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* add */}

      {/* Edit  */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>Update Doctor Detail</Modal.Title>
          <i
            onClick={handleClose2}
            style={{ fontSize: "24px" }}
            className=" btn btn-primary"
          >
            {" "}
            <ImCross className="delete-btn" />
          </i>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
            <div className="row justify-content">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      id="package"
                      value={company_name}
                      onChange={(e) => {
                        setcompany_name(e.target.value);
                        setcompanyname(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {companyname ? (
                      <div class="alert alert-danger" role="alert">
                        Company Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      id="package"
                      value={first_name}
                      onChange={(e) => {
                        Setfirst_name(e.target.value);
                        setFirstName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {FirstName ? (
                      <div class="alert alert-danger" role="alert">
                        First Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={last_name}
                      onChange={(e) => {
                        Setlast_name(e.target.value);
                        setLastName(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue=""
                      required
                    />
                    {LastName ? (
                      <div class="alert alert-danger" role="alert">
                        Last Name is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                        setaddr(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {addr ? (
                      <div class="alert alert-danger" role="alert">
                        Address is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>city</label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => {
                        setcity(e.target.value);
                        setcit(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {cit ? (
                      <div class="alert alert-danger" role="alert">
                        City is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>country</label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setcountry(e.target.value);
                        setcoun(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {coun ? (
                      <div class="alert alert-danger" role="alert">
                        Country is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>zip</label>
                    <input
                      type="text"
                      value={zip_code}
                      onChange={(e) => {
                        setzip_code(e.target.value);
                        setzip(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {zip ? (
                      <div class="alert alert-danger" role="alert">
                        Zip code is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => {
                        setstate(e.target.value);
                        setsta(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                    />
                    {sta ? (
                      <div class="alert alert-danger" role="alert">
                        State is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        Setemail(e.target.value);
                        setEmail(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      required
                      defaultValue="abc@gmail.com"
                    />
                    {Email ? (
                      <div class="alert alert-danger" role="alert">
                        Email is Required!
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        Setpassword(e.target.value);
                        setPass(false);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          AddAdmin();
                        }
                      }}
                      className="form-control"
                      defaultValue="aaallli"
                      required
                    />
                    {Pass ? (
                      <div class="alert alert-danger" role="alert">
                        Enter Maximum 8 Digit!
                      </div>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => UpdateApi()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Edit  */}
    </div>
  );
}
