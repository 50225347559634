import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import backgroundimg from "../img/background/back1.jpg";
import { useNavigate } from "react-router-dom";
import { Loader } from "react-overlay-loader";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";

export default function DoctorReg() {
  const { enqueueSnackbar } = useSnackbar();

  // Doctor Registeration

  // validation
  const [error, seterror] = useState(false);
  // validation

  const [loader, setLoader] = useState(false);
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [zip_code, setzip_code] = useState("");
  const Navigate = useNavigate();

  const [FirstName, setFirstName] = useState(false);
  const [LastName, setLastName] = useState(false);
  const [Email, setEmail] = useState(false);
  const [Pass, setPass] = useState(false);
  const [add, setadd] = useState(false);
  const [cit, setcit] = useState(false);
  const [company, setcompany] = useState(false);
  const [count, setcount] = useState(false);
  const [sta, setsta] = useState(false);
  const [zip, setzip] = useState(false);

  const [id, setid] = useState("");
  const [registerscreen, setregisterscreen] = useState(true);
  const [otpscreen, setotpscreen] = useState(false);
  const Submit = () => {
    if (first_name === "") {
      setFirstName(true);
    } else if (last_name === "") {
      setLastName(true);
    } else if (address === "") {
      setadd(true);
    } else if (city === "") {
      setcit(true);
    } else if (company_name === "") {
      setcompany(true);
    } else if (country === "") {
      setcount(true);
    } else if (state === "") {
      setsta(true);
    } else if (zip_code === "") {
      setzip(true);
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append("firstname", first_name);
      formData.append("lastname", last_name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("company_name", company_name);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("zip_code", zip_code);
      axios
        .post(`${Baseurl.baseurl}doctor/register`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("doctorid", res.data.doctor_id);
          setid(res?.data?.doctor_id);
          if (res.data.status) {
            setregisterscreen(false);
            setotpscreen(true);
            console.log(res.data.message);
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            seterror(true);
          }
        })
        .catch((errors) => {
          setLoader(false);
          enqueueSnackbar(errors.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        });
    }
  };

  const [code, setcode] = useState("");
  const [otp, setotp] = useState(false);
  const Otp = () => {
    if (code === "") {
      setotp(true);
    } else {
      setLoader(true);
      const Data = {
        verified_code: code,
      };
      axios
        .post(`${Baseurl.baseurl}doctor/verify/otp/${id}`, Data)
        .then((res) => {
          console.log("Doctor", res.data);
          if (res.data.status) {
            setLoader(false);
            enqueueSnackbar(res.data.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
            Navigate("/Doctorpackage", { state: { id: id } });
          } else {
            setLoader(false);
            enqueueSnackbar("The verified code must be Valid", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };

  return (
    <div
      className="page-head-bg"
      style={{ backgroundImage: `url(${backgroundimg})` }}
    >
      {loader ? <Loader fullPage loading /> : null}
      {registerscreen ? (
        <div className="container mt-5">
          <div className="row">
            <div className="offset-md-2 col-md-8 offset-md-2">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">DOCTOR REGISTERATION</h3>
                </div>
                {error ? (
                  <div class="alert alert-danger" role="alert">
                    The email has already been taken!{" "}
                  </div>
                ) : null}
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        First Name
                      </label>
                      <input
                        value={first_name}
                        onChange={(e) => {
                          setfirstname(e.target.value);
                          setFirstName(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                        autofocus
                      />
                      {FirstName ? (
                        <div class="alert alert-danger" role="alert">
                          First Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="frist_name" className="text-white">
                        Last Name
                      </label>
                      <input
                        value={last_name}
                        onChange={(e) => {
                          setlastname(e.target.value);
                          setLastName(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                        autofocus
                      />
                      {LastName ? (
                        <div class="alert alert-danger" role="alert">
                          Last Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> Address </label>
                      <input
                        value={address}
                        onChange={(e) => {
                          setaddress(e.target.value);
                          setadd(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {add ? (
                        <div class="alert alert-danger" role="alert">
                          Address is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> City </label>
                      <input
                        value={city}
                        onChange={(e) => {
                          setcity(e.target.value);
                          setcit(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {cit ? (
                        <div class="alert alert-danger" role="alert">
                          City is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> Company Name </label>
                      <input
                        value={company_name}
                        onChange={(e) => {
                          setcompany_name(e.target.value);
                          setcompany(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {company ? (
                        <div class="alert alert-danger" role="alert">
                          Company Name is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> Country </label>
                      <input
                        value={country}
                        onChange={(e) => {
                          setcountry(e.target.value);
                          setcount(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {count ? (
                        <div class="alert alert-danger" role="alert">
                          Country is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> State </label>
                      <input
                        value={state}
                        onChange={(e) => {
                          setstate(e.target.value);
                          setsta(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {sta ? (
                        <div class="alert alert-danger" role="alert">
                          State is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="text-white"> Zip Code </label>
                      <input
                        id="city"
                        value={zip_code}
                        onChange={(e) => {
                          setzip_code(e.target.value);
                          setzip(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        type="text"
                        className="form-control"
                        name="frist_name"
                      />
                      {zip ? (
                        <div class="alert alert-danger" role="alert">
                          Zip Code is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email" className="text-white">
                        Email
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                          setEmail(false);
                          seterror(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        name="email"
                      />
                      {Email ? (
                        <div class="alert alert-danger" role="alert">
                          Email is Required!
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="last_name" className="text-white">
                        Password
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                          setPass(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Submit();
                          }
                        }}
                        className="form-control"
                        name="last_name"
                      />
                      {Pass ? (
                        <div class="alert alert-danger" role="alert">
                          Password is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-2 form-group col-md-8 offset-md-2">
                      <button
                        type="submit"
                        // disabled={
                        //   !email || !password || !first_name || !last_name
                        // }
                        onClick={Submit}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="text-white text-center">
                  <Link to="/" className="text-white">
                    DASHBOARD
                  </Link>
                </div>
                <div className="mt-2 mb-2 text-white text-center">
                  Already Registered?{" "}
                  <Link to="/Doctorlogin" className="text-white">
                    Login
                  </Link>
                </div>
                <div className="mb-2 text-white text-center">
                  Design By
                  <a
                    href="https://hnhtechsolutions.com/"
                    target="_blank"
                    className="text-white"
                  >
                    {" "}
                    Hnh Tech Solutions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {otpscreen ? (
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="card card-primary mt-4">
                <div className="card-header">
                  <h3 className="text-center">OTP</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <input
                        type="number"
                        placeholder="Please Enter Otp Pin"
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value);
                          setotp(false);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Otp();
                          }
                        }}
                        className="form-control"
                        name="otp"
                      />
                      {otp ? (
                        <div class="alert alert-danger" role="alert">
                          Otp is Required!
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-md-2 form-group col-md-8 offset-md-2">
                      <button
                        type="submit"
                        onClick={Otp}
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
