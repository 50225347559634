import React, { useRef, useState} from "react";
import { Loader } from 'react-overlay-loader';
import axios from "axios";
import { useNavigate} from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';
import img2 from "../img/stripemadh.png";
import Dmeheader from "../Component/Dmeheader";
import Footer from "../Component/Footer";


export default function DmesupplierUpdate() {

    const { enqueueSnackbar} = useSnackbar();
    const Id = localStorage.getItem("dmeId")
    console.log("id", Id)
    const Navigate = useNavigate();
    const premiumpaymentRef = useRef(null)

    const [loader, setLoader] = useState(false)
    // Premium
    const [pname, setpname] = useState("Premium");
    const [pamount, setpamount] = useState(449 * 100);

    const Premium = () => {
        premiumpaymentRef.current.onClick()
        return
    }
    // Premium


    // premiumtoken
    const premiumPaymentSuccuss = (token) => {
        setLoader(true)
        console.log(token.id);
        const formData = {
            'name': pname,
            'dme_id': Id,
            'amount': pamount,
            'source': token.id,
        }
        axios.post(`${Baseurl.baseurl}supplier/premium/package/${Id}`, formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                setLoader(false)
                console.log("res", res.data);
                if (res.data) {
                    Navigate("/DmeDashboard");
                    localStorage.setItem('package', "Premium");
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'success',
                        autoHideDuration: 2000,
                    });
                    setLoader(false)
                } else {
                    enqueueSnackbar(res.data.message, {
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        variant: 'error',
                        autoHideDuration: 2000,
                    });

                }
            });
    }
    // premiumtoken
    return (
        <div>
            <Dmeheader />
            {loader ? <Loader fullPage loading /> : null}
            <div className="main-content">
                <section>
                    <div className='card'>
                        <div className='card-header row'>
                            <div className='col-md-6'>
                                <h5 className='mb-0'>Update To Premium</h5>
                            </div>
                        </div>
                        <div className='card-body col-md-12'>
                            <div className="offset-md-3 col-md-6 offset-md-3">
                                <div className="pricing pricing-highlight">
                                    <div className="pricing-title text-white">
                                        PREMIUM
                                    </div>
                                    <div className="pricing-padding">
                                        <div className="pricing-price text-white">
                                            <div>$449</div>
                                            <div>per month</div>
                                        </div>
                                        <div className="pricing-details">
                                            <div className="pricing-item">
                                                <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                <div className="pricing-item-label text-white">CAN HANDLE 400+ PATIENTS</div>
                                            </div>
                                            <div className="pricing-item">
                                                <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                <div className="pricing-item-label text-white">Invite 400+ PREMIUM Doctors.</div>
                                            </div>
                                            <div className="pricing-item">
                                                <div className="pricing-item-icon"><i className="fas fa-check"></i></div>
                                                <div className="pricing-item-label text-white">Invite 400+ PREMIUM THERAPIST.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing-cta">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={Premium}
                                        >
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <StripeCheckout
                                name="DME SUPPLIER"
                                ref={premiumpaymentRef}
                                image={img2}
                                className="d-none"
                                amount={pamount}
                                token={premiumPaymentSuccuss}
                                stripeKey="pk_test_51KeE33EHvEFI2kYz3KVSPeyovWZpEOYPUmPUz6NvkOZqMoxu4olBtizG0OGAkOcPQjiAi0ZQIchW5zl2JXfpAzCr00j3J3IXyp"
                            />
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
