import React, { forwardRef, useState, useEffect, useContext } from "react";
import DoctorHeader from "../Component/DoctorHeader";
import MaterialTable from "material-table";
import axios from "axios";
import Footer from "../Component/Footer";
import { Loader } from "react-overlay-loader";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Baseurl from "../Component/Baseurl";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../App";
import { useNavigate } from "react-router-dom";
import { GrDocumentText } from 'react-icons/gr';

export default function DoctorPatient() {
  const DoctorId = localStorage.getItem("DoctorId");
  const { theme, toggleTheme } = useContext(ThemeContext);
  const Navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [patient, setpatient] = useState([]);
  // Fetch Doctor Patients
  const Fetch = () => {
    axios
      .get(`${Baseurl.baseurl}doctor/view/patients/${DoctorId}`)
      .then((patient) => {
        console.log(patient, "hello");
        if (patient.data.view_patients.length <= 0) {
          setpatient([]);
          setLoader(false);
        } else {
          setLoader(false);
          setpatient(patient.data.view_patients);
        }
      })
      .catch((errors) => {
        console.log("catch", errors);
        setLoader(false);
        enqueueSnackbar(errors.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };
  useEffect(() => {
    Fetch();
  }, []);

  console.log(patient, "Doctor Patients");

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <div>
      <DoctorHeader />
      {loader ? <Loader fullPage loading /> : null}
      <div className="main-content">
        <section>
          <div className="card">
            <div className="card-header row">
              <div className="col-md-6">
                <h5 className="mb-0">Patient Master List</h5>
              </div>
            </div>
            <div className="card-body col-md-12">
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={[
                  {
                    title: "First Name",
                    field: "first_name",
                    render: (rowData) => rowData.patient.first_name,
                  },
                  {
                    title: "Last Name",
                    field: "last_name",
                    render: (rowData) => rowData.patient.last_name,
                  },
                  {
                    title: "Email",
                    field: "email",
                    render: (rowData) => rowData.patient.email,
                  },
                ]}
                data={patient}
                style={{
                  backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                }}
                actions={[
                  {
                    icon: () => <GrDocumentText />,
                    tooltip: "Document",

                    onClick: (event, rowData) =>
                      Navigate(`/doctor/patient/documents/${rowData.patient_id}`),
                  },
                ]}
                options={{
                  // pageSize: 20,
                  // pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  rowStyle: {
                    backgroundColor: theme === "dark" ? "#353c48" : "#1896c8",
                    color: "#FFF",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
