import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import backgroundimg from "../img/background/back.jpg";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";
import { Loader } from 'react-overlay-loader';
import Baseurl from '../Component/Baseurl'
import { useSnackbar } from 'notistack';

export default function StaffLogin() {
  const [loader, setLoader] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Navigate = useNavigate();
  const [StaffLoginid, SetStaffLoginid] = useState("")
  const [email, Setemail] = useState("");
  const [password, Setpassword] = useState("");
  // const Id=localStorage.getItem("StaffLoginid")
  useEffect(() => {
    SetStaffLoginid(localStorage.getItem("StaffLoginid"))
  }, [])

  console.log("id", StaffLoginid)
  const Login = () => {
    if (email === "" || password === "") {
      enqueueSnackbar("Credentials Is Required", {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      setLoader(true)
      var formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${Baseurl.baseurl}staff/auth/login`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoader(false)
          console.log(result);
          if (result.status) {
            console.log("result====>staff", result);
            localStorage.setItem(
              "permissions",
              JSON.stringify(result.permissions)
            );
            localStorage.setItem("id", result?.detail?.id);
            localStorage.setItem("StaffLogin", result.token);
            localStorage.setItem("StaffLoginid", result?.permissions[0]?.id);
            localStorage.setItem("staff_name", result.staff_name);
            localStorage.setItem("SettingValue", "light");

            Navigate("/StaffDashboard");
            enqueueSnackbar(result.message, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
              autoHideDuration: 2000,
            });
          } else {
            if (result.message) {
              enqueueSnackbar(result.message, {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
              });
            } else if (result.errors.password) {
              enqueueSnackbar(result.errors.password, {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
              });
            } else if (result.errors.email) {
              enqueueSnackbar(result.errors.email, {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
                autoHideDuration: 2000,
              });
            }
          }
        })
        .catch(error => {
          setLoader(false)
          enqueueSnackbar(error.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  }
  return (
    <div>
      <div
        id="app"
        className="page-head-bg"
        style={{ backgroundImage: `url(${backgroundimg})` }}>
        {loader ? <Loader fullPage loading /> : null}
        <section className="section mt-4">
          <div className="container mt-5">
            <div className="row mt-4">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="card card-primary mt-2">
                  <div className="card-header">
                    <h3 className="text-center">STAFF LOGIN</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="email" className="text-white">
                        EMAIL
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Login()
                          }
                        }}
                        value={email}
                        onChange={(e) => Setemail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <div className="d-block">
                        <label
                          htmlFor="password"
                          className="control-label text-white"
                        >
                          PASSWORD
                        </label>
                      </div>
                      <input
                        value={password}
                        onChange={(e) => Setpassword(e.target.value)}
                        type="password"
                        className="form-control"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            Login()
                          }
                        }}
                        name="password"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        tabIndex={4}
                        onClick={() => Login()}
                      >
                        Login
                      </button>
                    </div>
                    <div className="mt-2 text-white text-center">
                      <Link to="/" className="text-white">Dashboard</Link>
                    </div>
                    <div className="mt-2 text-white text-center">
                      Don't have an account? <Link to="/staffregister" className="text-white" >Create One</Link>
                    </div>
                    <div className="mt-2 text-white text-center">
                      Design By<a href="https://hnhtechsolutions.com/" target="_blank" className="text-white">  Hnh Tech Solutions</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
